<template>
  <v-dialog
    class="my_fade"
    v-model="init.dialogOpen"
    @keydown.esc="cancel(false)"
    persistent
    max-width="40%"
    scrollable
  >
    <!-- 刪除畫面 -->
    <v-card
      v-show="init.dialogAction == 'delete'"
      flat
      class="px-4 py-3 my_bg-6"
    >
      <v-card-title :class="'pa-3 ' + 'my_' + init.dialogAction">
        <span class="title">
          {{ title }}
        </span>
      </v-card-title>
      <div class="mb-5">
        <p>
          {{ init.lbl.ruleName }} :
          <u class="title text-xs-center">{{ formData.ruleName }}</u>
        </p>
        <p>
          {{ init.lbl.description }} :
          <u class="title text-xs-center">{{ formData.description }}</u>
        </p>
        <blockquote class="body-2 py-3 text--grey lighten-4">
          {{ init.lbl.warning }}
        </blockquote>
      </div>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn color="my_btn-2" dark @click="cancel()">
          {{ Dict.cancel }}
          <v-icon dark right>mdi-cancel</v-icon>
        </v-btn>
        <v-btn color="my_btn-3" dark @click="del()">
          {{ Dict.confirm }}
          <v-icon dark right>mdi-check-circle</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- 新增|編輯畫面 -->
    <v-card
      v-show="['update', 'create'].includes(init.dialogAction)"
      class="my_bg-6"
    >
      <v-card-title :class="'pa-3 ' + 'my_' + init.dialogAction">
        <span class="title">
          {{ title }}
        </span>
      </v-card-title>
      <v-card-text v-if="init.dialogType == 'mail'">
        <v-layout row style="height: 50%">
          <v-flex sm2 pl-2>
            <v-checkbox
              v-for="mail in mailLists"
              :key="mail.id"
              :value="mail.id"
              :label="mail.mailAddress"
              dense
              v-model="selected"
              hide-details
              class="my-1"
            />
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="my_btn-2" dark @click="cancel()">
          {{ Dict.cancel }}
          <v-icon dark right>mdi-cancel</v-icon>
        </v-btn>
        <v-btn color="my_btn-3" dark @click="save()">
          {{ Dict.confirm }}
          <v-icon dark right>mdi-check-circle</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.chip--small {
  height: 20px;
}
</style>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState("rules", ["init", "formData"]),
    // eslint-disable-next-line vue/return-in-computed-property
    title(state) {
      var v = state.init.dialogAction;
      var title = state.init.lbl.title;
      if (v == "create") {
        return "Add " + title;
      } else if (v == "update") {
        return "Modify " + title;
      } else if (v == "delete") {
        return "Delete " + title;
      }
    },
  },
  data() {
    return {
      active: "actionCodes",
      selected: [],
    };
  },
  methods: {
    ...mapActions("rules", [
      "actTempSave",
      "actCloseDialog",
      "actGetMails",
      "actSave",
    ]),
    save() {
      this.actTempSave(this.selected);
    },
    cancel() {
      this.actCloseDialog();
    },
    del() {
      this.actSave();
    },
  },
};
</script>
