const { helpers } = require("vuelidate/lib/validators");

export const notDomain = (value) => {
  return value == null || value == undefined || value == ""
    ? true
    : value.indexOf(".") >= 0;
};

export const notUri = (value) => {
  return value == null || value == undefined || value == ""
    ? true
    : value.indexOf("://") >= 0;
};

export const smtpHeaderFormat = (value) => {
  return value == null || value == undefined || value == ""
    ? true
    : /^[Xx]-[a-zA-Z0-9_.-]*$/.test(value);
};

export const validateIf = (prop, validator) =>
  helpers.withParams({ type: "validatedIf", prop }, function (value, parentVm) {
    return helpers.ref(prop, this, parentVm) == 1 ? validator(value) : true;
  });
