<template>
  <v-container fluid>
    <div>
      <v-row justify="center">
        <v-col md="6">
          <v-alert type="success" justify="center"
            >We had linked your account to an existing account.</v-alert
          >
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col md="6" class="text-center">Click blow to login again. </v-col>
      </v-row>
      <v-row justify="center"
        ><v-col md="6" class="text-center">
          <v-btn v-on:click="logout">Login</v-btn></v-col
        ></v-row
      >
    </div>
  </v-container>
</template>

<script>
import { AppConfig } from "../config.js";
import mainOidc from "../auth/index";

export default {
  data: () => ({}),
  methods: {
    logout() {
      mainOidc.signOut();
      window.location = AppConfig.baseUrl;
    },
  },
};
</script>
