import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,[_c(VCard,[_c(VCardTitle,[_c('span',[_vm._v("Activity Logs")])]),_c(VCardText,[_c(VRow,[_c(VCol,[_c(VCard,[_c(VCardTitle,[_vm._v("Search")]),_c(VCardText,[_c(VRow,[_c(VCol,{staticClass:"ml-md-auto",attrs:{"cols":"6"}},[_c(VMenu,{ref:"formSearch.beginDateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":"Start Date","prepend-icon":"mdi-calendar-blank-outline","readonly":""},model:{value:(_vm.formSearch.beginDate),callback:function ($$v) {_vm.$set(_vm.formSearch, "beginDate", $$v)},expression:"formSearch.beginDate"}},on))]}}]),model:{value:(_vm.formSearch.beginDateMenu),callback:function ($$v) {_vm.$set(_vm.formSearch, "beginDateMenu", $$v)},expression:"formSearch.beginDateMenu"}},[_c(VDatePicker,{model:{value:(_vm.formSearch.beginDate),callback:function ($$v) {_vm.$set(_vm.formSearch, "beginDate", $$v)},expression:"formSearch.beginDate"}})],1)],1),_c(VCol,{staticClass:"ml-md-auto",attrs:{"cols":"6"}},[_c(VMenu,{ref:"formSearch.endDateMenu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VTextField,_vm._g({attrs:{"label":"End Date","prepend-icon":"mdi-calendar-blank-outline","readonly":""},model:{value:(_vm.formSearch.endDate),callback:function ($$v) {_vm.$set(_vm.formSearch, "endDate", $$v)},expression:"formSearch.endDate"}},on))]}}]),model:{value:(_vm.formSearch.endDateMenu),callback:function ($$v) {_vm.$set(_vm.formSearch, "endDateMenu", $$v)},expression:"formSearch.endDateMenu"}},[_c(VDatePicker,{model:{value:(_vm.formSearch.endDate),callback:function ($$v) {_vm.$set(_vm.formSearch, "endDate", $$v)},expression:"formSearch.endDate"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.getAuditLogs()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-magnify")]),_c('span',[_vm._v(_vm._s(_vm.Dict.search))])],1),_c(VBtn,{attrs:{"outlined":"","text":""},on:{"click":function($event){return _vm.initForm()}}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("mdi-delete-outline")]),_c('span',[_vm._v(_vm._s(_vm.Dict.clear))])],1)],1)],1)],1)],1),(!_vm.loadAuditLogCompleted)?_c(VRow,[_c(VCol,[_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"type":"table"}})],1)],1):_vm._e(),(_vm.loadAuditLogCompleted)?_c(VRow,[_c(VCol,[_c(VDataTable,{attrs:{"dense":"","headers":_vm.auditTableHeaders,"items":_vm.auditLogs,"item-key":"id","group-by":"logDate","footer-props":_vm.footerProps,"items-per-page":"20","no-data-text":_vm.Dict.grid.noDataText,"no-results-text":_vm.Dict.grid.noResultsText,"sort-by":['logDate', 'timeStamp'],"sort-desc":[true, true]},scopedSlots:_vm._u([{key:"item.userId",fn:function(ref){
var item = ref.item;
return [(
                      item.userDisplayName != null &&
                      item.userDisplayName != ''
                    )?_c('span',[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.userDisplayName))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.userName))])])],1):_vm._e()]}},{key:"item.clientIP",fn:function(ref){
                    var item = ref.item;
return [(
                      item.auditType != 'BATCHJOB'
                    )?_c('span',[_vm._v(_vm._s(item.clientIP)+" ")]):_vm._e()]}},{key:"item.timeStamp",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.localMoment(item.timeStamp, _vm.AppConfig.dateTimeFormat))+" ")]}},{key:"item.logDate",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.localMoment(item.logDate, _vm.AppConfig.dateFormat))+" ")]}},{key:"group.header",fn:function(ref){
                    var items = ref.items;
                    var isOpen = ref.isOpen;
                    var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"4"}},[_c(VIcon,{attrs:{"small":""},on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-minus" : "mdi-plus")+" ")]),_vm._v(" "+_vm._s(_vm.localMoment(items[0].logDate, _vm.AppConfig.dateTextFormat))+" ")],1)]}}],null,false,1214767742)})],1)],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }