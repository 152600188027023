<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title
            ><span>Rules</span>
            <v-spacer />
            <!-- 新增 -->
            <v-btn
              outlined
              text
              @click="
                (init.dialogAction = 'create'), $router.push('rules/ruleinfo')
              "
            >
              <v-icon small>mdi-plus</v-icon>
              <span>{{ Dict.new }}</span>
            </v-btn>
          </v-card-title>
          <v-divider> </v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <v-skeleton-loader
                  v-if="progress"
                  class="mx-auto"
                  type="list-item-three-line"
                ></v-skeleton-loader>

                <v-card v-show="!progress">
                  <v-card-title>Search</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6" class="ml-md-auto">
                        <v-text-field
                          v-model="formSearch.ruleName"
                          clearable
                          hide-details
                          prepend-inner-icon="mdi-magnify"
                          :label="init.lbl.ruleName"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="ml-md-auto">
                        <!-- <v-select
                          v-model="formSearch.isActive"
                          :label="init.lbl.isActive"
                          :items="items"
                          attach
                          chips
                          multiple
                        ></v-select> -->
                        <v-radio-group v-model="formSearch.isActive" row>
                          <template v-slot:label>
                            <div>{{ init.lbl.isActive }} :</div>
                          </template>
                          <v-radio :label="Dict.allItem" value="All"></v-radio>
                          <v-radio
                            :label="Dict.enable"
                            value="Enable"
                          ></v-radio>
                          <v-radio
                            :label="Dict.disable"
                            value="Disable"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- 查詢 -->
                    <v-btn outlined text @click="actSearch(true)">
                      <v-icon small>mdi-magnify</v-icon
                      ><span>{{ Dict.search }}</span>
                    </v-btn>

                    <!-- 清除 -->
                    <v-btn outlined text @click="actIniSearch(formData)">
                      <v-icon small>mdi-delete-outline</v-icon>
                      <span>{{ Dict.clear }}</span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-skeleton-loader
                  v-if="progress"
                  class="mx-auto"
                  type="table"
                ></v-skeleton-loader>
                <v-data-table
                  v-show="!progress"
                  class="elevation-1 my_table_sm"
                  :headers="gridRulesList.headers"
                  :items="gridRulesList.data"
                  :loading="gridRulesList.loading"
                  :server-items-length="gridRulesList.totalCount"
                  :hide-default-footer="gridRulesList.data.length == 0"
                  :options.sync="gridRulesList.page"
                  :items-per-page-text="Dict.grid.rowsPerPageText"
                  :items-per-page-options="Dict.grid.rowsPerPageItems"
                  :no-data-text="Dict.grid.noDataText"
                  :no-results-text="Dict.grid.noResultsText"
                  :footer-props="footerProps"
                  items-per-page="20"
                >
                  <template v-slot:item.id class="display:none"> </template>
                  <template v-slot:item.updateDate="{ item }">
                    <span>{{
                      item.updateDate | localMoment(AppConfig.dateTimeFormat)
                    }}</span>
                  </template>
                  <template v-slot:item.isActive="{ item }">
                    <v-checkbox color="success"
                      v-model="item.isActive"
                    ></v-checkbox>
                  </template>
                  <template v-slot:item.ruleAction="{ item }">
                    <v-icon color="red darken-2" v-if="item.ruleAction == 2">
                      mdi-cancel
                    </v-icon>
                    <v-icon color="green darken-2" v-if="item.ruleAction == 1">
                      mdi-check
                    </v-icon>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      x-small
                      color="primary"
                      fab
                      dark
                      @click="
                        actSetRow(item),
                          (init.dialogAction = 'update'),
                          $router.push('/rules/ruleinfo')
                      "
                    >
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn
                      x-small
                      color="error"
                      fab
                      dark
                      @click="
                        actSetRow(item),
                          (init.dialogAction = 'delete'),
                          actShowDialog({ action: 'delete' })
                      "
                    >
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <ruleInfoDialog></ruleInfoDialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ruleInfoDialog from "../rules/RuleInfoDialog";
import moment from "moment";
export default {
  components: {
    ruleInfoDialog,
  },
  data: () => ({
    items: ["Enable", "Disable"],
    footerProps: { "items-per-page-options": [20, 30, 50, 100, -1] },
  }),
  mounted() {
    this.loadTable();
  },
  computed: {
    ...mapState("rules", ["formSearch", "gridRulesList", "init", "formData"]),
    ...mapState("mainCtr", ["progress"]),
  },
  methods: {
    ...mapActions("rules", [
      "actReadRulesLists",
      "actIniForm",
      "actIniSearch",
      "actSetRow",
      "actShowDialog",
      "actCloseDialog",
      "actSearch",
    ]),
    loadTable() {
      this.actIniForm();
      this.actSearch();
    },
  },
  filters: {
    localMoment: function (date, format) {
      return moment.utc(date).local().format(format);
    },
  },
};
</script>
