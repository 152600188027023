import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"30%"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.actCloseAlert()}},model:{value:(_vm.alert.dialog),callback:function ($$v) {_vm.$set(_vm.alert, "dialog", $$v)},expression:"alert.dialog"}},[_c(VCard,{staticClass:"text-xs-center pa-3"},[_c(VListItem,[_c(VListItemAvatar,{attrs:{"tile":"","size":"80"}},[_c(VIcon,{staticClass:"black--text",attrs:{"x-large":""}},[_vm._v(_vm._s(_vm.alert.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{class:_vm.alert.color + '--text mb-2',staticStyle:{"font-size":"30px"}},[_c('b',[_vm._v(_vm._s(_vm.alert.title))])]),_c(VCard,{staticClass:"pa-3 my_word",staticStyle:{"overflow":"auto"},attrs:{"flat":"","height":"80px"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")]),_c(VCard,{staticClass:"text-xs-center",attrs:{"flat":""}},[_c(VBtn,{class:'my_btn-' + _vm.alert.color,on:{"click":function($event){return _vm.actCloseAlert()}}},[_vm._v(_vm._s(_vm.Dict.close))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }