<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title
            ><span>Global Rule</span>
            <v-spacer></v-spacer>
            <v-btn outlined @click="save()">
              <v-icon>mdi-check-circle</v-icon>
              <span>{{ Dict.save }}</span>
            </v-btn>
            <v-btn color="error" outlined @click="cancel()">
              <v-icon>mdi-close</v-icon>
              <span>{{ Dict.cancel }}</span>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>

          <v-skeleton-loader
            v-if="progress"
            class="mx-auto"
            type="list-item-three-line, divider,list-item-avatar,list-item-avatar, divider, list-item-avatar, divider, list-item-three-line,divider,list-item-three-line"
          ></v-skeleton-loader>

          <v-card-text v-if="!progress">
            <v-list subheader two-line>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ init.lbl.ruleName }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field
                      :label="init.lbl.ruleName"
                      v-model="formDataGlobal.ruleName"
                      @input="$v.formDataGlobal.ruleName.$touch()"
                      @blur="$v.formDataGlobal.ruleName.$touch()"
                    >
                    </v-text-field>
                    <div
                      class="input-error"
                      v-if="!$v.formDataGlobal.ruleName.required"
                    >
                      {{ init.lbl.ruleName }} is required.
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{
                    init.lbl.description
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field
                      :label="init.lbl.description"
                      v-model="formDataGlobal.description"
                    >
                    </v-text-field>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-switch
                      :label="`${
                        formDataGlobal.isActive ? Dict.enable : Dict.disable
                      }`"
                      v-model="formDataGlobal.isActive"
                    ></v-switch>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="!progress">
      <v-col>
        <v-tabs v-model="tab" show-arrows>
          <v-tabs-slider></v-tabs-slider>

          <v-tab href="#tab-1">
            {{ init.lbl.allow }}
            <v-icon> mdi-check </v-icon>
          </v-tab>

          <v-tab href="#tab-2">
            {{ init.lbl.block }}
            <v-icon> mdi-cancel</v-icon>
          </v-tab>

          <v-tabs-items v-model="tab">
            <v-tab-item :key="1" :value="'tab-1'"
              ><v-card>
                <v-card-title
                  ><span>{{ init.lbl.mail }}</span>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                  <v-list shaped>
                    <v-list-item-group
                      v-model="formDataGlobal.allowMails"
                      multiple
                    >
                      <template v-for="(item, i) in mailLists">
                        <v-divider
                          v-if="!item"
                          :key="`divider-${i}`"
                        ></v-divider>
                        <v-list-item v-else :key="`item-${i}`" :value="item.id">
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.mailAddress"
                              ></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                :true-value="item.id"
                                disabled
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :key="2" :value="'tab-2'"
              ><v-card>
                <v-card-title
                  ><span>{{ init.lbl.mail }}</span>
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text>
                  <v-list shaped>
                    <v-list-item-group
                      v-model="formDataGlobal.blockMails"
                      multiple
                    >
                      <template v-for="(item, i) in mailLists">
                        <v-divider
                          v-if="!item"
                          :key="`divider-${i}`"
                        ></v-divider>
                        <v-list-item v-else :key="`item-${i}`" :value="item.id">
                          <template v-slot:default="{ active }">
                            <v-list-item-content>
                              <v-list-item-title
                                v-text="item.mailAddress"
                              ></v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-checkbox
                                :input-value="active"
                                :true-value="item.id"
                                disabled
                              ></v-checkbox>
                            </v-list-item-action>
                          </template>
                        </v-list-item>
                      </template>
                    </v-list-item-group>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
#responsibilityEdit .ql-editor {
  max-height: 200px;
}
</style>
<script>
const { validationMixin, default: Vuelidate } = require("vuelidate");
const { required, minLength } = require("vuelidate/lib/validators");
import { mapActions, mapState } from "vuex";

export default {
  components: {},
  data: () => ({ tab: null }),
  mounted() {
    this.actGetGlobalInfo();
  },
  watch: {
    // "formDataGlobal.ruleAction": function () {
    //   this.actSearchMail(this.formDataGlobal.ruleAction);
    // },
  },
  computed: {
    ...mapState("rules", [
      "init",
      "formDataGlobal",
      "mailLists",
      "allowMails",
      "blockMails",
    ]),
    ...mapState("mainCtr", ["progress"]),
  },
  validations: {
    formDataGlobal: {
      ruleName: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("rules", [
      "actIniFormGlobal",
      "actGetGlobalInfo",
      "actSaveGlobal",
      "actGlobalCancel",
    ]),
    dataUrl(imageData) {
      return "data:image/jpeg;base64," + imageData;
    },
    save() {
      this.$v.formDataGlobal.$touch();
      if (this.$v.formDataGlobal.$pending || this.$v.formDataGlobal.$error)
        return;
      this.actSaveGlobal({
        allowMail: this.formDataGlobal.allowMails,
        blockMail: this.formDataGlobal.blockMails,
      });
    },
    cancel() {
      this.$v.formDataGlobal.$reset();
      this.actGlobalCancel();
    },
  },
};
</script>
