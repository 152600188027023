import mainCtr from "./mainCtr";
import Dict from "./dict";
import Icon from "./icon";
import { AppConfig } from "../config";

var moment = require("vue-moment");
var EventusGlobal = {
  mixinData: {
    data() {
      return {
        Dict: Dict,
        Icon: Icon,
        AppConfig: AppConfig,
      };
    },
    computed: {
      autoHeight() {
        return function (v) {
          if (v) {
            var h = window.innerHeight;
            return h - v + "px";
          } else {
            return window.innerHeight + "px";
          }
        };
      },
      autoWidth() {
        return function (v) {
          if (v) {
            var w = document.body.clientWidth;
            return w - v + "px";
          } else {
            return document.body.clientWidth + "px";
          }
        };
      },
      getOperInfo(data) {
        return EventusGlobal.getOperInfo(data);
      },
    },
    filters: {
      enable(v) {
        return EventusGlobal.enable(v);
      },
      editable(v) {
        return EventusGlobal.editable(v);
      },
      dialogTypeName(v) {
        return EventusGlobal.dialogTypeName(v);
      },
      formatDateTime(v) {
        return EventusGlobal.formatDateTime(v);
      },
      formatDate(v) {
        return EventusGlobal.formatDate(v);
      },
      formatType(v) {
        return EventusGlobal.formatType(v);
      },
      formatLocation(v) {
        return EventusGlobal.formatLocation(v);
      },
      formatLocal(v) {
        return EventusGlobal.formatLocal(v);
      },
      formatAlertStatus(v) {
        return EventusGlobal.formatAlertStatus(v);
      },
      formatBool(v) {
        return EventusGlobal.formatBool(v);
      },
      formatCommas(v) {
        if (v >= 0) {
          return EventusGlobal.formatCommas(v);
        }
      },

      trimText(v) {
        if (v != "" && v) {
          var t = v.trim();
          t = t.replace(/ /g, "");
          return t.replace(/ /g, "");
        }
      },
    },
  },
  trimText(v) {
    if (v != "" && v) {
      var t = v.trim();
      t = t.replace(/ /g, "");
      return t.replace(/ /g, "");
    }
  },
  oper() {
    return JSON.parse(sessionStorage.getItem("jsonObject"));
  },
  getOperInfo(data) {
    var loginStr = sessionStorage.getItem("jsonObject");
    if (loginStr) {
      var loginObject = JSON.parse(loginStr);

      data.oper = loginObject.operId;
      data.deptId = loginObject.deptId;
      data.deptName = loginObject.deptName;
    }
    return data;
  },

  AUTHPWD(status) {
    mainCtr.state.authPwdStatus = status;
    return true;
  },
  AUTHPWDSTATUS() {
    return mainCtr.state.authPwdStatus;
  },
  PROGRESS(status) {
    mainCtr.state.progress = status;
  },
  EXTEND(status) {
    mainCtr.state.extend = status;
  },
  setConfirm(message, methods) {
    var confirmObj = {};
    confirmObj.dialog = true;
    confirmObj.message = message;
    confirmObj.methods = methods;

    mainCtr.state.confirm = confirmObj;
  },
  setAlert(message, type = "error", option = undefined) {
    var alertObj = {};
    alertObj.dialog = true;
    alertObj.message = message;
    alertObj.width = "40%";

    if (type == "success") {
      alertObj.title = "Welcome";
      alertObj.icon = "mdi-emoticon-happy-outline";
      alertObj.color = "green";
    } else if (type == "error") {
      alertObj.title = "ERROR";
      alertObj.icon = "mdi-emoticon-dead-outline";
      alertObj.color = "red";
    } else if (option != undefined) {
      alertObj.title = option.title;
      alertObj.icon = option.icon;
      alertObj.color = option.color;
    }
    mainCtr.state.alert = alertObj;
  },
  setSnackBar(message, type = "error", sys = "") {
    var snackBarObj = {};
    snackBarObj.message = message;
    snackBarObj.type = type;
    snackBarObj.sys = sys;

    if (type == "success") {
      snackBarObj.duration = 1000;
    } else if (type == "warn") {
      snackBarObj.duration = -1;
    } else if (type == "error") {
      snackBarObj.duration = -1;
    }
    mainCtr.state.snackBar = snackBarObj;
  },

  isEmptyObj(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  },
  isDate(str) {
    var d = moment(str).format("YYYY/MM/DD");
    if (d != "Invalid date") return true;
    else return false;
  },
  enable(v) {
    return v ? "啟用" : "停用";
  },
  editable(v) {
    return v ? "編輯" : "唯讀";
  },
  dialogTypeName(v) {
    if (v == "create") {
      return "新增";
    } else if (v == "update") {
      return "編輯";
    } else if (v == "delete") {
      return "刪除";
    }
  },
  formatCommas(val) {
    var intVal = parseInt(val);
    if (intVal) {
      var aIntNum = intVal.toString().split(".");
      if (aIntNum[0].length >= 4) {
        aIntNum[0] = aIntNum[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (aIntNum[1] && aIntNum[1].length >= 4) {
        aIntNum[1] = aIntNum[1].replace(/(\d{3})/g, "$1 ");
      }
      return aIntNum.join(".");
    } else {
      return "";
    }
  },
  formatDateTime(value) {
    if (value && value != "0") {
      var d = moment(value).format("YYYY-MM-DD HH:mm:ss");
      if (d != "Invalid date") value = d;
    }
    return value;
  },
  formatDate(value) {
    if (value && value != "0") {
      var d = moment(value).format("YYYY-MM-DD");
      if (d != "Invalid date") value = d;
    }
    return value;
  },
  formatMMYY(value) {
    if (value && value != "0" && value.length >= 4) {
      var d = value.substr(0, 2) + "/" + value.substr(2, 4);
      value = d;
    }
    return value;
  },
  formatDateTrim(value) {
    return value == null ? "" : moment(value).format("YYYYMMDD");
  },
  formatDateTimeStr(value) {
    return value == null ? "" : moment(value).format("hh:mm a");
  },

  formatBool(value) {
    return value ? "✔️" : "✖️";
  },

  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  },
  arrayDiff(a, b) {
    return b.filter((i) => {
      return a.indexOf(i) < 0;
    });
  },
  getApConfig(key) {
    var configs = EventusGlobal.oper().apConfig;
    return key == undefined ? configs : configs[key];
  },
};

export default EventusGlobal;
