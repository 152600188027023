var Icon = {
  custId: "assignment_ind",
  custName: "face",
  select: "view_week",
  campaignId: "content_paste",
  list: "list",
  date: "mdi-calendar-range",
  code: "code",
  actionCodes: "format_list_numbered",
  failedCodes: "thumb_down",
  delimiter: "flip",
  text: "mdi-keyboard",
  mark: "bookmark_border",
  title: "title",
  level: "layers",
  project: "content_paste",
  ext: "extension",
  alert: "add_alert",
  memo: "warning",
  deptName: "account_balance",
  parent: "vertical_align_top",
  address: "location_on",
  email: "mail_outline",
  print: "print",
  sms: "sms",
  url: "link",
  person: "person",
  time: "access_time",
  birthday: "cake",
  vip: "security",
  clMemo: "star",
  csMemo: "mode_edit",
  warn: "warning",
  ivr: "info",
};
export default Icon;
