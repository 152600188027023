<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title><span>Activity Logs</span> </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-card>
                  <v-card-title>Search</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6" class="ml-md-auto">
                        <v-menu
                          ref="formSearch.beginDateMenu"
                          v-model="formSearch.beginDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              label="Start Date"
                              v-model="formSearch.beginDate"
                              prepend-icon="mdi-calendar-blank-outline"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formSearch.beginDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6" class="ml-md-auto">
                        <v-menu
                          ref="formSearch.endDateMenu"
                          v-model="formSearch.endDateMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              label="End Date"
                              v-model="formSearch.endDate"
                              prepend-icon="mdi-calendar-blank-outline"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formSearch.endDate"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- 查詢 -->
                    <v-btn outlined text @click="getAuditLogs()">
                      <v-icon small>mdi-magnify</v-icon
                      ><span>{{ Dict.search }}</span>
                    </v-btn>

                    <v-btn outlined text @click="initForm()">
                      <v-icon small>mdi-delete-outline</v-icon>
                      <span>{{ Dict.clear }}</span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="!loadAuditLogCompleted">
              <v-col>
                <v-skeleton-loader
                  class="mx-auto"
                  type="table"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row v-if="loadAuditLogCompleted">
              <v-col>
                <v-data-table
                  dense
                  :headers="auditTableHeaders"
                  :items="auditLogs"
                  item-key="id"
                  group-by="logDate"
                  :footer-props="footerProps"
                  items-per-page="20"
                  :no-data-text="Dict.grid.noDataText"
                  :no-results-text="Dict.grid.noResultsText"
                  :sort-by="['logDate', 'timeStamp']"
                  :sort-desc="[true, true]"
                >
                  <template v-slot:item.userId="{ item }">
                    <span
                      v-if="
                        item.userDisplayName != null &&
                        item.userDisplayName != ''
                      "
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">{{
                            item.userDisplayName
                          }}</span>
                        </template>
                        <span>{{ item.userName }}</span>
                      </v-tooltip>
                    </span>
                  </template>
                    <template v-slot:item.clientIP="{ item }">
                     <span
                      v-if="
                        item.auditType != 'BATCHJOB'
                      "
                    >{{ item.clientIP }}
                     </span>
                  </template>
                  <template v-slot:item.timeStamp="{ item }">
                    {{ localMoment(item.timeStamp, AppConfig.dateTimeFormat) }}
                  </template>
                  <template v-slot:item.logDate="{ item }">
                    {{ localMoment(item.logDate, AppConfig.dateFormat) }}
                  </template>
                  <template v-slot:group.header="{ items, isOpen, toggle }">
                    <th colspan="4">
                      <v-icon small @click="toggle"
                        >{{ isOpen ? "mdi-minus" : "mdi-plus" }}
                      </v-icon>
                      {{
                        localMoment(items[0].logDate, AppConfig.dateTextFormat)
                      }}
                    </th>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import axiosAPI from "../../store/asiosAPI";
import { AppConfig } from "../../config";
export default {
  components: {},
  data: () => ({
    loadAuditLogCompleted: false,
    auditLogs: [],
    footerProps: { "items-per-page-options": [20, 30, 50, 100, -1] },
    formSearch: {
      beginDate: null,
      endDate: null,
      beginDateMenu: false,
      endDateMenu: false,
    },

    auditTableHeaders: [
      { text: "Time", value: "timeStamp" },
      { text: "Message", value: "message", align: "start" },
      { text: "User", value: "userId", align: "start" },
      { text: "Client IP", value: "clientIP" },
      { text: "Date", value: "logDate" },
    ],
  }),
  mounted() {
    this.initForm();
    this.getAuditLogs();
  },
  computed: {
    ...mapState("mainCtr", ["progress"]),
  },

  methods: {
    localMoment(date, format) {
      return moment.utc(date).local().format(format);
    },

    initForm() {
      this.formSearch = {
        beginDate: moment().add(-30, "days").format("YYYY-MM-DD"),
        endDate: moment().add(1, "days").format("YYYY-MM-DD"),
      };
    },
    getAuditLogs() {
      this.loadAuditLogCompleted = false;
      var getParams = {
        params: {
          startDate: moment(this.formSearch.beginDate).toISOString(),
          endDate: moment(this.formSearch.endDate).toISOString(),
        },
      };
      axiosAPI.instance.show = true;
      axiosAPI.instance.get(`/auditlog/`, getParams).then((response) => {
        if (response.data.auditLogs) {
          this.auditLogs = response.data.auditLogs;
          this.loadAuditLogCompleted = true;
        }
      });
    },
  },
};
</script>
