import Chart from 'chart.js';
import { Pie, mixins } from "vue-chartjs";
import labels from 'chartjs-plugin-datalabels';

export default {
  extends: Pie,
  mixins: [mixins.reactiveProp],
  props: ["options"],
  mounted() {
    this.addPlugin(labels),
    this.renderChart(this.chartData, this.options);
  },
};
