<template>
  <v-container fluid>
    <div>
      <v-row justify="center">
        <v-col cols="10">
          <v-row>
            <v-col cols="6">
              <v-card>
                <v-card-title>Email Message in 24 Hours</v-card-title>
                <div
                  v-if="!progress"
                  class="pa-4"
                >
                  <NumOfEmail
                    v-if="!progress && (this.numOfEmail.internal +
                      this.numOfEmail.nonTrusted +
                      this.numOfEmail.trusted +
                      this.numOfEmail.blocked != 0)"
                    style="height:200px"
                    :chart-data="emailSummaryChartData"
                    :options="emailSummaryChartOption"
                  />
                  <v-skeleton-loader
                    v-if="progress"
                    class="mx-auto"
                    type="article"
                  />
                </div>
                <div
                  v-if="!progress && (this.numOfEmail.internal +
                    this.numOfEmail.nonTrusted +
                    this.numOfEmail.trusted +
                    this.numOfEmail.blocked == 0)"
                  style="height: 200px;"
                  class="pa-4"
                >
                  <v-row
                    no-gutters
                    style="height: 200px;"
                  >
                    <v-col
                      align-self="center"
                      md="4"
                      offset-md="4"
                    >
                      <v-alert 
                        text
                        dense
                        color="teal"
                        prominent
                        icon="mdi-magnify"
                      >
                        No Data Found
                      </v-alert>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card>
                <v-card-title>License Available</v-card-title>
                <div
                  v-if="!progress"
                  class="pa-4"
                >
                  <LicenseAvailable
                    v-if="!progress && (licenseSummary.validTo != '0001-01-01T00:00:00')"
                    :chart-data="licenseChartData"
                    :options="licenseChartOption"
                    style="height: 200px;"
                  />
                  <v-skeleton-loader
                    v-if="progress"
                    class="mx-auto"
                    type="article"
                  />
                </div>
                <div
                  v-if="!progress && (licenseSummary.validTo == '0001-01-01T00:00:00')"
                  style="height: 200px;"
                  class="pa-4"
                >
                  <v-row
                    no-gutters
                    style="height: 200px;"
                  >
                    <v-col
                      align-self="center"
                      md="4"
                      offset-md="4"
                    >
                      <v-alert 
                        text
                        dense
                        color="teal"
                        prominent
                        icon="mdi-magnify"
                      >
                        No Data Found
                      </v-alert>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-expansion-panels
                multiple
                accordion
                :value="expanded"
              >
                <v-expansion-panel>
                  <v-expansion-panel-header style="font-size: 1.25rem;font-weight: 500;">
                    Email Sent By Country
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>            
                    <div
                      v-if="!progress"
                      class="pa-4"
                    >
                      <div
                        id="mapEmailCountContainer"
                        style="width: 100%; height: 300px"
                      />
                      <v-simple-table v-if="numOfEmailByCountry">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Country
                              </th>
                              <th class="text-right">
                                Count
                              </th>
                              <th class="text-right">
                                %
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in numOfEmailByCountry.countryData"
                              :key="item.country"
                            >
                              <td>{{ item.countryName }}</td>
                              <td class="text-right">
                                {{ item.count }}
                              </td>
                              <td class="text-right">
                                {{
                                  parseFloat(item.percent * 100).toFixed(2) + "%"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                    <v-skeleton-loader
                      v-if="progress"
                      class="mx-auto"
                      type="card, article"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-expansion-panels
                multiple
                accordion
                :value="expanded"
              >
                <v-expansion-panel expand>
                  <v-expansion-panel-header style="font-size: 1.25rem;font-weight: 500;">
                    User By Country
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-if="!progress"
                      class="pa-4"
                    >
                      <div
                        id="mapSendLogContainer"
                        style="width: 100%; height: 300px"
                      />
                      <v-simple-table v-if="numOfUserByCountry">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                Country
                              </th>
                              <th class="text-right">
                                Count
                              </th>
                              <th class="text-right">
                                %
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="item in numOfUserByCountry.countryData"
                              :key="item.country"
                            >
                              <td>{{ item.countryName }}</td>
                              <td class="text-right">
                                {{ item.count }}
                              </td>
                              <td class="text-right">
                                {{
                                  parseFloat(item.percent * 100).toFixed(2) + "%"
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                    <v-skeleton-loader
                      v-if="progress"
                      class="mx-auto"
                      type="card, article"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-expansion-panels
                multiple
                accordion
                :value="expanded"
              >
                <v-expansion-panel expand>
                  <v-expansion-panel-header style="font-size: 1.25rem;font-weight: 500;">
                    Email Message in 7 Days
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-if="!progress && (numOfEmailByDays != undefined && numOfEmailByDays != null && numOfEmailByDaysTotalCount != 0)"
                      class="pa-4"
                    >
                      <line-chart
                        v-if="!progress && numOfEmailByDays != undefined"
                        :data="numOfEmailByDays"
                      />
                    </div>
                    <v-skeleton-loader
                      v-if="progress"
                      class="mx-auto"
                      type="article"
                    />
                    <div
                      v-if="!progress && (numOfEmailByDays == undefined || numOfEmailByDays == null || numOfEmailByDaysTotalCount == 0)"
                      class="pa-4"
                    >
                      <v-row
                        no-gutters
                        style="height: 150px;"
                      >
                        <v-col
                          align-self="center"
                          md="4"
                          offset-md="4"
                        >
                          <v-alert 
                            text
                            dense
                            color="teal"
                            prominent
                            icon="mdi-magnify"
                          >
                            No Data Found
                          </v-alert>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-expansion-panels
                multiple
                accordion
                :value="expanded"
              >
                <v-expansion-panel expand>
                  <v-expansion-panel-header style="font-size: 1.25rem;font-weight: 500;">
                    Top 5 Sender
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div
                      v-if="!progress && topSenders.senders != undefined && topSenders.senders.length!= 0"
                      class="pa-4"
                    >
                      <TopSenders
                        :chart-data="topSendersChartData"
                        :options="topSendersChartOption"
                        style="height: 300px;"
                      />
                    </div>
                    <v-skeleton-loader
                      v-if="progress"
                      class="mx-auto"
                      type="card, table"
                    />
                    <div v-if="!progress && (topSenders.senders == undefined || topSenders.senders.length == 0)">
                      <v-row
                        no-gutters
                        style="height: 150px;"
                      >
                        <v-col
                          align-self="center"
                          md="4"
                          offset-md="4"
                        >
                          <v-alert 
                            text
                            dense
                            color="teal"
                            prominent
                            icon="mdi-magnify"
                          >
                            No Data Found
                          </v-alert>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import EmailSent from "../charts/EmailSent.js";
import LicenseAvailable from "../charts/LicenseAvailable.js";
import NumOfEmail from "../charts/NumOfEmail.js";
import TopSenders from "../charts/TopFiveSenders.js";
import { AppConfig } from "../config.js";
import mainOidc from "../auth/index";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";

export default {
  data: () => ({
    licenseChartData: {},
    licenseChartOption: { responsive: true, maintainAspectRatio: false ,
     },
    emailSummaryChartData: {},
    emailSummaryChartOption: { responsive: true, maintainAspectRatio: false},
    topSendersChartData: {},
    topSendersChartOption: { responsive: true, maintainAspectRatio: false ,
    plugins: {
       datalabels: {
          color: '#ffffff',
       }
    },},
    numOfEmailSent: 1,
    sender: {},
    loadEmailByCountry: false,
    expanded:[0],
  }),
  computed: {
    ...mapState("home", [
      "init",
      "licenseSummary",
      "numOfEmail",
      "numOfEmailByDays",
      "numOfEmailByDaysTotalCount",
      "numOfEmailByCountry",
      "numOfClientConnectByCountry",
      "numOfUserByCountry",
      "topSenders",
    ]),
    ...mapState("mainCtr", ["progress"]),
    user() {
      if (AppConfig.deploymentType != "Cloud") return "";
      else return mainOidc.authName;
    },
    isAuthenticated() {
      if (AppConfig.deploymentType != "Cloud") return true;
      return mainOidc.isAuthenticated;
    },
    claims() {
      if (this.user) {
        return Object.keys(this.user).map((key) => ({
          key,
          value: this.user[key],
        }));
      }
      return [];
    },
    getDateArray() {
      var end = new Date();
      var start = new Date();
      start.setDate(start.getDate() - 7);
      var arr = new Array(),
        dt = new Date(start);

      while (dt <= end) {
        arr.push(new Date(dt));
        dt.setDate(dt.getDate() + 1);
      }
      return arr;
    },
    emailSentCount() {
      var count = 0;
      count =
        this.numOfEmail.internal +
        this.numOfEmail.nonTrusted +
        this.numOfEmail.trusted +
        this.numOfEmail.blocked;
      return count;
    },
  },
  components: {
    NumOfEmail,
    LicenseAvailable,
    TopSenders,
  },
  methods: {
    ...mapActions("home", [
      "actGetLicenseSummary",
      "actGetNumOfEmail",
      "actGetNumOfEmailByDays",
      "actGetNumOfUserByCountry",
      "actGetNumOfClientConnectByCountry",
      "actGetNumOfEmailByCountry",
      "actGetTopSendersByHours",
    ]),
  },
  watch: {
    licenseSummary: function (newVal, oldVal) {
      this.licenseChartData = {
        labels: ["Used", "Available"],
        datasets: [
          {
            backgroundColor: ["rgb(255, 159, 64)", "rgb(54, 162, 235)"],
            data: [
              this.licenseSummary.usedCount,
              this.licenseSummary.licenseCount - this.licenseSummary.usedCount,
            ],
          },
        ],
      };
    },
    numOfClientConnectByCountry: function (newVal, oldVal) {
      mapboxgl.accessToken = AppConfig.mapboxKey;
      var map = new mapboxgl.Map({
        container: "mapSendLogContainer",
        style: "mapbox://styles/mapbox/light-v10",
        zoom: 0.2,
        center: [newVal.centerLong, newVal.centerLat],
        transformRequest: (url, resourceType) => {
          if (resourceType === "Source") {
            var accountid = "";
            if (AppConfig.deploymentType != "Cloud") {
              accountid = AppConfig.accountId;
            } else {
              if (mainOidc.isAuthenticated) {
                accountid = mainOidc.userProfile[AppConfig.accountIdKey];
              } else {
                accountid = "00000000-0000-0000-0000-000000000000";
              }
            }

            return {
              url: url,
              headers: {
                Authorization: "Bearer " + mainOidc.accessToken,
                "enki-account-id": accountid,
              },
            };
          }
        },
      });

      map.on("load", function () {
        map.addSource("userList", {
          type: "geojson",
          data: `${AppConfig.apiRoot}/statistics/numOfUser/7/geojson`,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 20, // Radius of each cluster when clustering points (defaults to 50)
        });

        map.addLayer({
          id: "clusters",
          type: "circle",
          source: "userList",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#51bbd6",
              100,
              "#f1f075",
              750,
              "#f28cb1",
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              100,
              30,
              750,
              40,
            ],
          },
        });

        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "userList",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["Arial Unicode MS Bold"],
            "text-size": 12,
          },
        });
        map.addLayer({
          id: "unclustered-point",
          type: "circle",
          source: "userList",
          filter: ["!", ["has", "point_count"]],
          paint: {
            "circle-color": "#11b4da",
            "circle-radius": 20,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#fff",
          },
        });
        map.addLayer({
          id: "unclustered-count",
          type: "symbol",
          source: "userList",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "text-field": "1",
            "text-font": ["Arial Unicode MS Bold"],
            "text-size": 12,
          },
        });

        map.on("sourcedata", function (e) {
          if (e.sourceId !== "userList" || !e.isSourceLoaded) return;
          var f = map.querySourceFeatures("userList");
          if (f.length === 0) return;
          var bbox = turf.bbox({
            type: "FeatureCollection",
            features: f,
          });
          map.fitBounds(bbox, { padding: 50 });
        });
      });
    },
    numOfEmailByCountry: function (newVal, oldVal) {
      mapboxgl.accessToken = AppConfig.mapboxKey;
      var map = new mapboxgl.Map({
        container: "mapEmailCountContainer",
        style: "mapbox://styles/mapbox/light-v10",
        zoom: 0.2,
        center: [newVal.centerLong, newVal.centerLat],
        transformRequest: (url, resourceType) => {
          if (resourceType === "Source") {
            var accountid = "";
            if (AppConfig.deploymentType != "Cloud") {
              accountid = AppConfig.accountId;
            } else {
              if (mainOidc.isAuthenticated) {
                accountid = mainOidc.userProfile[AppConfig.accountIdKey];
              } else {
                accountid = "00000000-0000-0000-0000-000000000000";
              }
            }

            return {
              url: url,
              headers: {
                Authorization: "Bearer " + mainOidc.accessToken,
                "enki-account-id": accountid,
              },
            };
          }
        },
      });

      map.on("load", function () {
        map.addSource("sendLog", {
          type: "geojson",
          data: `${AppConfig.apiRoot}/statistics/numOfEmail/7/geojson`,
          cluster: true,
          clusterMaxZoom: 14, // Max zoom to cluster points on
          clusterRadius: 20, // Radius of each cluster when clustering points (defaults to 50)
        });

        map.addLayer({
          id: "clusters",
          type: "circle",
          source: "sendLog",
          filter: ["has", "point_count"],
          paint: {
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#51bbd6",
              100,
              "#f1f075",
              750,
              "#f28cb1",
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              100,
              30,
              750,
              40,
            ],
          },
        });

        map.addLayer({
          id: "cluster-count",
          type: "symbol",
          source: "sendLog",
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-font": ["Arial Unicode MS Bold"],
            "text-size": 12,
          },
        });
        map.addLayer({
          id: "unclustered-point",
          type: "circle",
          source: "sendLog",
          filter: ["!", ["has", "point_count"]],
          paint: {
            "circle-color": "#11b4da",
            "circle-radius": 4,
            "circle-stroke-width": 1,
            "circle-stroke-color": "#fff",
          },
        });
        map.addLayer({
          id: "unclustered-count",
          type: "symbol",
          source: "sendLog",
          filter: ["!", ["has", "point_count"]],
          layout: {
            "text-field": "1",
            "text-font": ["Arial Unicode MS Bold"],
            "text-size": 12,
          },
        });

        map.on("sourcedata", function (e) {
          if (e.sourceId !== "sendLog" || !e.isSourceLoaded) return;
          var f = map.querySourceFeatures("sendLog");
          if (f.length === 0) return;
          var bbox = turf.bbox({
            type: "FeatureCollection",
            features: f,
          });
          map.fitBounds(bbox, { padding: 50 });
        });
      });
    },
    numOfEmail: function (newVal, oldVal) {
      this.emailSummaryChartData = {
        labels: ["NonTrusted", "Trusted", "Blocked", "Internal"],
        datasets: [
          {
            backgroundColor: [
              "rgb(54, 162, 235)",
              "rgb(255, 87, 51)",
              "rgb(255, 159, 64)",
              "rgb(10, 113, 18)",
            ],
            data: [
              this.numOfEmail.nonTrusted,
              this.numOfEmail.trusted,
              this.numOfEmail.blocked,
              this.numOfEmail.internal,
            ],
          },
        ],
      };
    },
    topSenders: function (newVal, oldVal) {
      if (this.topSenders != undefined && this.topSenders.length != 0) {
        this.topSendersChartData = {
          labels: this.topSenders.senders,
          datasets: [
            {
              backgroundColor: [
                "rgb(54, 162, 235)",
                "rgb(255, 87, 51)",
                "rgb(255, 159, 64)",
                "rgb(10, 113, 18)",
                "rgb(102  45 145)",
                "rgb(123  82  49)",
                "rgb( 28 117 188)",
                "rgb(215 244  34)",
                "rgb(119 179 225)",
                "rgb(248 241 148)",
              ],
              data: this.topSenders.counts,
            },
          ],
        };
      }
    },
  },
  mounted() {
    if (AppConfig.deploymentType != "Cloud") {
      this.actGetLicenseSummary();
      this.actGetNumOfEmail();
      this.actGetNumOfEmailByDays();
      this.actGetTopSendersByHours();
    } else {
      if (!mainOidc.isAuthenticated) {
        mainOidc.signIn();
      } else {
        this.actGetLicenseSummary();
        this.actGetNumOfEmail();
        this.actGetNumOfEmailByDays();
        this.actGetNumOfUserByCountry();
        this.actGetNumOfClientConnectByCountry();
        this.actGetNumOfEmailByCountry();
        this.actGetTopSendersByHours();
      }
    }
  },
};
</script>
