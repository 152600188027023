import axiosAPI from "../asiosAPI";
import EG from "../../common/plugin";

var state = {
  name: "home",
  init: {
    lbl: {
      title: "Enki Admin Portal",
    },
  },
  numOfUserByCountry:null,
  numOfClientConnectByCountry:null,
  numOfEmailByCountry:null,
  numOfEmail: {
    internal: 0,
    nonTrusted: 0,
    trusted: 0,
    blocked: 0,
  },
  numOfEmailByDays: {
    internal: 0,
    nonTrusted: 0,
    trusted: 0,
    blocked: 0,
  },
  numOfEmailByDaysTotalCount:0,
  licenseSummary: {
    licenseCount: 0,
    usedCount: 0,
    validFrom: null,
    validTo: null,
    isValid: false,
  },
  topSenders: [],
};

var getters = {};

var actions = {
  actGetLicenseSummary({ commit }) {
    axiosAPI.instance.show = true;
    axiosAPI.instance.post("/license/summary").then((response) => {
      if (response.data.success) {
        commit("mutLicenseSummary", response.data.data);
      }
    });
    // .catch((e) => {
    //   EG.PROGRESS(false);
    //   EG.setAlert(`Problem handling something: ${e}.`);
    // });
  },
  actGetNumOfEmail({ commit }) {
    axiosAPI.instance.show = true;
    axiosAPI.instance.post("/statistics/numOfEMail/24").then((response) => {
      if (response.data.success) {
        commit("mutNumOfEmail", response.data.data);
      }
    });
    // .catch((e) => {
    //   EG.PROGRESS(false);
    //   EG.setAlert(`Problem handling something: ${e}.`);
    // });
  },
  actGetNumOfClientConnectByCountry({ commit }) {
    axiosAPI.instance.show = true;
    axiosAPI.instance
      .post("/statistics/numOfClientConnectByCountry/7")
      .then((response) => {
        if (response.data.success) {
          commit("mutNumOfClientConnectByCountry", response.data.data);
        }
      })

  },
  actGetNumOfUserByCountry({ commit }) {
    axiosAPI.instance.show = true;
    axiosAPI.instance
      .post("/statistics/numOfUserByCountry/7")
      .then((response) => {
        if (response.data.success) {
          commit("mutNumOfUserByCountry", response.data.data);
        }
      })

  },
  actGetNumOfEmailByCountry({ commit }) {
    axiosAPI.instance.show = true;
    axiosAPI.instance
      .post("/statistics/numOfEMailByCountry/7")
      .then((response) => {
        if (response.data.success) {
          commit("mutNumOfEmailByCountry", response.data.data);
        }
      })

  },
  actGetNumOfEmailByDays({ commit }) {
    axiosAPI.instance.show = true;
    axiosAPI.instance
      .post("/statistics/numOfEMailByDays/7")
      .then((response) => {
        if (response.data.success) {
          commit("mutNumOfEmailByDays", response.data);
        }
      });
    // .catch((e) => {
    //   EG.PROGRESS(false);
    //   EG.setAlert(`Problem handling something: ${e}.`);
    // });
  },
  actGetTopSendersByHours({ commit }) {
    axiosAPI.instance.show = true;
    axiosAPI.instance.post("/statistics/topSender/5/168").then((response) => {
      if (response.data.success) {
        commit("mutTopSendersByHours", response.data.data);
      }
    });
    // .catch((e) => {
    //   EG.PROGRESS(false);
    //   EG.setAlert(`Problem handling something: ${e}.`);
    // });
  },
};

var mutations = {
  mutLicenseSummary(state, data) {
    state.licenseSummary = data;
  },
  mutNumOfEmail(state, data) {
    state.numOfEmail = data;
  },
  mutNumOfEmailByDays(state, data) {
    state.numOfEmailByDays = data.data;
    state.numOfEmailByDaysTotalCount = data.totalCount;
  },
  mutNumOfUserByCountry(state, data) {
    state.numOfUserByCountry = data;
  },
  mutNumOfClientConnectByCountry(state, data) {
    state.numOfClientConnectByCountry = data;
  },
  mutNumOfEmailByCountry(state, data) {
    state.numOfEmailByCountry = data;
  },
  mutTopSendersByHours(state, data) {
    state.topSenders = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
