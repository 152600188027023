<template>
  <v-container fluid>
    <div>
      <v-row justify="center">
        <v-col md="6">
          <v-alert type="error" justify="center"
            >Your Subscription/License expired.</v-alert
          >
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col md="6" class="text-center">Click below to fix the issue. </v-col>
      </v-row>
      <v-row justify="center"
        ><v-col md="6" class="text-center">
          <v-btn v-on:click="actOpenSubscriptionPortal"
            >Subscription Portal <v-icon>mdi-open-in-new</v-icon></v-btn
          ></v-col
        ></v-row
      >
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { AppConfig } from "../config.js";

export default {
  data: () => ({}),
  methods: {
    ...mapActions("account", ["actOpenSubscriptionPortal"]),
  },
};
</script>
