<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="6">
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="80"
            :width="10"
          ></v-progress-circular></div></v-col
    ></v-row>
    <v-row justify="center">
      <v-col md="6"
        ><div class="text-center text-body-1">Loading...</div></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { UserManager } from "oidc-client";
import { AppConfig } from "../config.js";
import axiosAPI from "../store/asiosAPI";
import {useAppInsights} from "../services/AppInsights"

export default {
  data: () => ({
    loadCompleted: false,
  }),
  computed: {
    ...mapState("account", ["status"]),
  },
  methods: {
    ...mapActions("account", ["actGetStatus"]),
    ...mapActions("users", ["actSetReleaseNotesState"]),
  },
  watch: {
    status: function () {
      if (this.loadCompleted) {
        if (this.status == "active") {
          this.actSetReleaseNotesState(false);
          window.location.href = "/";
        } else if (this.status == "trial-expired") {
          window.location.href = "/login-error-trialexpired";
        } else {
          window.location.href = "/login-error-expired";
        }
      }
    },
  },
  mounted() {
    var mgr = new UserManager({
      response_mode: "query",
    });

    var currentPage = this;

    mgr
      .signinRedirectCallback()
      .then(async function (user) {
        var needLogin = false;

        if (!user.profile[AppConfig.accountIdKey]) {
          var linkSuccess = false;
          axiosAPI.instance.show = false;
          await axiosAPI.instance
            .post("/user/linkexisting", { externalId: user.profile["sub"] })
            .then((response) => {
              linkSuccess = response.data.data;
            });
          if (linkSuccess) {
            window.location.href = "/login-account-linked";
          } else {
            window.location.href = "/login-error-noaccess";
          }
        } else {
          needLogin = true;
        }

        if (needLogin) {
          axiosAPI.instance.show = false;
          axiosAPI.instance
            .post(
              "/user/logon",
              { externalId: user.profile["sub"] },
              {
                headers: {
                  "enki-account-id": user.profile[AppConfig.accountIdKey],
                  Authorization: `Bearer ${user.access_token}`,
                },
              }
            )
            .then((response) => {
              if (response.data.data) {
                currentPage.actGetStatus({
                  accountId: user.profile[AppConfig.accountIdKey],
                  accessToken: user.access_token,
                });
                currentPage.loadCompleted = true;
              } else {
                window.location.href = "/login-error-noaccess";
              }
            })
            .catch(function (err) {
              //console.log(err);
              useAppInsights().trackException({exception: new Error("API call failed on url " + err.config.url + " Error : "+ JSON.stringify(err))});
            });
        }
      })
      .catch(function (err) {
        if (err) window.location.href = "/login-error-noaccess";
        // console.log(err);
        useAppInsights().trackException({exception: new Error("API call failed on url " + err.config.url + " Error : "+ JSON.stringify(err))});
      });
  },
};
</script>
