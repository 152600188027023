<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="6">
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="80"
            :width="10"
          ></v-progress-circular></div></v-col
    ></v-row>
    <v-row justify="center">
      <v-col md="6"
        ><div class="text-center text-body-1">
          {{ this.loadingText }}
        </div></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { AppConfig } from "../config.js";
import axiosAPI from "../store/asiosAPI";

export default {
  data: () => ({
    loadCompleted: false,
    loadingText: "Loading...",
  }),
  computed: {
    ...mapState("account", ["status"]),
  },
  mounted() {
    var accountid = this.$route.query.state;
    var authCode = this.$route.query.code;
    var error = this.$route.query.error;
    var error_description = this.$route.query.error_description;

    if (!error) {
      this.loadingText = "Grant Enki Exchange Administrator Right...";
      axiosAPI.instance
        .post("/m365/grant-permission/", {
          accountId: accountid,
          authCode: authCode,
          returnUrl: AppConfig.baseUrl + "/m365/setup-tenant-callback",
        })
        .then((response) => {
          this.loadingText = "Enable External Recipient MailTip...";
          axiosAPI.instance
            .post("/m365/enable-external-mailtip/", {
              accountId: accountid,
            })
            .then((response) => {
              this.loadingText =
                "Enable Support for On Send Add-In for all OWA Policy...";
              axiosAPI.instance
                .post("/m365/enable-on-send-support/", {
                  accountId: accountid,
                })
                .then((response) => {
                  this.loadingText = "Import Microsoft 365 Domains to Enki...";
                  axiosAPI.instance
                    .post("/m365/import-domains/", {
                      accountId: accountid,
                    })
                    .then((response) => {
                      this.loadingText = "Enable Outlook External Tagging...";
                      axiosAPI.instance
                        .post("/m365/enable-external-tag/", {
                          accountId: accountid,
                        })
                        .then((response) => {
                          this.loadingText =
                            "Done. Redirecting back to admin page...";
                          window.location.href = "/account";
                        });
                    });
                });
            });
        });
    }
  },
};
</script>