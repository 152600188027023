import axiosAPI from "../asiosAPI";
import EG from "../../common/plugin";
import mainOidc from "../../auth/index.js";
import { AppConfig } from "../../config";
import store from "../../store/index";

var state = {
  name: "globalsetting",
  currHead: "Global Setting",
  init: {
    lbl: {
      title: "Global Setting",
      internalDomain: "Internal Domain",
      connection: "Connection",
      promptMessage: "Prompt Message",
      smtpHeader: "SMTP Header",
      adConnection: "AD Connection",
      o365Connection: "M365 Connection",
      externalMsg: "External Message",
      blacklistMsg: "Blacklist Message",
      logoSetting: "Logo",
      checkTypeSetting: "Check Type",
      logo: "Company Logo",
      checkType: "Outlook Check Type",
      isSMTPEditing: false,
      isConnEditing: false,
      isPromptEditing: false,
      isLogoEditing: false,
      isCheckTypeEditing: false,
      connAccount: "Account",
      connPassword: "Password",
      ExternalMailWarning: "External Mail Warning Message",
      server: "Server",
      isFunctionTypeEditing: false,
      importO365: "Import from M365",
    },
    statusSelect: {
      text: "",
      value: "",
      toolTips: "",
    },
    dialogAction: "",
    connDialogAction: "",
    dialogOpen: false,
    connDialogOpen: false,
    formData: {
      accountId: null,
      internalDomains: [],
      connections: [],
      promptMessages: [],
      smtpHeader: { headerMsg: "" },
      account: {},
    },
    formSearch: {
      accountId: "00000000-0000-0000-0000-000000000000",
    },
  },
  formData: {
    accountId: null,
    internalDomains: [],
    connections: [],
    promptMessages: [],
    smtpHeader: { headerMsg: "" },
    account: {},
  },
  formSearch: {
    accountId: "00000000-0000-0000-0000-000000000000",
  },
  domainData: {
    Id: "00000000-0000-0000-0000-000000000000",
    AccountId: null,
    InternalDomain1: null,
  },
  exDomainData: {
    Id: "00000000-0000-0000-0000-000000000000",
    AccountId: null,
    InternalDomain1: [],
  },
  connectionData: {
    id: "00000000-0000-0000-0000-000000000000",
    accountId: null,
    type: 0,
    ldapType: 0,
    connection1: null,
    account: null,
    password: null,
  },
  excelData: {
    tableData: [],
    tableHeader: [],
  },
};

var getters = {
  userAccountId: function (state) {
    if (AppConfig.deploymentType != "Cloud") {
      return AppConfig.accountId;
    } else {
      if (mainOidc.userProfile != undefined)
        return mainOidc.userProfile[AppConfig.accountIdKey];
      else return "00000000-0000-0000-0000-000000000000";
    }
  },
};

var actions = {
  actIniForm({ commit }) {
    commit("mutIniForm");
  },
  actIniConnForm({ commit }) {
    commit("mutIniConnForm");
  },
  actInitAllData({ commit }) {
    commit("mutIniSearch");
  },
  actSearch({ commit }, isManual) {
    if (isManual) {
      commit("mutIniPage");
    }
    axiosAPI.instance.show = isManual;
    axiosAPI.instance
      .post("/globalsettings/GetGlobalSettings")
      .then((response) => {
        // window.console.log(response.data)
        if (response.data.success) commit("mutFillFormData", response.data);
      });
    // .catch((e) => {
    //   EG.PROGRESS(false);
    //   EG.setAlert(`Problem handling something: ${e}.`);
    // });
  },
  actSave({ commit }, type) {
    var _formdata = state.formData;

    _formdata.accountId = getters.userAccountId();

    var url = "/globalsettings/Update_" + type;
    _formdata.account.checkType = _formdata.account.checkType.toString();
    if (type == "SMTP") {
      state.init.lbl.isSMTPEditing = !state.init.lbl.isSMTPEditing;
      if (state.formData.smtpHeader == null)
        state.formData.smtpHeader.id = "00000000-0000-0000-0000-000000000000";
    } else if (type == "Connection") {
      state.init.lbl.isConnEditing = !state.init.lbl.isConnEditing;
    } else if (type == "PromptMessage") {
      state.init.lbl.isPromptEditing = !state.init.lbl.isPromptEditing;
    } else if (type == "Logo") {
      state.init.lbl.isLogoEditing = !state.init.lbl.isLogoEditing;
    } else if (type == "CheckType") {
      state.init.lbl.isCheckTypeEditing = !state.init.lbl.isCheckTypeEditing;
    } else if (type == "Function") {
      state.init.lbl.isFunctionTypeEditing = !state.init.lbl
        .isFunctionTypeEditing;
    }

    axiosAPI.instance.show = true;
    axiosAPI.instance.post(url, _formdata).then((response) => {
      if (response.data.success) {
        commit("mutFillFormData", response.data);
      }
    });
  },
  actSaveInternalDomain({ commit }) {
    var _domain = state.domainData;
    _domain.AccountId = getters.userAccountId();
    if (state.init.dialogAction == "create")
      _domain.Id = "00000000-0000-0000-0000-000000000000";

    var url = "/globalsettings/";
    url += state.init.dialogAction + "_InternalDomain";

    axiosAPI.instance.show = true;
    axiosAPI.instance
      .post(url, _domain)
      .then((response) => {
        if (response.data.success) {
          actions.actCloseDialog({ commit });
          commit("mutFillFormData", response.data);
        }
      })
      .finally(() => {
        actions.actCloseDialog({ commit });
      });
  },
  actSaveConnections({ commit }) {
    var _conn = JSON.parse(JSON.stringify(state.connectionData));
    var url = "/globalsettings/";
    url += state.init.connDialogAction + "_Connection";

    _conn.accountId = getters.userAccountId();
    _conn.type = AppConfig.deploymentType != "Cloud" ? 0 : 1;

    if (state.init.connDialogAction == "create")
      _conn.id = "00000000-0000-0000-0000-000000000000";

    _conn.connection1 =
      state.connectionData.ldapType + "://" + state.connectionData.connection1;

    axiosAPI.instance.show = true;
    axiosAPI.instance
      .post(url, _conn)
      .then((response) => {
        if (response.data.success) {
          actions.actCloseConnDialog({ commit });
          commit("mutFillFormData", response.data);
        } else actions.actCloseConnDialog({ commit });
      })
      .finally(() => {
        actions.actCloseConnDialog({ commit });
      });
  },
  actIniSearch({ commit }) {
    commit("mutIniSearch");
  },
  actSetRow({ commit }, RowData) {
    commit("mutSetRow", RowData);
  },
  actSetConnRow({ commit }, RowData) {
    commit("mutSetConnRow", RowData);
  },
  actShowDialog({ commit }, payLoad) {
    commit("mutDialogSet", payLoad);
    commit("mutDialogCtrl", true);
  },
  actCloseDialog({ commit }) {
    state.domainData.InternalDomain1 = "";
    commit("mutDialogCtrl", false);
  },
  actShowConnDialog({ commit }, payLoad) {
    commit("mutConnDialogSet", payLoad);
    commit("mutConnDialogCtrl", true);
  },
  actCloseConnDialog({ commit }) {
    state.connectionData.connection1 = "";
    state.connectionData.account = "";
    state.connectionData.password = "";
    state.connectionData.ldapType = "LDAP";
    commit("mutConnDialogCtrl", false);
  },
  actCancel({ commit }, type) {
    commit("mutCancelEditing", {
      tmpData: state.init.formData,
      editType: type,
    });
  },
  actEdit({ commit }, type) {
    commit("mutEditing", {
      tmpData: state.init.formData,
      editType: type,
    });
  },
  actImportO365({ commit }) {
    var url = "/globalsettings/Import_DomainFromO365";

    axiosAPI.instance.show = true;
    axiosAPI.instance
      .post(url)
      .then((response) => {
        if (response.data.success) {
          actions.actCloseDialog({ commit });
          commit("mutFillFormData", response.data);
        }
      })
      .finally(() => {
        actions.actCloseDialog({ commit });
      });
  },
  actSaveExcelImport({ commit }) {
    var _formData = state.formData;
    _formData.accountId = getters.userAccountId();

    var importMailLists = [];
    state.excelData.tableData.map((td) => {
      importMailLists.push({
        id: _formData.id,
        accountId: _formData.accountId,
        internalDomain1: td.Domains,
      });
    });

    axiosAPI.instance.show = true;
    axiosAPI.instance
      .post("/globalsettings/Import_InternalDomain", importMailLists)
      .then((response) => {
        if (response.data.success) {
          actions.actCloseDialog({ commit });
          // commit("mutIniSearch");
          actions.actSearch({ commit });
        }
        commit("mutIniForm");
      });
  },
};

var mutations = {
  mutFillFormData(state, res) {
    if (res.data != null) {
      state.formData.internalDomains = res.data.internalDomains;
      state.formData.smtpHeader = res.data.smtpHeader;
      state.formData.accountId = res.data.id;
      state.formData.connections = res.data.connections;
      state.formData.promptMessages = res.data.promptMessages;
      state.formData.promptMessages.forEach(function (item) {
        if (item.imageData != null) {
          item.imageData = Array.from(
            Uint8Array.from(atob(item.imageData), (c) => c.charCodeAt(0))
          );
        }
      });
      state.formData.account = res.data.account;
      if (state.formData.account.logo != null) {
        state.formData.account.logo = Array.from(
          Uint8Array.from(atob(state.formData.account.logo), (c) =>
            c.charCodeAt(0)
          )
        );
      }

      state.formData.account.checkType = state.formData.account.checkType.split(
        ","
      );

      state.init.formData = JSON.parse(JSON.stringify(state.formData));
    } else {
      state.formData.internalDomain = [];
      state.formData.smtpHeader = { headerMsg: "" };
      state.formData.connections = [];
      state.formData.promptMessage = [];
      state.formData.accountId = getters.userAccountId();
      state.formData.account = {};
    }
  },
  mutSetRow(state, RowData) {
    state.domainData.Id = RowData.id;
    state.domainData.AccountId = RowData.accountId;
    state.domainData.InternalDomain1 = RowData.internalDomain1;
  },
  mutSetConnRow(state, RowData) {
    var conn = RowData.connection1.split("://");
    state.connectionData.id = RowData.id;
    state.connectionData.connection1 = conn.length < 2 ? conn[0] : conn[1];
    state.connectionData.account = RowData.account;
    state.connectionData.type = RowData.type;
    state.connectionData.ldapType = conn.length < 2 ? "LDAP" : conn[0];
  },
  mutDialogSet(state, payLoad) {
    state.init.dialogAction = payLoad.action;
  },
  mutDialogCtrl(state, Status) {
    state.init.dialogOpen = Status;
  },
  mutConnDialogSet(state, payLoad) {
    state.init.connDialogAction = payLoad.action;
  },
  mutConnDialogCtrl(state, Status) {
    state.init.connDialogOpen = Status;
  },
  mutIniForm(state) {
    state.domainData = { InternalDomain1: "" };
  },
  mutIniConnForm(state) {
    state.connectionData = {
      id: "00000000-0000-0000-0000-000000000000",
      accountId: null,
      type: 0,
      connection1: null,
      account: null,
      password: null,
      ldapType: "LDAP",
    };
  },
  mutIniSearch(state) {
    state.formSearch = state.init.formSearch;
  },
  mutIniPage(state) {
    state.formSearch.Page.page = 1;
  },
  mutCancelEditing(state, { tmpData, editType }) {
    if (editType == "SMTP") {
      state.init.lbl.isSMTPEditing = !state.init.lbl.isSMTPEditing;
      state.formData.smtpHeader = JSON.parse(
        JSON.stringify(tmpData.smtpHeader)
      );
    } else if (editType == "Connection") {
      state.init.lbl.isConnEditing = !state.init.lbl.isConnEditing;
      state.formData.connections = JSON.parse(
        JSON.stringify(tmpData.connections)
      );
    } else if (editType == "PromptMessage") {
      state.init.lbl.isPromptEditing = !state.init.lbl.isPromptEditing;
      state.formData.promptMessages = JSON.parse(
        JSON.stringify(tmpData.promptMessages)
      );
      state.formData.account = JSON.parse(JSON.stringify(tmpData.account));
    } else if (editType == "Logo") {
      state.init.lbl.isLogoEditing = !state.init.lbl.isLogoEditing;
      state.formData.account = JSON.parse(JSON.stringify(tmpData.account));
    } else if (editType == "CheckType") {
      state.init.lbl.isCheckTypeEditing = !state.init.lbl.isCheckTypeEditing;
      state.formData.account = JSON.parse(JSON.stringify(tmpData.account));
    } else if (editType == "Function") {
      state.init.lbl.isFunctionTypeEditing = !state.init.lbl
        .isFunctionTypeEditing;
      state.formData.account = JSON.parse(JSON.stringify(tmpData.account));
    }
  },
  mutEditing(state, { tmpData, editType }) {
    state.init.lbl.isConnEditing = false;
    state.init.lbl.isSMTPEditing = false;
    state.init.lbl.isPromptEditing = false;
    state.init.lbl.isLogoEditing = false;
    state.init.lbl.isCheckTypeEditing = false;
    state.init.lbl.isFunctionTypeEditing = false;
    state.formData.smtpHeader = JSON.parse(JSON.stringify(tmpData.smtpHeader));
    state.formData.connections = JSON.parse(
      JSON.stringify(tmpData.connections)
    );
    state.formData.promptMessages = JSON.parse(
      JSON.stringify(tmpData.promptMessages)
    );
    state.formData.account = JSON.parse(JSON.stringify(tmpData.account));
    state.formData.account = JSON.parse(JSON.stringify(tmpData.account));

    if (editType == "Connection") state.init.lbl.isConnEditing = true;
    else if (editType == "SMTP") state.init.lbl.isSMTPEditing = true;
    else if (editType == "PromptMessage") state.init.lbl.isPromptEditing = true;
    else if (editType == "Logo") state.init.lbl.isLogoEditing = true;
    else if (editType == "CheckType") state.init.lbl.isCheckTypeEditing = true;
    else if (editType == "Function")
      state.init.lbl.isFunctionTypeEditing = true;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
