<template>
  <v-dialog
    v-model="alert.dialog"
    @keydown.esc="actCloseAlert()"
    max-width="30%"
  >
    <v-card class="text-xs-center pa-3">
      <v-list-item>
        <v-list-item-avatar tile size="80"
          ><v-icon class="black--text" x-large>{{
            alert.icon
          }}</v-icon></v-list-item-avatar
        >
        <v-list-item-content>
          <v-list-item-title
            :class="alert.color + '--text mb-2'"
            style="font-size: 30px"
          >
            <b>{{ alert.title }}</b></v-list-item-title
          >
          <v-card
            flat
            class="pa-3 my_word"
            height="80px"
            style="overflow: auto"
          >
            {{ alert.message }}
          </v-card>
          <v-card flat class="text-xs-center">
            <v-btn :class="'my_btn-' + alert.color" @click="actCloseAlert()">{{
              Dict.close
            }}</v-btn>
          </v-card>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  computed: {
    ...mapState("mainCtr", ["alert"]),
  },
  methods: {
    ...mapActions({
      actCloseAlert: "mainCtr/actCloseAlert",
    }),
  },
};
</script>
