<template>
  <v-dialog v-model="eulaDialog" fullscreen persistent>
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Accpet End User License Agreement
      </v-card-title>
      <v-card-text>
        <div class="Contract">
          <iframe :src="pathUrl"></iframe>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1" text @click="notAccpet()">
          Not Accpet
        </v-btn>
        <v-btn color="green darken-1" text @click="accpet()"> Accept </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { AppConfig } from "../config";
import mainOidc from "../auth/index";

export default {
  data() {
    return {
      pathUrl: AppConfig.eulaUrl,
    };
  },
  created() {},
  computed: {
    ...mapState("account", ["eulaDialog"]),
  },
  methods: {
    ...mapActions("account", ["actAcceptEULA"]),
    accpet() {
      var id = "";
      if (AppConfig.deploymentType != "Cloud") id = AppConfig.accountId;
      else id = mainOidc.userProfile[AppConfig.accountIdKey];

      this.actAcceptEULA({
        accountId: id,
      });
    },
    notAccpet() {
      this.eulaDialog = false;
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.Contract {
  iframe {
    width: 100%;
    height: 600px;
    border: none;
  }
}
</style>
