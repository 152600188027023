import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import { useAppInsights } from "./services/AppInsights";

// Import the Auth0 configuration
import { domain, clientId, audience } from "../auth_config.json";

// Import the plugin here
import EG from "../src/common/plugin";
import Notifications from "vue-notification";
import velocity from "velocity-animate";
import Vue2Editor from "vue2-editor";
import mainAuth from "./auth";
import VueExcelXlsx from "vue-excel-xlsx";

import Chartkick from "chartkick";
import VueChartkick from "vue-chartkick";
import UserAvatar from "./components/UserAvatar.vue";
import VueAppInsights from 'vue-application-insights'
import {AppConfig}  from "./config";

Vue.use(Vuelidate);
Vue.use(Notifications, { velocity });
Vue.use(VueAxios, axios);
Vue.use(Vue2Editor);
Vue.use(VueExcelXlsx);
Vue.use(VueChartkick, { Chartkick });
Vue.use(require("vue-moment"));
Vue.component('user-avatar',UserAvatar);
//useAppInsights().trackEvent({ name: "App Loaded!" });
Vue.use(VueAppInsights, {
  id: AppConfig.appInsightsId,
  router
})
Vue.use({
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});
Vue.mixin(EG.mixinData);
Vue.config.productionTip = false;


mainAuth.startup().then((ok) => {
  if (ok) {
    new Vue({
      router,
      store,
      vuetify,
      useAppInsights,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
