<template>
  <div>
    <button type="button" class="download-btn" v-on:click="download">
      Download
    </button>
  </div>
</template>

<script>
import XLSX from "xlsx";
export default {
  data() {
    return {
      items: [
        {
          Date: "",
          Organisation: "Eventus",
          Domains: "domain.com",
          Status: "encrypt",
          Ref: "",
        },
      ],
    };
  },
  methods: {
    download: function () {
      const data = XLSX.utils.json_to_sheet(this.items);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(wb, "template.xlsx");
    },
  },
};
</script>

<style lang="scss" scoped>
.download-btn {
  background-color: DodgerBlue;
  border: none;
  color: white;
  padding: 4px 15px;
  margin: 3px 0;
  cursor: pointer;
  font-size: 14px;
  border-radius: 6px;
}
/* Darker background on mouse-over */
.download-btn:hover {
  background-color: RoyalBlue;
}
</style>
