<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title
            ><span>Mail Lists</span>
            <v-spacer />
            <!-- 新增 -->
            <v-btn
              outlined
              text
              @click="actIniForm(), actShowDialog({ action: 'create' })"
            >
              <v-icon small>mdi-plus</v-icon>
              <span>{{ Dict.new }}</span>
            </v-btn>
            <upload-excel
              :on-success="handleSuccess"
              :before-upload="beforeUpload"
              v-if="!importSuccess"
            />
            <v-dialog v-model="excelDialog" width="400">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  fab
                  text
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark> mdi-information-outline </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title> Can't Import Excel File? </v-card-title>
                <v-card-text style="margin-top: 20px">
                  <p>Please check your header.</p>
                  <p>
                    Required header:
                    <br />
                    Date | Organisation | Domains | Status | Ref
                  </p>
                  <p>
                    Or click below button to download a template
                    <br />
                    <DownloadExcel />
                  </p>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" text @click="excelDialog = false">
                    Back
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-divider> </v-divider>
          <v-card-title v-if="importSuccess">
            <v-spacer />
            <span style="font-size: 15px; font-weight: 500; margin-right: 20px"
              >Are you sure import
              <span style="color: #f05454">{{
                this.excelData.tableData.length
              }}</span>
              domains from your excel?</span
            >
            <v-btn
              color="my_btn-3"
              dark
              @click="saveExcelImport()"
              style="margin-right: 20px"
            >
              {{ Dict.confirm }}
              <v-icon dark right>mdi-check-circle</v-icon>
            </v-btn>
            <v-btn color="my_btn-2" dark @click="cancel()">
              {{ Dict.cancel }}
              <v-icon dark right>mdi-cancel</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider> </v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <v-skeleton-loader
                  v-if="progress"
                  class="mx-auto"
                  type="list-item-three-line"
                ></v-skeleton-loader>
                <v-card v-show="!progress">
                  <v-card-title>Search</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6" class="ml-md-auto">
                        <v-text-field
                          v-model="formSearch.mail"
                          clearable
                          hide-details
                          prepend-inner-icon="mdi-magnify"
                          :label="init.lbl.mailAddress"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" class="ml-md-auto">
                        <!-- <v-select
                          v-model="formSearch.isActive"
                          :label="init.lbl.isActive"
                          :items="items"
                          attach
                          chips
                          multiple
                        ></v-select> -->
                        <v-radio-group v-model="formSearch.isActive" row>
                          <template v-slot:label>
                            <div>{{ init.lbl.isActive }} :</div>
                          </template>
                          <v-radio :label="Dict.allItem" value="All"></v-radio>
                          <v-radio
                            :label="Dict.enable"
                            value="Enable"
                          ></v-radio>
                          <v-radio
                            :label="Dict.disable"
                            value="Disable"
                          ></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- 查詢 -->
                    <v-btn outlined text @click="actSearch(true)">
                      <v-icon small>mdi-magnify</v-icon
                      ><span>{{ Dict.search }}</span>
                    </v-btn>

                    <!-- 清除 -->
                    <v-btn outlined text @click="actIniSearch(formData)">
                      <v-icon small>mdi-delete-outline</v-icon>
                      <span>{{ Dict.clear }}</span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-skeleton-loader
                  v-if="progress"
                  class="mx-auto"
                  type="table"
                ></v-skeleton-loader>
                <v-data-table
                  v-show="!progress"
                  class="elevation-1 my_table_sm"
                  :headers="gridMailList.headers"
                  :items="gridMailList.data"
                  :loading="gridMailList.loading"
                  :server-items-length="gridMailList.totalCount"
                  :hide-default-footer="gridMailList.data.length == 0"
                  :options.sync="gridMailList.page"
                  :items-per-page-text="Dict.grid.rowsPerPageText"
                  :no-data-text="Dict.grid.noDataText"
                  :no-results-text="Dict.grid.noResultsText"
                  :footer-props="footerProps"
                  items-per-page="20"
                >
                  <template v-slot:[`item.id`] class="display:none"> </template>
                  <template v-slot:[`item.updateDate`]="{ item }">
                    <span>{{
                      item.updateDate | localMoment(AppConfig.dateTimeFormat)
                    }}</span>
                  </template>
                  <template v-slot:[`item.isActive`]="{ item }">
                  
                     <v-icon
                                small
                                v-if="item.isActive"
                                color="green darken-2"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                small
                                v-if="!item.isActive"
                                color="red darken-2"
                                >mdi-close</v-icon
                              >
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn
                      x-small
                      color="primary"
                      fab
                      dark
                      @click="
                        actSetRow(item), actShowDialog({ action: 'update' })
                      "
                    >
                      <v-icon dark>mdi-pencil</v-icon>
                    </v-btn>

                    <v-btn
                      x-small
                      color="error"
                      fab
                      dark
                      @click="
                        actSetRow(item), actShowDialog({ action: 'delete' })
                      "
                    >
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <mailDialog></mailDialog>
  </v-container>
</template>

<script>
import mailDialog from "./MailListDialog";
import UploadExcel from "../../components/UploadExcel.vue";
import DownloadExcel from "../../components/DownloadExcel.vue";

import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  components: {
    mailDialog,
    UploadExcel,
    DownloadExcel,
  },
  data: () => ({
    items: ["Enable", "Disable"],
    importSuccess: false,
    excelDialog: false,
    footerProps: { "items-per-page-options": [20, 30, 50, 100, -1] },
  }),
  mounted() {
    this.loadTable();
  },
  computed: {
    ...mapState("mailList", [
      "formSearch",
      "gridMailList",
      "init",
      "formData",
      "excelData",
    ]),
    ...mapState("mainCtr", ["progress"]),
  },
  methods: {
    ...mapActions("mailList", [
      "actReadMailLists",
      "actIniForm",
      "actIniSearch",
      "actSetRow",
      "actShowDialog",
      "actCloseDialog",
      "actSearch",
      "actSaveExcelImport",
    ]),
    loadTable() {
      this.actIniForm();
      this.actSearch();
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    handleSuccess({ results, header }) {
      this.excelData.tableData = results;
      this.excelData.tableHeader = header;
      this.importSuccess = true;
    },
    saveExcelImport() {
      this.actSaveExcelImport();
      this.importSuccess = false;
    },
    cancel() {
      this.importSuccess = false;
    },
  },
  filters: {
    localMoment: function (date, format) {
      return moment.utc(date).local().format(format);
    },
  },
};
</script>
