<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card flat class="pa-0" height="40px">
          <h6 class="my_header">
            <span>{{ init.lbl.title }} {{ Dict.maintain }}</span>
          </h6>
        </v-card>
        <v-skeleton-loader
          v-if="progress"
          class="mx-auto"
          type="list-item-three-line, divider,list-item-avatar,list-item-avatar, divider, list-item-avatar, divider, list-item-three-line,divider,list-item-three-line"
        />
      </v-col>
    </v-row>
    <v-row v-if="!progress">
      <v-col>
        <v-card>
          <v-card-title
            >{{ init.lbl.internalDomain }}
            <v-spacer></v-spacer>

            <v-btn
              color="teal"
              class="ma-2 white--text"
              @click="actImportO365()"
            >
              <v-icon small>mdi-upload</v-icon>
              <span>{{ init.lbl.importO365 }}</span>
            </v-btn>

            <upload-excel
              :on-success="handleSuccess"
              :before-upload="beforeUpload"
              v-if="!importSuccess"
            />
            <v-btn
              outlined
              text
              @click="actIniForm(), actShowDialog({ action: 'create' })"
            >
              <v-icon small> mdi-plus </v-icon>
              <span>{{ Dict.new }}</span>
            </v-btn>
            <v-dialog v-model="excelDialog" width="400">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  fab
                  text
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark> mdi-information-outline </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title> Can't Import Excel File? </v-card-title>
                <v-card-text style="margin-top: 20px">
                  <p>Please check your header.</p>
                  <p>
                    Required header:
                    <br />
                    Date | Organisation | Domains | Status | Ref
                  </p>
                  <p>
                    Or click below button to download a template
                    <br />
                    <DownloadExcel />
                  </p>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" text @click="excelDialog = false">
                    Back
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-divider> </v-divider>
          <v-card-title v-if="importSuccess">
            <v-spacer />
            <span style="font-size: 15px; font-weight: 500; margin-right: 20px"
              >Are you sure import
              <span style="color: #f05454">{{
                this.excelData.tableData.length
              }}</span>
              domains from your excel?</span
            >
            <v-btn
              color="my_btn-3"
              dark
              @click="saveExcelImport()"
              style="margin-right: 20px"
            >
              {{ Dict.confirm }}
              <v-icon dark right>mdi-check-circle</v-icon>
            </v-btn>
            <v-btn color="my_btn-2" dark @click="cancel()">
              {{ Dict.cancel }}
              <v-icon dark right>mdi-cancel</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text v-show="!progress">
            <v-list v-for="item in formData.internalDomains" :key="item.Id">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-earth </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.internalDomain1 }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-btn
                  icon
                  x-small
                  fab
                  @click="actSetRow(item), actShowDialog({ action: 'update' })"
                >
                  <v-icon color="lighten-1"> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  icon
                  x-small
                  color="error"
                  fab
                  @click="actSetRow(item), actShowDialog({ action: 'delete' })"
                >
                  <v-icon color="lighten-1"> mdi-delete </v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <globalDialog />
      </v-col>
    </v-row>
    <v-row v-if="!progress">
      <v-col>
        <v-card>
          <v-card-title>
            {{ init.lbl.connection }}
            <v-spacer />
            <v-btn
              outlined
              text
              @click="actIniConnForm(), actShowConnDialog({ action: 'create' })"
            >
              <v-icon small> mdi-plus </v-icon>
              <span>{{ Dict.new }}</span>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-list
              v-for="item in formData.connections.filter(function (u) {
                if (AppConfig.deploymentType != 'Cloud') return u.type == 0;
                else return u.type == 1;
              })"
              :key="item.Id"
            >
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-wan </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.connection1 }}</v-list-item-title>
                </v-list-item-content>
                <v-btn
                  icon
                  x-small
                  fab
                  @click="
                    actSetConnRow(item), actShowConnDialog({ action: 'update' })
                  "
                >
                  <v-icon color="lighten-1"> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  icon
                  x-small
                  color="error"
                  fab
                  @click="
                    actSetConnRow(item), actShowConnDialog({ action: 'delete' })
                  "
                >
                  <v-icon color="lighten-1"> mdi-delete </v-icon>
                </v-btn>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <connectionDialog />
      </v-col>
    </v-row>
    <v-row v-if="!progress">
      <v-col>
        <v-card>
          <v-card-title>
            {{ init.lbl.smtpHeader }}
            <v-spacer />
            <v-btn
              v-if="!init.lbl.isSMTPEditing"
              outlined
              @click="actEdit('SMTP')"
            >
              <v-icon small> mdi-pencil </v-icon>
              <span>{{ Dict.edit }}</span>
            </v-btn>
            <v-btn
              v-if="init.lbl.isSMTPEditing"
              outlined
              @click="validate('SMTP')"
            >
              <v-icon>mdi-check-circle</v-icon>
              <span>{{ Dict.confirm }}</span>
            </v-btn>
            <v-btn
              v-if="init.lbl.isSMTPEditing"
              color="error"
              dark
              outlined
              @click="actCancel('SMTP')"
            >
              <v-icon>mdi-close</v-icon>
              <span>{{ Dict.cancel }}</span>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-code-tags </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-text-field
                    v-model="formData.smtpHeader.headerMsg"
                    :disabled="!init.lbl.isSMTPEditing"
                    label="SMTP Header"
                    @input="$v.formData.smtpHeader.headerMsg.$touch()"
                    @blur="$v.formData.smtpHeader.headerMsg.$touch()"
                  />
                  <div
                    v-if="!$v.formData.smtpHeader.headerMsg.smtpHeaderFormat"
                    class="input-error"
                  >
                    {{ init.lbl.smtpHeader }} format is incorrect. Only allow
                    characters, numbers and - and should start with "x-"
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!progress">
      <v-col>
        <v-card>
          <v-card-title>
            {{ init.lbl.promptMessage }}
            <v-spacer />
            <div class="text-center" style="margin-right: 20px">
              <v-dialog v-model="dialog" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    fab
                    text
                    dark
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon dark> mdi-information-outline </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title> Prompt Message Setting </v-card-title>
                  <v-card-text style="margin-top: 20px">
                    <p>
                      Here we provide 2 ways to you create prompt box for
                      External and Block warning.
                    </p>
                    <p>
                      Upload image for your prompt box, please make sure your
                      custom text has been empty. (Use an image at 530x350
                      pixels for better-quality.)
                    </p>
                    <p>
                      Or if you want use custom text for your prompt box, please
                      make sure your image has been deleted.
                    </p>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text @click="dialog = false">
                      Back
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <v-btn
              v-if="!init.lbl.isPromptEditing"
              outlined
              @click="actEdit('PromptMessage')"
            >
              <v-icon small> mdi-pencil </v-icon>
              <span>{{ Dict.edit }}</span>
            </v-btn>
            <v-btn
              v-if="init.lbl.isPromptEditing"
              outlined
              @click="actSave('PromptMessage')"
            >
              <v-icon>mdi-check-circle</v-icon>
              <span>{{ Dict.confirm }}</span>
            </v-btn>
            <v-btn
              v-if="init.lbl.isPromptEditing"
              color="error"
              outlined
              @click="actCancel('PromptMessage')"
            >
              <v-icon>mdi-close</v-icon>
              <span>{{ Dict.cancel }}</span>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-list wrap>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo" small> mdi-pencil-ruler </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ init.lbl.ExternalMailWarning }}
                  </v-list-item-subtitle>
                  <v-flex xs12 sm12>
                    <v-text-field
                      v-model="formData.account.externalMailWarning"
                      :disabled="!init.lbl.isPromptEditing"
                      label="Main Paragraph"
                    />
                  </v-flex>
                  <v-flex xs12 sm12>
                    <v-text-field
                      v-if="formData.account.externalMailWarning"
                      v-show="
                        formData.account.externalMailWarningSec ||
                        init.lbl.isPromptEditing
                      "
                      v-model="formData.account.externalMailWarningSec"
                      :disabled="!init.lbl.isPromptEditing"
                      label="Second Paragraph"
                    />
                  </v-flex>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list v-for="item in formData.promptMessages" :key="item.Id">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-message-text-outline </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle v-if="item.type == 0">
                    {{ init.lbl.externalMsg }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-else>
                    {{ init.lbl.blacklistMsg }}
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    <v-container fluid>
                      <v-row>
                        <v-col>
                          Custom Text:
                          <p
                            v-if="item.imageData && item.message"
                            style="color: red; font-size: 12px"
                          >
                            Use Custom Text for pormpt message, please delete
                            image first.
                          </p>
                          <div :id="'editor' + item.type">
                            <vue-editor
                              v-model="item.message"
                              :disabled="!init.lbl.isPromptEditing"
                              :editor-toolbar="customToolbar"
                              style="width: 520px"
                            />
                          </div>
                        </v-col>
                        <v-col>
                          Upload Image:
                          <p
                            v-if="item.imageData && item.message"
                            style="color: red; font-size: 12px"
                          >
                            Use Image for pormpt message, please clear Custom
                            Text first.
                          </p>
                          <div>
                            <imageInput
                              v-model="item.imageData"
                              :data="item.imageData"
                              :is-editable="init.lbl.isPromptEditing"
                              :width="'530px'"
                              :height="'350px'"
                              :type="'prompt'"
                            />
                          </div>
                        </v-col>
                        <v-spacer />
                      </v-row>
                    </v-container>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!progress">
      <v-col>
        <v-card>
          <v-card-title>
            {{ init.lbl.logoSetting }}
            <v-spacer />
            <v-btn
              v-if="!init.lbl.isLogoEditing"
              outlined
              @click="actEdit('Logo')"
            >
              <v-icon small> mdi-pencil </v-icon>
              <span>{{ Dict.edit }}</span>
            </v-btn>
            <v-btn
              v-if="init.lbl.isLogoEditing"
              outlined
              @click="actSave('Logo')"
            >
              <v-icon>mdi-check-circle</v-icon>
              <span>{{ Dict.confirm }}</span>
            </v-btn>
            <v-btn
              v-if="init.lbl.isLogoEditing"
              color="error"
              outlined
              @click="actCancel('Logo')"
            >
              <v-icon>mdi-close</v-icon>
              <span>{{ Dict.cancel }}</span>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo" small> mdi-pencil-ruler </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ init.lbl.logo }}
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    <imageInput
                      v-model="formData.account.logo"
                      :data="formData.account.logo"
                      :is-editable="init.lbl.isLogoEditing"
                      :width="'1200px'"
                      :height="'250px'"
                      :type="'logo'"
                    />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!progress">
      <v-col>
        <v-card>
          <v-card-title>
            {{ init.lbl.checkTypeSetting }}
            <v-spacer />
            <v-btn
              v-if="!init.lbl.isCheckTypeEditing"
              outlined
              @click="actEdit('CheckType')"
            >
              <v-icon small> mdi-pencil </v-icon>
              <span>{{ Dict.edit }}</span>
            </v-btn>
            <v-btn
              v-if="init.lbl.isCheckTypeEditing"
              outlined
              @click="actSave('CheckType')"
            >
              <v-icon>mdi-check-circle</v-icon>
              <span>{{ Dict.confirm }}</span>
            </v-btn>
            <v-btn
              v-if="init.lbl.isCheckTypeEditing"
              color="error"
              outlined
              @click="actCancel('CheckType')"
            >
              <v-icon>mdi-close</v-icon>
              <span>{{ Dict.cancel }}</span>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-playlist-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ init.lbl.checkType }}
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    <v-switch
                      v-model="formData.account.checkType"
                      label="External Check"
                      value="e"
                      color="primary"
                      :readonly="!init.lbl.isCheckTypeEditing"
                    />
                    <v-switch
                      v-model="formData.account.checkType"
                      label="Whitelist/BlackList Check"
                      value="w"
                      :readonly="!init.lbl.isCheckTypeEditing"
                    />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!progress">
      <v-col>
        <v-card>
          <v-card-title>
            Enki Function Type
            <v-spacer />
            <v-btn
              v-if="!init.lbl.isFunctionTypeEditing"
              outlined
              @click="actEdit('Function')"
            >
              <v-icon small> mdi-pencil </v-icon>
              <span>{{ Dict.edit }}</span>
            </v-btn>
            <v-btn
              v-if="init.lbl.isFunctionTypeEditing"
              outlined
              @click="actSave('Function')"
            >
              <v-icon>mdi-check-circle</v-icon>
              <span>{{ Dict.confirm }}</span>
            </v-btn>
            <v-btn
              v-if="init.lbl.isFunctionTypeEditing"
              color="error"
              outlined
              @click="actCancel('Function')"
            >
              <v-icon>mdi-close</v-icon>
              <span>{{ Dict.cancel }}</span>
            </v-btn>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="indigo"> mdi-playlist-check </v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ init.lbl.checkType }}
                  </v-list-item-subtitle>
                  <v-list-item-title>
                    <v-switch
                      v-model="formData.account.enableEnkiCheckO365"
                      label="Enki Check for Office 365"
                      color="primary"
                      :readonly="!init.lbl.isFunctionTypeEditing"
                    />
                    <v-chip-group
                      :value="o365functions"
                      active-class="primary--text"
                      column
                      multiple
                    >
                      <v-chip
                        v-model="formData.account.enableEnkiCheckO365Mac"
                        filter
                        outlined
                        active-class="purple--text"
                        :disabled="
                          !init.lbl.isFunctionTypeEditing &&
                          formData.account.enableEnkiCheckO365
                        "
                      >
                        Enki Check for Mac
                      </v-chip>
                      <v-chip
                        v-model="formData.account.enableEnkiCheckO365Win"
                        filter
                        outlined
                        active-class="purple--text"
                        :disabled="
                          !init.lbl.isFunctionTypeEditing &&
                          formData.account.enableEnkiCheckO365
                        "
                      >
                        Enki Check for Windows
                      </v-chip>
                      <v-chip
                        v-model="formData.account.enableEnkiCheckO365OWA"
                        filter
                        outlined
                        active-class="purple--text"
                        :disabled="
                          !init.lbl.isFunctionTypeEditing &&
                          formData.account.enableEnkiCheckO365
                        "
                      >
                        Enki Check for OWA
                      </v-chip>
                    </v-chip-group>
                    <v-switch
                      v-model="formData.account.enableEnkiCheckOffice"
                      label="Enki Check for Outlook"
                      :readonly="!init.lbl.isFunctionTypeEditing"
                    />
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style>
#responsibilityEdit .ql-editor {
  max-height: 200px;
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import globalDialog from "./GlobalSettingsDialog";
import connectionDialog from "./ConnectionDialog";
import imageInput from "../../components/ImageInput";
import { AppConfig } from "../../config";

import { notDomain, notUri, smtpHeaderFormat } from "../../common/validator";
const { validationMixin, default: Vuelidate } = require("vuelidate");
const { required, minLength } = require("vuelidate/lib/validators");
import UploadExcel from "../../components/UploadExcel.vue";
import DownloadExcel from "../../components/DownloadExcel.vue";

export default {
  components: {
    globalDialog,
    connectionDialog,
    imageInput,
    UploadExcel,
    DownloadExcel,
  },
  data: () => ({
    amenities: [],
    customToolbar: [
      [
        {
          size: ["small", false, "large", "huge"],
        },
      ],
      ["bold", "italic", "underline", "strike"],
      [
        {
          color: [],
        },
        {
          background: [],
        },
      ],
      [
        {
          direction: "rtl",
        },
      ],
      ["clean"],
    ],
    rules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Image size should be less than 2 MB!",
    ],
    textRules: [
      (v) =>
        // v.length <= 100 ||
        "The maximum length for Warning Messgae is 100 characters including spaces.",
    ],
    externalMsgWarningSec: "",
    dialog: false,
    excelDialog: false,
    importSuccess: false,
  }),
  mounted() {
    this.actSearch();
  },
  computed: {
    ...mapState("globalSetting", [
      "formSearch",
      "init",
      "formData",
      "domainData",
      "excelData",
    ]),
    ...mapState("mainCtr", ["progress"]),
    o365functions() {
      var list = [];
      if (this.formData.account.enableEnkiCheckO365) {
        if (this.formData.account.enableEnkiCheckO365Mac) {
          list.push(0);
        }

        if (this.formData.account.enableEnkiCheckO365Win) {
          list.push(1);
        }
        if (this.formData.account.enableEnkiCheckO365OWA) {
          list.push(2);
        }
      }
      return list;
    },
  },
  validations: {
    formData: {
      connections: {
        $each: {
          connection1: {
            minLength: minLength(5),
            notUri,
          },
        },
      },
      smtpHeader: {
        headerMsg: {
          smtpHeaderFormat,
        },
      },
    },
  },
  methods: {
    ...mapActions("globalSetting", [
      "actIniForm",
      "actIniSearch",
      "actSetRow",
      "actShowDialog",
      "actSearch",
      "actSave",
      "actCancel",
      "actEdit",
      "actShowConnDialog",
      "actSetConnRow",
      "actIniConnForm",
      "actImportO365",
      "actSaveExcelImport",
    ]),
    dataUrl(imageData) {
      return "data:image/jpeg;base64," + imageData;
    },
    validate(type) {
      if (type == "Connection") {
        this.$v.formData.connections.$touch();
        // if its still pending or an error is returned do not submit
        if (
          this.$v.formData.connections.$pending ||
          this.$v.formData.connections.$error
        )
          return;
      } else if (type == "SMTP") {
        this.$v.formData.smtpHeader.$touch();
        // if its still pending or an error is returned do not submit
        if (
          this.$v.formData.smtpHeader.$pending ||
          this.$v.formData.smtpHeader.$error
        )
          return;
      }

      this.actSave(type);
    },
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      this.$message({
        message: "Please do not upload files larger than 1m in size.",
        type: "warning",
      });
      return false;
    },
    handleSuccess({ results, header }) {
      this.excelData.tableData = results;
      this.excelData.tableHeader = header;
      this.importSuccess = true;
    },
    saveExcelImport() {
      this.actSaveExcelImport();
      this.importSuccess = false;
    },
    cancel() {
      this.importSuccess = false;
    },
  },
};
</script>
