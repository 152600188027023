<template>
  <v-dialog
    class="my_fade"
    v-model="init.dialogOpen"
    @keydown.esc="cancel(false)"
    persistent
    max-width="40%"
  >
    <v-card flat :class="'pa-3 ' + 'my_' + init.dialogAction">
      <span class="title">
        {{ title }}
      </span>
    </v-card>

    <!-- 刪除畫面 -->
    <v-card
      v-show="init.dialogAction == 'delete'"
      flat
      class="px-4 py-3 my_bg-6"
    >
      <div class="mb-5">
        <p class="title text-xs-center">
          <u>{{ formData.mailAddress }}</u>
        </p>
        <blockquote class="body-2 py-3 text--grey lighten-4">
          {{ init.lbl.warning }}
        </blockquote>
      </div>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn color="my_btn-2" dark @click="cancel()">
          {{ Dict.cancel }}
          <v-icon dark right>mdi-cancel</v-icon>
        </v-btn>
        <v-btn color="my_btn-3" dark @click="save()">
          {{ Dict.confirm }}
          <v-icon dark right>mdi-check-circle</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- 新增|編輯畫面 -->
    <v-card
      v-show="['update', 'create'].includes(init.dialogAction)"
      class="my_bg-6"
    >
      <v-card-text>
        <ul style="padding-top: 20px">
          <p>Click DETAILS to see how to create domain list.</p>
        </ul>
      </v-card-text>
      <v-card-text>
        <v-layout row style="height: 70px">
          <v-flex sm6 pl-2>
            <v-text-field
              :prepend-icon="Icon.text"
              :label="init.lbl.mailAddress"
              v-model="formData.mailAddress"
              @input="$v.formData.mailAddress.$touch()"
              @blur="$v.formData.mailAddress.$touch()"
            ></v-text-field>
            <div class="input-error" v-if="!$v.formData.mailAddress.required">
              {{ init.lbl.mailAddress }} is required.
            </div>
            <div class="input-error" v-if="!$v.formData.mailAddress.minLength">
              {{ init.lbl.mailAddress }} must have at least
              {{ $v.formData.mailAddress.$params.minLength.min }} letters.
            </div>
            <div class="input-error" v-if="!$v.formData.mailAddress.notDomain">
              {{ init.lbl.mailAddress }} format is incorrect.
            </div>
          </v-flex>
          <v-flex sm2 pl-2>
            <v-switch
              :label="`${formData.isActive ? Dict.enable : Dict.disable}`"
              v-model="formData.isActive"
            ></v-switch>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions style="padding-top: 20px">
        <template>
          <div>
            <v-dialog v-model="dialog" width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" text dark v-bind="attrs" v-on="on">
                  Details
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <ul>
                    <p>Domain Settings Example</p>
                    <p style="color: grey; font-size: 12px">
                      All domain no need included @
                    </p>
                  </ul>
                </v-card-title>

                <v-card-text style="padding-top: 20px">
                  <img src="../../assets/regexDomain.png" alt="regexDomain" />
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog = false">
                    Back
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </template>
        <v-spacer></v-spacer>
        <v-btn color="my_btn-2" dark @click="cancel()">
          {{ Dict.cancel }}
          <v-icon dark right>mdi-cancel</v-icon>
        </v-btn>
        <v-btn color="my_btn-3" dark @click="save()">
          {{ Dict.confirm }}
          <v-icon dark right>mdi-check-circle</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.chip--small {
  height: 20px;
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import { notDomain } from "../../common/validator";
const { validationMixin, default: Vuelidate } = require("vuelidate");
const { required, minLength } = require("vuelidate/lib/validators");

export default {
  computed: {
    ...mapState("mailList", ["init", "formData"]),
    // eslint-disable-next-line vue/return-in-computed-property
    title(state) {
      var v = state.init.dialogAction;
      var title = state.init.lbl.title;
      if (v == "create") {
        return "Create " + title;
      } else if (v == "update") {
        return "Modify " + title;
      } else if (v == "delete") {
        return "Delete " + title;
      }
    },
  },
  data() {
    return {
      active: "actionCodes",
      dialog: false,
    };
  },
  mounted() {},
  validations: {
    formData: {
      mailAddress: {
        required,
        minLength: minLength(5),
        notDomain,
      },
    },
  },
  methods: {
    ...mapActions("mailList", ["actSave", "actCloseDialog"]),

    save() {
      if (this.init.dialogAction != "delete") {
        this.$v.formData.$touch();
        // if its still pending or an error is returned do not submit
        if (this.$v.formData.$pending || this.$v.formData.$error) return;
      }
      this.actSave();
    },
    cancel() {
      this.$v.formData.$reset();
      this.actCloseDialog();
    },
    del() {
      this.actSave();
    },
  },
};
</script>
