<template>
  <v-container>
    <v-row v-if="!loadUserCompleted">
      <v-col cols="4">
        <v-skeleton-loader
          class="mx-auto"
          type="card-avatar, list-item-three-line"
        ></v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader
          class="mx-auto"
          type="list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="loadUserCompleted">
      <v-col cols="4">
        <v-card class="mx-auto" tile>
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar size="64">
                <user-avatar :userid="this.userInfo.id" :imgsize="64" />
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ this.userInfo.name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{
                  this.userInfo.userName
                }}</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click="refreshSendLog()">
                <v-icon>mdi-refresh</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>mdi-power-plug</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-subtitle>{{
                  this.userInfo.lastConnectTime | localMomentFromNow
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-dialog v-model="changePasswordDialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on"
                  ><v-list-item-icon>
                    <v-icon>mdi-lock-reset</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title> Change Password </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-form
                ref="changepasswordform"
                v-model="changepasswordform"
                lazy-validation
              >
                <v-card>
                  <v-card-title class="text-h5 blue lighten-2">
                    Change Password
                  </v-card-title>

                  <v-card-text>
                    <v-text-field
                      v-model="userPassword"
                      :rules="[rules.required, rules.minLength]"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      label="Password"
                      required
                      clearable
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>

                    <v-text-field
                      v-model="confirmPassword"
                      :rules="[rules.required, rules.minLength, passwordMatch]"
                      :append-icon="
                        showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :type="showConfirmPassword ? 'text' : 'password'"
                      label="Confirm Password"
                      required
                      clearable
                      @click:append="showConfirmPassword = !showConfirmPassword"
                    ></v-text-field>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn color="primary" text @click="changeUserPassword">
                      Save
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="" text @click="resetForm"> Cancel </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-dialog>
          </v-list>
        </v-card>
      </v-col>

      <v-col>
        <v-skeleton-loader
          v-if="!this.loadSendLogCompleted"
          class="mx-auto"
          type="list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line,list-item-avatar-three-line"
        ></v-skeleton-loader>
        <v-card
          min-height="70vh"
          class="mx-auto"
          tile
          v-if="this.loadSendLogCompleted"
        >
          <v-card-title>Activities</v-card-title>
          <v-card-text>
            <v-timeline dense clipped>
              <span
                v-for="sendDate in Object.keys(this.groupedSendLog)"
                :key="sendDate"
              >
                <v-timeline-item hide-dot>
                  <strong>{{
                    sendDate | localMoment(AppConfig.dateTextFormat)
                  }}</strong>
                </v-timeline-item>

                <v-timeline-item
                  v-for="mailId in Object.keys(groupedSendLog[sendDate])"
                  :key="mailId"
                  small
                  :set="(mail = groupedSendLog[sendDate][mailId])"
                >
                  <div>
                    <v-row justify="space-between">
                      <v-col cols="7">
                        <div>
                        <strong v-if="mail[0].senderUserId == userInfo.id"
                          >You</strong
                        >
                        <strong v-else>{{ mail[0].sender }}</strong>

                    <span v-if="mail[0].isSend">
                      sent <v-icon>mdi-email-outline</v-icon> to </span>
                    
                    <span v-if="!mail[0].isSend">
                      attempted send <v-icon>mdi-email-outline</v-icon> to
                    </span></div>

                    <div v-for="log in mail" :key="log.id">
                      <a :href="'mailto:' + log.sendTo">{{ log.sendTo }}</a>
                    </div>
                      </v-col>
                      <v-col class="text-right" cols="5">
                        {{
                          mail[0].sendTime | localMoment(AppConfig.timeFormat)
                        }}
                      </v-col>
                    </v-row>

                  </div>
                </v-timeline-item>
              </span>
              <v-timeline-item class="mb-4" hide-dot>
                <v-btn class="mx-0"> Load More </v-btn>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const { validationMixin, default: Vuelidate } = require("vuelidate");
const { required, minLength, requiredIf } = require("vuelidate/lib/validators");
import { mapActions, mapState, mapGetters } from "vuex";
import { AppConfig } from "../../config";
import EG from "../../common/plugin";
import userAvatar from "../../components/UserAvatar.vue";
import { notDomain, notUri, validateIf } from "../../common/validator";
import mainOidc from "../../auth/index";
import moment from "moment";
import axiosAPI from "../../store/asiosAPI";

export default {
  components: {},
  computed: {
    ...mapState("mainCtr", ["progress"]),
  },
  filters: {
    localMoment: function (date, format) {
      return moment.utc(date).local().format(format);
    },
    localMomentFromNow: function (date) {
      return moment.utc(date).local().fromNow();
    },
  },
  watch: {
    loadUserCompleted: function (newVal, oldVal) {
      if (newVal) {
        this.getUserSendLog();
      } else {
        this.userSendLog = [];
      }
    },
  },
  mounted() {
    this.getMyUserInfo();
  },
  methods: {
    refreshSendLog(){
      this.userSendLog = [];
      this.loadSendLogCompleted=false;
      this.getUserSendLog();

    },
    groupBy(xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    },
    changeUserPassword() {
      if (this.$refs.changepasswordform.validate()) {
        axiosAPI.instance.show = true;
        axiosAPI.instance
          .post(`/user/${this.userInfo.id}/password`, {
            userId: this.userInfo.externalId,
            password: this.confirmPassword,
          })
          .then((response) => {
            if (response.data.success) {
              EG.setSnackBar("Password Update Completed", "success");
            }
          });
        this.changePasswordDialog = false;
      }
    },
    resetForm() {
      this.$refs.changepasswordform.reset();
      this.$refs.changepasswordform.resetValidation();
      this.changePasswordDialog = false;
    },
    passwordMatch(value) {
      return this.userPassword === value || "Passwords not match";
    },
    groupSendLogForShow(sendLog) {
      let dateLog = this.groupBy(sendLog, "sendDate");
      let resultLog = [];

      for (var key in dateLog) {
        let mailLog = this.groupBy(dateLog[key], "mailId");
        resultLog[key] = mailLog;
      }
      return resultLog;
    },
    getUserSendLog() {
      axiosAPI.instance.show = true;
      axiosAPI.instance
        .get(`/user/${this.userInfo.id}/sendlog/0/30`)
        .then((response) => {
          if (response.data.success) {
            this.userSendLog = this.userSendLog.concat(response.data.data);
            this.groupedSendLog = this.groupSendLogForShow(this.userSendLog);
            this.loadSendLogCompleted = true;
          }
        });
    },
    getMyUserInfo() {
      axiosAPI.instance.show = true;
      axiosAPI.instance
        .post("/user/me", {
          externalId: mainOidc.userProfile["sub"],
        })
        .then((response) => {
          if (response.data.success) {
            this.userInfo = response.data.data;
            this.loadUserCompleted = true;
          }
        });
    },
  },
  data: () => ({
    loadUserCompleted: false,
    loadSendLogCompleted: false,
    userInfo: null,
    changePasswordDialog: false,
    showPassword: false,
    showConfirmPassword: false,
    changepasswordform: false,
    userPassword: "",
    confirmPassword: "",
    userSendLog: [],
    groupedSendLog: [],
    rules: {
      required: (value) => !!value || "Required.",
      minLength: (value) => (value && value.length >= 8) || "Min 8 characters",
    },
  }),
};
</script>