<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app>
      <v-list dense>
        <v-layout>
          <v-flex class="mt-5 mb-3">
            <v-list-item link @click="home()">
              <v-list-item-action class="ma-5">
                <v-icon>mdi-home</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title style="font-size: 14px"
                  >Home</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </v-flex>
        </v-layout>
        <div style="margin: auto 5%"><v-divider></v-divider></div>

        <v-flex class="my-3">
          <v-list-item link @click="redirect('maillist')">
            <v-list-item-action class="ma-5">
              <v-icon>mdi-email-open</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Mail List</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-flex>
        <v-flex class="my-3">
          <v-list-item link @click="redirect('globalrule')">
            <v-list-item-action class="ma-5">
              <v-icon>mdi-pencil-ruler</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Global Rules</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-flex>
        <v-flex class="my-3">
          <v-list-item link @click="redirect('rules')">
            <v-list-item-action class="ma-5">
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Rules</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-flex>

        <v-flex class="my-3">
          <v-list-item link @click="redirect('statistics')">
            <v-list-item-action class="ma-5">
              <v-icon>mdi-chart-line</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Reports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-flex>

        <v-flex class="my-3">
          <v-list-item link @click="redirect('activitylog')">
            <v-list-item-action class="ma-5">
              <v-icon>mdi-post</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Activity Log</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-flex>
         
        <v-flex class="my-3">
          <div style="margin: auto 5%"><v-divider></v-divider></div>

          <v-list-item link @click="redirect('globalsetting')">
            <v-list-item-action class="ma-5">
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Global Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-flex>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-col cols="6">
          <v-toolbar-title
            ><v-list-item link @click="home();drawer = !drawer"
              ><v-img
                src="../public/EnkiLogoBanner.png"
                height="30"
              ></v-img></v-list-item
          ></v-toolbar-title>
        </v-col>
      </div>
      <v-spacer></v-spacer>
      <v-menu :offset-y="true">
        <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-badge
          dot
          color="red"
          overlap
          :value="(licenseExpired || licenseExceeded )"
          ><v-icon>mdi-bell</v-icon></v-badge
        >
      </v-btn>
       </template>
        <v-list dense style="max-width:300px;">
          <v-subheader><strong>Alerts</strong></v-subheader>
          <v-divider></v-divider>
           <v-list-item  three-line
          
          >
       
            <v-list-item-content>  <v-list-item-subtitle
                >No Alerts</v-list-item-subtitle
                >
              </v-list-item-content>
              </v-list-item>
          </v-list>
          </v-menu>
      <v-menu :offset-y="true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" @click="updateReleaseNotesReadState()">
            <v-badge
              :content="!releaseNotesRead && releaseNotes.length"
              :value="!releaseNotesRead && releaseNotes.length"
              color="red"
              overlap
            >
              <v-icon> mdi-alert-decagram-outline </v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-list dense style="max-width:300px;">
          <v-subheader><strong>Release Notes</strong></v-subheader>
          <v-divider></v-divider>
          <v-list-item  three-line
            v-for="rn in releaseNotes"
            :href="rn.link"
            :key="rn.id"
            target="_blank" link
          >
       
            <v-list-item-content>
              <v-list-item-title
                >
                <strong>{{ rn.title }}</strong></v-list-item-title
              >
              <v-list-item-subtitle
                ><span v-html="rn.description"></span
              ></v-list-item-subtitle>
                  <v-list-item-subtitle
                ><v-chip class="text-lowercase" small :color="rn.type=='feature'?'success': (rn.type=='update'?'primary':'warning')">
                  {{ rn.type }}
                </v-chip> posted {{ rn.postAt | localMomentFromNow }}</v-list-item-subtitle>
            </v-list-item-content><v-list-item-action>
              <v-icon>mdi-open-in-new</v-icon>
            </v-list-item-action>
          </v-list-item>
         
        </v-list>
      </v-menu>

      <v-btn icon href="https://docs.eventus.tools/docs/enki/" target="_blank">
        <v-icon class="mx-4" title="Docs"> mdi-help-circle-outline </v-icon>
      </v-btn>
      <v-menu :offset-y="true">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon class="mx-4" v-if="!myUserInfo"> mdi-account </v-icon>
            <user-avatar
              class="mx-4"
              :userid="myUserInfo.id"
              :imgsize="48"
              v-if="myUserInfo"
            />
          </v-btn> </template
        ><v-list dense>
          <v-list-item to="/users/me">
            <v-list-item-icon
              ><v-icon>mdi-badge-account-horizontal</v-icon></v-list-item-icon
            >
            <v-list-item-content>
              <v-list-item-title
                >{{
                  AppConfig.deploymentType == "Cloud"
                    ? user == null
                      ? ""
                      : user.name
                    : this.$store.getters.apiAuthUser
                }}
              </v-list-item-title></v-list-item-content
            ></v-list-item
          >
          <!-- if user's role is Admin then show Account option 
          <v-list-item to="/account" v-if="myUserInfo.role=='Admin'">
          can't work
          -->
          <v-list-item to="/account">

            <v-list-item-icon>
              <v-icon>mdi-account-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                >Account</v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-divider />
          <v-list-item
            link
            @click="logout()"
            v-if="AppConfig.deploymentType == 'Cloud'"
          >
            <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title></v-list-item-content
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-card v-if="progress" flat class="pa-0">
        <v-progress-linear
          color="warning"
          height="6"
          v-bind:indeterminate="true"
        ></v-progress-linear>
      </v-card>
      <v-card v-else flat class="black" height="2px"> </v-card>

      <v-container v-if="this.licenseExpired || this.licenseExceeded">
        <v-alert
          type="error"
          border="left"
          icon="mdi-alert-circle"
          v-if="this.licenseExceeded"
        >
          You exceeded purchased license count. Used/Purchased is
          {{ this.$store.getters.apiLicense.usedCount }} /
          {{ this.$store.getters.apiLicense.licenseCount }}
        </v-alert>
        <v-alert
          type="warning"
          border="left"
          icon="mdi-alert"
          v-if="this.licenseExpired"
        >
          Your subscription will expire in less than one month at

          {{
            new Date(this.$store.getters.apiLicense.validTo)
              | moment(AppConfig.dateTextFormat)
          }}
        </v-alert>
      </v-container>

      <v-container>
        <v-breadcrumbs :items="breadcrumbs" style="padding: 0">
          <template v-slot:item="{ item }">
            <router-link :to="item.to" v-if="!item.disabled">
              <v-breadcrumbs-item :class="[item.disabled && 'disabled']">
                {{ item.text }}
              </v-breadcrumbs-item>
            </router-link>
            <v-breadcrumbs-item :class="[item.disabled && 'disabled']" v-else>
              {{ item.text }}
            </v-breadcrumbs-item>
          </template></v-breadcrumbs
        >
      </v-container>

      <alert></alert>
      <snackBar></snackBar>
      <router-view></router-view>
    </v-main>
    <v-container>
      <v-footer app color="blue lighten-5" light padless inset
        ><v-card
          layout
          justify-center
          class="flex"
          flat
          tile
          color="#FFFFFF"
          elevation="10"
        ></v-card>
        <span>&copy; 2021 Eventus IP Pte Ltd</span>
        <v-spacer></v-spacer>
        <v-dialog v-model="apiDialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon small>mdi-api</v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title primary-title> API Settings </v-card-title>

            <v-card-text>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Portal Version</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ this.$store.getters.appVersion }}
                    -
                    {{ this.$store.getters.appMode }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>API Version</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ this.$store.getters.apiVersion }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line v-if="AppConfig.deploymentType != 'Cloud'">
                <v-list-item-content>
                  <v-list-item-title>API User</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ this.$store.getters.apiAuthUser }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Base Url</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ AppConfig.baseUrl }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Api Url</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ AppConfig.apiRoot }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="apiDialog = false">
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-footer>
    </v-container>
  </v-app>
</template>

<script>
import snackBar from "./components/snackBar";
import alert from "./components/alert.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import { AppConfig } from "./config";
import mainOidc from "./auth/index";
import axiosAPI from "./store/asiosAPI";
import moment from "moment";

export default {
  name: "App",
  components: {
    snackBar,
    alert,
  },
  props: {
    source: String,
  },
  data: () => ({
    drawer: false,
    apiDialog: false,
    show: false,
    releaseNotes: [],
  }),
   filters: {
    localMoment: function (date, format) {
      return moment.utc(date).local().format(format);
    },
    localMomentFromNow: function (date) {
      return moment.utc(date).local().fromNow();
    },
  },
  methods: {
    ...mapActions("users", ["actGetMyUserInfo","actSetReleaseNotesState"]),
    // Log the user in
    // login() {
    //   this.$auth.loginWithRedirect();
    // },
    // Log the user out
    logout() {
      mainOidc.signOut();
      window.location = window.location.origin;
    },
    home() {
      if (this.$route.path != "" && this.$route.path != "/")
        this.$router.push("/");
    },
    updateReleaseNotesReadState(){
      if(!this.releaseNotesRead)
      {
        this.actSetReleaseNotesState(true);
      }
    },
    redirect(url) {
      if (this.$route.path != url && this.$route.path != "/" + url)
        this.$router.push("/" + url);
    },
    getReleaseNotes() {
      axiosAPI.instance.show = true;
      axiosAPI.instance.get("/version/releasenotes").then((response) => {
        if (response.data.success) {
          this.releaseNotes = response.data.data;
        }
      });
    },
  },
  computed: {
    ...mapState("mainCtr", ["progress"]),
    ...mapState("users", ["myUserInfo","releaseNotesRead"]),
    user() {
      //console.log(mainOidc.userProfile);
      if (AppConfig.deploymentType != "Cloud") return "";
      else return mainOidc.isAuthenticated ? mainOidc.userProfile : "";
    },
    claims() {
      if (this.user) {
        return Object.keys(this.user).map((key) => ({
          key,
          value: this.user[key],
        }));
      }
      return [];
    },
    licenseExceeded() {
      if (this.$store.getters.apiLicense.licenseCount >= 0) {
        return (
          this.$store.getters.apiLicense.usedCount >
          this.$store.getters.apiLicense.licenseCount
        );
      } else {
        return false;
      }
    },
    licenseExpired() {
      if (this.$store.getters.apiLicense.validTo == "1999-01-01") {
        return false;
      } else {
        var firstDate = new Date(this.$store.getters.apiLicense.validTo);
        var secondDate = new Date(Date.now());
        secondDate.setMonth(secondDate.getMonth() + 1);
        return firstDate < secondDate;
      }
    },
    breadcrumbs: function () {
      let currentPath = this.$route.path;
      if (currentPath == "/") {
        currentPath = "";
      }
      let pathArray = currentPath.split("/");
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        if (this.$route.matched[idx]) {
          if (idx == 0) {
            breadcrumbArray.push({
              path: "",
              to: "/",
              text: this.$route.matched[idx].meta.breadcrumb || path,
            });
          } else {
            breadcrumbArray.push({
              path: path,
              to: breadcrumbArray[idx - 1]
                ? (breadcrumbArray[idx - 1].to == "/"
                    ? ""
                    : breadcrumbArray[idx - 1].to) +
                  "/" +
                  path
                : path,
              text: this.$route.matched[idx].meta.breadcrumb || path,
            });
          }
        }
        return breadcrumbArray;
      }, []);
      return breadcrumbs;
    },
  },
  mounted() {
    if (this.user && !this.myUserInfo) {
      this.actGetMyUserInfo();
    }
    this.getReleaseNotes();
  },
};
</script>
<style>
.bt {
  position: absolute;
  bottom: 0px;
}
.alertLicense {
  background: yellow;
  color: red;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  height: 50px;
  padding: 12px;
  position: fixed;
  width: 100%;
}
</style>
