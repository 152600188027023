import Vue from "vue";
import Vuex from "vuex";
import mainCtr from "../common/mainCtr";
import home from "./settings/home";
import mailList from "./rules/maillist";
import globalSetting from "./settings/globalsetting";
import statistics from "./statistics/statistics";
import account from "./settings/account";
import users from "./users";
import rules from "./rules/rules";
import axiosAPI from "./asiosAPI";
import mainOidc from "../auth/index";
import { AppConfig } from "../config";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || "0",
    appMode: process.env.NODE_ENV || "",
    apiVersion: "",
    apiCallError: false,
    apiAuthError: false,
    apiAuthUser: "",
    apiLicError: false,
    apiLicense: {
      licenseCount: -1,
      usedCount: 0,
      validFrom: "1999-01-01",
      validTo: "1999-01-01",
    },
  },
  mutations: {},
  actions: {},
  modules: {
    mainCtr,
    mailList,
    globalSetting,
    statistics,
    account,
    users,
    rules,
    home,
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
    appMode: (state) => {
      return state.appMode;
    },
    apiVersion: (state) => {
      if (state.apiVersion === "" || state.apiCallError) {
        axiosAPI.instance.show = false;
        axiosAPI.instance
          .get("/version")
          .then(function (response) {
            state.apiVersion = response.data;
            state.apiCallError = false;
          })
          .catch((e) => {
            state.apiCallError = true;
            state.apiVersion = `${e}`;
          });
      }
      return state.apiVersion;
    },
    apiAuthUser: (state) => {
      if (
        (state.apiAuthUser === "" || state.apiAuthError) &&
        (mainOidc.isAuthenticated || AppConfig.deploymentType != "Cloud")
      ) {
        axiosAPI.instance.show = false;
        axiosAPI.instance
          .get("/authUser")
          .then(function (response) {
            state.apiAuthUser = response.data;
            state.apiAuthError = false;
          })
          .catch((e) => {
            state.apiAuthError = true;
            state.apiAuthUser = `${e}`;
          });
      }
      return state.apiAuthUser;
    },
    apiLicense: (state) => {
      if (
        state.apiLicense.licenseCount === -1 &&
        ((mainOidc.isAuthenticated &&
          mainOidc.userProfile[AppConfig.accountIdKey] != null) ||
          AppConfig.deploymentType != "Cloud")
      ) {
        axiosAPI.instance.show = false;
        axiosAPI.instance
          .post("/license/summary")
          .then(function (response) {
            state.apiLicense = response.data.data;
            state.apiLicError = false;
          })
          .catch((e) => {
            state.apiLicError = true;
            state.apiLicense = `${e}`;
          });
      }
      return state.apiLicense;
    },
  },
});
