import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('div',[_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"md":"6"}},[_c(VAlert,{attrs:{"type":"error","justify":"center"}},[_vm._v("Your trial expired.")])],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"md":"6"}},[_vm._v("Click below to fix the issue. ")])],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"text-center",attrs:{"md":"6"}},[_c(VBtn,{on:{"click":_vm.actOpenSubscriptionPortal}},[_vm._v("Subscription Portal "),_c(VIcon,[_vm._v("mdi-open-in-new")])],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }