<template>
  <v-container>
    <v-skeleton-loader
      v-if="progress"
      class="mx-auto"
      type="list-item-three-line, divider,list-item-avatar,list-item-avatar, divider, list-item-avatar, divider, list-item-three-line,divider,list-item-three-line"
    ></v-skeleton-loader>
    <div v-if="!progress">
      <v-tabs v-model="accountTab">
        <v-tab key="tabAccount">Account</v-tab>
        <v-tab key="tabUsers">Users</v-tab>
        <v-tab key="tabM365" v-if="AppConfig.deploymentType == 'Cloud'"
          >Microsoft 365</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="accountTab">
        <v-tab-item key="tabAccount">
          <v-row>
            <v-col>
              <v-card>
                <v-card-title
                  ><span>{{ init.lbl.title }}</span>
                  <v-spacer></v-spacer>
                  <v-btn outlined @click="save()">
                    <v-icon>mdi-check-circle</v-icon>
                    <span>{{ Dict.save }}</span>
                  </v-btn>
                  <v-btn color="error" outlined @click="cancel()">
                    <v-icon>mdi-close</v-icon>
                    <span>{{ Dict.cancel }}</span>
                  </v-btn>
                </v-card-title>

                <v-divider></v-divider>

                <v-card-text>
                  <v-list subheader three-line>
                    <!-- <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ init.lbl.account }}</span>
                    <v-btn
                      small
                      outlined
                      @click="actIniForm(), actShowDialog({ action: 'create' })"
                    >
                      <v-icon small>mdi-plus</v-icon>
                      <span>{{ Dict.new }}</span>
                    </v-btn> 
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-select
                      v-model="formData.selectedId"
                      :items="formData.accountList"
                      item-text="company"
                      item-value="id"
                      :options="formData.accountList"
                      persistent-hint
                      readonly="readonly"
                    ></v-select>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item> -->
                    <v-subheader>Company Info</v-subheader>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <v-text-field
                            :label="init.lbl.company"
                            v-model="formData.account.company"
                            @input="$v.formData.account.company.$touch()"
                            @blur="$v.formData.account.company.$touch()"
                          >
                          </v-text-field>
                          <div
                            class="input-error"
                            v-if="!$v.formData.account.company.required"
                          >
                            {{ init.lbl.company }} is required.
                          </div>
                          <div
                            class="input-error"
                            v-if="!$v.formData.account.company.minLength"
                          >
                            {{ init.lbl.company }} must have at least
                            {{
                              $v.formData.account.company.$params.minLength.min
                            }}
                            letters.
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-subheader>Subscription and Billing</v-subheader>
                    <v-list-item
                      v-if="AppConfig.deploymentType == 'Cloud'"
                      v-on:click="actOpenSubscriptionPortal"
                    >
                      <v-list-item-action
                        ><v-icon>mdi-open-in-new</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title
                          >Subscription Portal</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          Open billing subscription portal to manage payment and
                          product selection.
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="AppConfig.deploymentType == 'Local'">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          init.lbl.license
                        }}</v-list-item-title>
                        <v-list-item-subtitle>
                          <v-text-field
                            :label="init.lbl.license"
                            v-model="formData.account.licenseKey"
                            @input="$v.formData.account.licenseKey.$touch()"
                            @blur="$v.formData.account.licenseKey.$touch()"
                          >
                          </v-text-field>
                          <div
                            class="input-error"
                            v-if="!$v.formData.account.licenseKey.required"
                          >
                            {{ init.lbl.license }} is required.
                          </div>
                          <div
                            class="input-error"
                            v-if="!$v.formData.account.licenseKey.minLength"
                          >
                            {{ init.lbl.license }} must have at least
                            {{
                              $v.formData.account.licenseKey.$params.minLength
                                .min
                            }}
                            letters.
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-subheader>{{ init.lbl.authType }}</v-subheader>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-subtitle>
                          <v-radio-group v-model="formData.account.authType">
                            <v-radio
                              :label="init.lbl.winAuth"
                              :value="0"
                              v-if="AppConfig.deploymentType == 'Local'"
                            ></v-radio>
                            <v-radio
                              :label="init.lbl.oAuth"
                              :value="1"
                            ></v-radio>
                          </v-radio-group>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <span v-if="AppConfig.deploymentType != 'Cloud'">
                      <v-list-item v-if="formData.account.authType == 1">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            init.lbl.authClientId
                          }}</v-list-item-title>
                          <v-list-item-subtitle>
                            <v-text-field
                              :label="init.lbl.authClientId"
                              v-model="formData.account.authClientId"
                              @input="$v.formData.account.authClientId.$touch()"
                              @blur="$v.formData.account.authClientId.$touch()"
                            >
                            </v-text-field>
                            <div
                              class="input-error"
                              v-if="
                                !$v.formData.account.authClientId.required &&
                                formData.account.authType == 1
                              "
                            >
                              {{ init.lbl.authClientId }} is required.
                            </div>
                            <div
                              class="input-error"
                              v-if="
                                !$v.formData.account.authClientId.minLength &&
                                formData.account.authType == 1
                              "
                            >
                              {{ init.lbl.authClientId }} must have at least
                              {{
                                $v.formData.account.authClientId.$params
                                  .minLength.$sub[0].min
                              }}
                              letters.
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item v-if="formData.account.authType == 1">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            init.lbl.authDomain
                          }}</v-list-item-title>
                          <v-list-item-subtitle>
                            <v-text-field
                              :label="init.lbl.authDomain"
                              v-model="formData.account.authDomain"
                              @input="$v.formData.account.authDomain.$touch()"
                              @blur="$v.formData.account.authDomain.$touch()"
                            >
                            </v-text-field>
                            <div
                              class="input-error"
                              v-if="
                                !$v.formData.account.authDomain.required &&
                                formData.account.authType == 1
                              "
                            >
                              {{ init.lbl.authDomain }} is required.
                            </div>
                            <div
                              class="input-error"
                              v-if="
                                !$v.formData.account.authDomain.minLength &&
                                formData.account.authType == 1
                              "
                            >
                              {{ init.lbl.authDomain }} must have at least
                              {{
                                $v.formData.account.authDomain.$params.minLength
                                  .$sub[0].min
                              }}
                              letters.
                            </div>
                            <div
                              class="input-error"
                              v-if="
                                !$v.formData.account.authDomain.notDomain &&
                                formData.account.authType == 1
                              "
                            >
                              {{ init.lbl.authDomain }} format is incorrect.
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item v-if="formData.account.authType == 1">
                        <v-list-item-content>
                          <v-list-item-title>{{
                            init.lbl.authAudience
                          }}</v-list-item-title>
                          <v-list-item-subtitle>
                            <v-text-field
                              :label="init.lbl.authAudience"
                              v-model="formData.account.authAudience"
                              @input="$v.formData.account.authAudience.$touch()"
                              @blur="$v.formData.account.authAudience.$touch()"
                            >
                            </v-text-field>
                            <div
                              class="input-error"
                              v-if="
                                !$v.formData.account.authAudience.required &&
                                formData.account.authType == 1
                              "
                            >
                              {{ init.lbl.authAudience }} is required.
                            </div>
                            <div
                              class="input-error"
                              v-if="
                                !$v.formData.account.authAudience.minLength &&
                                formData.account.authType == 1
                              "
                            >
                              {{ init.lbl.authAudience }} must have at least
                              {{
                                $v.formData.account.authAudience.$params
                                  .minLength.$sub[0].min
                              }}
                              letters.
                            </div>
                            <div
                              class="input-error"
                              v-if="
                                !$v.formData.account.authAudience.notDomain &&
                                formData.account.authType == 1
                              "
                            >
                              {{ init.lbl.authAudience }} format is incorrect.
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </span>
                    <span v-if="AppConfig.deploymentType == 'Cloud'">
                      <v-subheader>Synchronization</v-subheader>

                      <v-list-item>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="formData.account.enableAutoSyncO365Users"
                          ></v-checkbox>
                        </v-list-item-action>

                        <v-list-item-content>
                          <v-list-item-title
                            >Enable M365 Users Sync</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >Last Sync:
                            <span v-if="formData.account.lastO365UsersSyncTime">
                              {{
                                formData.account.lastO365UsersSyncTime
                                  | localMomentFromNow
                              }}</span
                            >
                            <span v-if="!formData.account.lastO365UsersSyncTime"
                              >Never</span
                            >
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </span>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item key="tabUsers">
          <v-card>
            <v-card-title
              ><span>{{ init.lbl.usersTitle }}</span>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-actions>
              <v-dialog v-model="addUserDialog" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined v-bind="attrs" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                    <span>{{ Dict.add }}</span>
                  </v-btn>
                </template>
                <v-form>
                  <v-card>
                    <v-card-title>
                      <span>Add User</span>
                      <v-spacer />
                      <v-btn icon @click="addUserDialog = false"
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              label="First name *"
                              required
                              v-model="newAccountUser.firstName"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              label="Last Name *"
                              required
                              v-model="newAccountUser.lastName"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              type="email"
                              label="Email *"
                              required
                              v-model="newAccountUser.email"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-select
                              :items="['Admin', 'User']"
                              label="Role *"
                              required
                              v-model="newAccountUser.role"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                      <small>*indicates required field</small>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="
                          addUserDialog = false;
                          addUser();
                        "
                      >
                        Add
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="
                          addUserDialog = false;
                          resetAddUser();
                        "
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>

              <v-dialog v-model="importUserDialog" persistent max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined v-bind="attrs" v-on="on">
                    <v-icon>mdi-cloud-download-outline</v-icon>
                    <span>M365 Import</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to import users from Microsoft
                    365?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="importUserDialog = false"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="importUserConfirm()"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-spacer />
              <v-btn color="success" outlined @click="refreshUsers()">
                <v-icon>mdi-refresh</v-icon>
                <span>{{ Dict.refresh }}</span>
              </v-btn>
            </v-card-actions>

            <v-divider></v-divider>

            <v-card-text>
              <v-text-field
                v-model="accountUsersSearchText"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <v-data-table
                :headers="usersTableHeaders"
                :items="accountUsers"
                :items-per-page="20"
                :search="accountUsersSearchText"
                :footer-props="{ itemsPerPageOptions: [10, 20, 30, -1] }"
              >
                <template v-slot:[`item.name`]="{ item }">
                  <v-list-item
                    :three-line="item.lastConnectTime ? true : false"
                    :two-line="item.lastConnectTime ? false : true"
                  >
                    <v-list-item-avatar size="48">
                      <user-avatar :userid="item.id" :imgsize="48"
                    /></v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row>
                          <v-col>{{ item.name }}</v-col>
                          <v-col v-if="item.isImported">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <img
                                  src="/images/azure-ad.svg"
                                  alt="Imported from Azure AD"
                                  height="24"
                                  width="24"
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </template>
                              <span>Imported from Azure AD</span>
                            </v-tooltip>
                          </v-col>
                        </v-row></v-list-item-title
                      >
                      <v-list-item-subtitle>{{
                        item.userName
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="item.lastLogonTime"
                        >Last Logon:
                        {{
                          item.lastLogonTime | localMomentFromNow
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:[`item.role`]="{ item }">
          <v-select
            :items="roles"
            :menu-props="{ top: true, offsetY: true }"
            v-model="item.role"
            @change="changeUserRole(item)"
          ></v-select>
        </template>
                <template v-slot:[`item.isActive`]="{ item }">
                  <v-checkbox
                    color="success"
                    v-model="item.isActive"
                    @change="changeUserStatus(item)"
                  >
                    ></v-checkbox
                  >
                </template>

                <template v-slot:[`item.lastConnectTime`]="{ item }">
                  <span v-if="item.lastConnectTime">{{
                    item.lastConnectTime.toLocaleString()
                      | localMoment(AppConfig.dateTimeFormat)
                  }}</span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon
                    @click="removeUser(item)"
                    color="error"
                    v-if="!item.isImported"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
            </v-card-text></v-card
          >
        </v-tab-item>
        <v-tab-item key="tabM365" v-if="AppConfig.deploymentType == 'Cloud'">
          <v-card>
            <v-card-title
              ><span>Microsoft 365</span>
              <v-spacer></v-spacer>
            </v-card-title>

            <v-divider></v-divider>
            <v-stepper v-model="m365step" vertical>
              <v-stepper-step
                :complete="!this.m365Status.serverPermission"
                step="1"
              >
                Grant Enki Servers
                <small
                  >Grant admin consent to Enki Servers for access to your
                  Microsoft 365 tenant</small
                >
              </v-stepper-step>

              <v-stepper-content step="1">
                <v-btn v-on:click="actOpenM365AdminConsent"
                  >Grant <v-icon>mdi-open-in-new</v-icon></v-btn
                >
              </v-stepper-content>

              <v-stepper-step :complete="!this.m365Status.clientAddIn" step="2">
                Grant Enki Check for Microsoft 365
                <small
                  >Grant admin consent to Enki Check for Office 365 for access
                  to your Microsoft 365 tenant</small
                >
              </v-stepper-step>

              <v-stepper-content step="2">
                <v-btn v-on:click="actOpenM365AdminConsentAddIn"
                  >Grant <v-icon>mdi-open-in-new</v-icon></v-btn
                >
              </v-stepper-content>
              <v-stepper-step step="3">
                Prepare Microsoft 365 Tenant for Enki
                <small
                  >Perform the following to get Microsoft 365 tenant ready for
                  Enki:
                  <ol>
                    <li>
                      Login as Global Administrator and grant Enki Exchange
                      Administrator role
                    </li>
                    <li>Enable External Recipient Mailtips</li>

                    <li>Enable On-Send Add-In Support</li>
                    <li>Import Domains to Enki</li>
                    <li>Enable External Sender Tagging</li>
                  </ol>
                </small>
              </v-stepper-step>

              <v-stepper-content step="3">
                <v-btn v-on:click="actOpenM365Prepare"
                  >Prepare <v-icon>mdi-open-in-new</v-icon></v-btn
                >
              </v-stepper-content>
            </v-stepper>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-dialog v-model="removeUserDialog" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Are you sure you want to delete this user
            <strong>{{ this.selectedAccountUser.name }}</strong
            >?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="
                removeUserDialog = false;
                this.$store.state.selectedAccountUserId = '';
              "
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="removeUserConfirm()"
              >OK</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
const { validationMixin, default: Vuelidate } = require("vuelidate");
const { required, minLength, requiredIf } = require("vuelidate/lib/validators");
import { mapActions, mapState, mapGetters } from "vuex";
import { AppConfig } from "../../config";
import { notDomain, notUri, validateIf } from "../../common/validator";
import mainOidc from "../../auth/index";
import moment from "moment";
import Vue from "vue";
import UserAvatar from "../../components/UserAvatar.vue";

export default {
  components: { UserAvatar },
  data: () => ({
    m365step: 1,
    addUserDialog: false,
    importUserDialog: false,
    removeUserDialog: false,
    accountTab: "tabAccount",
    accountUsersSearchText: "",
    selectedAccountUser: {},
    roles: ["Admin", "User"],
    usersTableHeaders: [
      { text: "User", value: "name" },
      { text: "Role", value: "role" },
      { text: "Actve", value: "isActive" },
      {
        text: "Last Connect Time",
        value: "lastConnectTime",
        filterable: false,
      },
      { text: "", value: "actions", sortable: false },
    ],
  }),
  validations: {
    formData: {
      account: {
        company: {
          required,
          minLength: minLength(3),
        },
        licenseKey: {
          required,
          minLength: minLength(5),
        },
        authAudience: {
          required: requiredIf(function () {
            return this.formData.account.authType == 1;
          }),
          minLength: validateIf("authType", minLength(5)),
          notDomain:
            validateIf("authType", notDomain) || validateIf("authType", notUri),
        },
        authDomain: {
          required: requiredIf(function () {
            return this.formData.account.authType == 1;
          }),
          notDomain: validateIf("authType", notDomain),
          minLength: validateIf("authType", minLength(5)),
        },
        authClientId: {
          required: requiredIf(function () {
            return this.formData.account.authType == 1;
          }),
          minLength: validateIf("authType", minLength(5)),
        },
      },
    },
  },
  filters: {
    localMoment: function (date, format) {
      return moment.utc(date).local().format(format);
    },
    localMomentFromNow: function (date) {
      return moment.utc(date).local().fromNow();
    },
  },
  mounted() {
    this.actIniForm();
    this.actSearch();
    if (AppConfig.deploymentType != "Cloud")
      this.formData.selectedId = AppConfig.accountId;
    else
      this.formData.selectedId = mainOidc.userProfile[AppConfig.accountIdKey];
    this.actGet();
    this.refreshUsers();
    this.resetAddUser();
    this.actGetM365Status();
  },
  watch: {
    addUserDialog: function (newVal, oldVal) {
      if (newVal) {
        this.resetAddUser();
      }
    },
    m365Status: function (newVal, oldVal) {
      if (newVal.serverPermission) {
        this.m365step = 2;
      }
      if (newVal.clientAddIn && newVal.serverPermission) {
        this.m365step = 3;
      }
    },
    // "formData.selectedId": function () {
    //   this.actGet();
    // },
  },
  computed: {
    ...mapState("account", [
      "formSearch",
      "init",
      "formData",
      "accountUsers",
      "newAccountUser",
      "m365Status",
    ]),
    ...mapState("mainCtr", ["progress"]),
  },
  methods: {
    ...mapActions("account", [
      "actIniForm",
      "actIniSearch",
      "actShowDialog",
      "actCloseDialog",
      "actSearch",
      "actGet",
      "actCancel",
      "actSave",
      "actOpenSubscriptionPortal",
      "actOpenM365AdminConsent",
      "actOpenM365AdminConsentAddIn",
      "actOpenM365Prepare",
      "actImportM365Users",
      "actGetUsers",
      "actAddUser",
      "actRemoveUser",
      "actEnableUser",
      "actDisableUser",
      "actGetM365Status",
      "actUserRole"
    ]),
    refreshUsers() {
      this.actGetUsers();
    },
    save() {
      this.$v.formData.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.formData.account.$pending || this.$v.formData.$error) return;
      this.actSave();
    },
    addUser() {
      this.actAddUser();
    },
    importUserConfirm() {
      this.importUserDialog = false;

      this.actImportM365Users();
    },
    removeUserConfirm() {
      this.removeUserDialog = false;

      this.actRemoveUser(this.selectedAccountUser.id);
    },
    changeUserStatus(item) {
      if (!item.isActive) {
        this.actDisableUser(item.id);
      } else {
        this.actEnableUser(item.id);
      }
    },
    changeUserRole(item) {
     
      this.actUserRole(item)
    },
    removeUser(item) {
      this.selectedAccountUser = item;
      this.removeUserDialog = true;
    },
    resetAddUser() {
      this.newAccountUser.firstName = "";
      this.newAccountUser.lastName = "";
      this.newAccountUser.email = "";
      this.newAccountUser.role = "";
    },
    cancel() {
      this.$v.formData.$reset();
      this.actCancel();
    },
    getValueOnly(val) {
      return Vue.util.extend({}, val);
    },
  },
};
</script>
