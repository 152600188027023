let baseUrl = "";
let apiRoot = "";
let accountIdKey = "";
let authType = "";
let accountId = "";
let dateFormat = "";
let dateTextFormat = "";
let dateTimeFormat = "";
let timeFormat="";
let deploymentType = "Cloud";
let eulaUrl = "";
let appInsightsId = "";

baseUrl = process.env.VUE_APP_ENKI_BASE_URL;
apiRoot = process.env.VUE_APP_ENKI_API_ROOT;
accountIdKey = "https://eventusenki.com/accountId";
dateTimeFormat = "YYYY/MM/DD HH:mm:ss";
timeFormat = "HH:mm:ss";
dateFormat = "YYYY/MM/DD";
dateTextFormat = "DD MMM YYYY";
deploymentType = process.env.VUE_APP_DeploymentType;
eulaUrl = "https://download.eventusenki.com/enki-server/Enki_Eula.pdf";

authType = process.env.VUE_APP_Auth_type;
if (deploymentType == "Local") accountId = process.env.VUE_APP_ACCOUNT_ID;
appInsightsId = process.env.VUE_APP_INSIGHTS_ID;

export const AppConfig = {
  baseUrl: baseUrl,
  apiRoot: apiRoot,
  accountIdKey: accountIdKey,
  authType: authType,
  accountId: accountId,
  dateFormat: dateFormat,
  dateTextFormat: dateTextFormat,
  dateTimeFormat: dateTimeFormat,
  timeFormat: timeFormat,
  deploymentType: deploymentType,
  eulaUrl: eulaUrl,
  mapboxKey: process.env.VUE_APP_MAPBOX_KEY,
  AzureADClientId: process.env.VUE_APP_AzureAD_ClientID,
  O365AddInClientId: process.env.VUE_APP_O365AddIn_ClientID,
  appInsightsId: appInsightsId,
};
