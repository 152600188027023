<template>
  <v-container fluid>
    <div>
      <v-row justify="center">
        <v-col md="6">
          <v-alert type="error" justify="center"
            >You do not have access to this site.</v-alert
          >
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col md="6" class="text-center"
          >Contact your administrator to grant access.
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { AppConfig } from "../config.js";

export default {
  data: () => ({}),
  methods: {},
};
</script>
