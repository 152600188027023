import axiosAPI from "../asiosAPI";
// eslint-disable-next-line no-unused-vars
import EG from "../../common/plugin";
import moment from "moment";
import { AppConfig } from "../../config";
import router from "../../router";
import store from "../../store/index";
import mainOidc from "../../auth/index";
import colors from "vuetify/lib/util/colors";

var state = {
  name: "rules",
  currHead: "Rule Management",
  init: {
    lbl: {
      title: "Rule Lists ",
      isActive: "Active",
      beginDate: "From:",
      endDate: "To:",
      ruleName: "Rule Name",
      description: "Description",
      ruleAction: "Actions",
      mail: "Mail Lists",
      sender: "Sender Lists",
      group: "Group Lists",
      allow: "Allow",
      block: "Block",
      include: "Include",
      exclude: "Exclude",
    },
    statusSelect: {
      text: "",
      value: "",
      toolTips: "",
    },
    dialogAction: "",
    dialogType: "",
    dialogOpen: false,
    infoAccountId: "00000000-0000-0000-0000-000000000000",
    formData: {
      id: "00000000-0000-0000-0000-000000000000",
      accountId: "00000000-0000-0000-0000-000000000000",
      ruleName: "",
      description: "",
      ruleAction: 1,
      isActive: true,
      mails: [],
      groupsInclude: [],
      groupsExclude: [],
      senders: [],
    },
    formSearch: {
      ruleName: "",
      isActive: "All",
    },
    formDataGlobal: {
      id: "00000000-0000-0000-0000-000000000000",
      accountId: "00000000-0000-0000-0000-000000000000",
      ruleName: "",
      description: "",
      ruleAction: 0,
      isActive: true,
      allowMails: [],
      blockMails: [],
    },
  },
  formData: {
    id: "00000000-0000-0000-0000-000000000000",
    accountId: "00000000-0000-0000-0000-000000000000",
    ruleName: "",
    description: "",
    ruleAction: 0,
    isActive: true,
    mails: [],
    groupsInclude: [],
    groupsExclude: [],
    senders: [],
  },
  formSearch: {
    ruleName: "",
    isActive: "All",
    status: null,
  },
  gridRulesList: {
    data: [],
    loading: false,
    headers: [
      { text: "", value: "actions" },
      { text: "Rule Name", value: "ruleName" },
      { text: "Action", value: "ruleAction" },
      { text: "Active", value: "isActive" },
      { text: "Updated Date", value: "updateDate" },
    ],
    totalCount: 0,
  },
  mailLists: [],
  ruleAction: 0,
  selectedMail: [],
  formDataGlobal: {
    id: "00000000-0000-0000-0000-000000000000",
    accountId: "00000000-0000-0000-0000-000000000000",
    ruleName: "",
    description: "",
    ruleAction: 0,
    isActive: true,
    allowMails: [],
    blockMails: [],
  },
  allowMails: [],
  blockMails: [],
  groupLists_include: [],
  groupLists_exclude: [],
  selectedGroup_Include: [],
  groupHeaders: [
    // { text: "Object SID", value: "sid" },
    { text: "Group Name", value: "name" },
    { text: "Connection", value: "connection" },
  ],
};

var getters = {
  userAccountId: function (state) {
    if (AppConfig.deploymentType != "Cloud") {
      return AppConfig.accountId;
    } else {
      if (mainOidc.userProfile != undefined)
        return mainOidc.userProfile[AppConfig.accountIdKey];
      else return "00000000-0000-0000-0000-000000000000";
    }
  },
};

var actions = {
  actInitAllData({ commit }) {
    commit("mutIniSearch");
  },
  actReadRulesLists(isManual) {
    if (isManual) state.gridRulesList.page.page = 1;

    axiosAPI.instance.show = true;
    axiosAPI.instance
      .post("/rules/list", state.formSearch)
      .then(function (response) {
        state.gridRulesList.data = response.data.data;
        state.gridRulesList.totalCount = response.data.TotalCount;
      });
      // .catch((e) => {
      //   throw new Error(`Problem handling something: ${e}.`);
      // });
  },
  actSearch({ commit }, isManual) {
    if (isManual) {
      commit("mutIniPage");
    }
    var _formSearch = JSON.parse(JSON.stringify(state.formSearch));
    if (state.formSearch.isActive != "All")
      _formSearch.isActive = state.formSearch.isActive.split(",");
    else _formSearch.isActive = [];

    axiosAPI.instance.show = isManual;
    axiosAPI.instance
      .post("/rules/list", _formSearch)
      .then((response) => {
        if (response.data.success) commit("mutFillGrid", response.data);
      })
      // .catch((e) => {
      //   throw new Error(`Problem handling something: ${e}.`);
      // });
  },
  actSearchMail({ commit }, isDomain) {
    var url = "getMaillists";
    if (isDomain == 1) url = "getDomainlists";
    axiosAPI.instance.show = true;
    axiosAPI.instance.post("/rules/" + url).then((response) => {
      commit("mutMailLists", response.data);
    });
  },
  actGetInfo({ commit }) {
    if (
      (state.formData.id == undefined ||
        state.formData.id == "00000000-0000-0000-0000-000000000000") &&
      state.init.dialogAction != "create"
    ) {
      // EG.setAlert("Rule Info Id is invalid.");
      state.init.dialogAction = "create";
      EG.PROGRESS(true);
      actions.actSearchMail({ commit }, 1);
      EG.PROGRESS(true);
      actions.actGetGroupLists({ commit });
      commit("mutIniForm");
      EG.PROGRESS(false);
    }
    if (state.init.dialogAction === "create") {
      EG.PROGRESS(true);
      actions.actSearchMail({ commit }, 1);
      EG.PROGRESS(true);
      actions.actGetGroupLists({ commit });
      commit("mutIniForm");
      EG.PROGRESS(false);
    } else {
      axiosAPI.instance.show = true;
      axiosAPI.instance.post("/rules/Get", state.formData).then((response) => {
        EG.PROGRESS(true);
        actions.actSearchMail({ commit }, 1);
        EG.PROGRESS(true);
        actions.actGetGroupLists({ commit });
        commit("mutFillForm", response.data);
        EG.PROGRESS(false);
      });
    }
  },
  actGetGlobalInfo({ commit }) {
    axiosAPI.instance.show = false;
    state.formData.accountId = getters.userAccountId();
    axiosAPI.instance
      .post("/rules/getglobal", state.formData)
      .then((response) => {
        actions.actSearchMail({ commit }, 1);
        commit("mutFillFormGlobal", response.data);
      });
  },
  actSave({ commit }, data) {
    var _formData = JSON.parse(JSON.stringify(state.formData));
    _formData.accountId = getters.userAccountId();
    var url = "/rules/";
    if (state.init.dialogAction === "create") {
      state.formData.id = "00000000-0000-0000-0000-000000000000";
      _formData.mails = data.mail;
      _formData.groupsInclude = data.includeGroup;
      _formData.groupsExclude = data.excludeGroup;
      url += "create";
    } else if (state.init.dialogAction === "update") {
      _formData.mails = data.mail;
      _formData.groupsInclude = data.includeGroup;
      _formData.groupsExclude = data.excludeGroup;

      url += "update";
    } else if (state.init.dialogAction === "delete") {
      url += "delete";
    }
    axiosAPI.instance.show = true;
    axiosAPI.instance.post(url, _formData).then((response) => {
      if (response.data.success) {
        if (state.init.dialogAction === "delete") {
          commit("mutFillGrid", response.data);
        } else {
          if (!data.isGlobal) {
            router.push("/rules");
          }
        }
        actions.actCloseDialog({ commit });
      }
    });
  },
  actSaveGlobal({ commit }, data) {
    var _formData = state.formDataGlobal;
    _formData.accountId = getters.userAccountId();

    state.init.dialogAction = "update";
    _formData.allowMails = data.allowMail;
    _formData.blockMails = data.blockMail;
    var url = "/rules/updateGlobal";

    axiosAPI.instance.show = true;
    axiosAPI.instance.post(url, _formData).then((response) => {
      if (response.data.success) {
        actions.actCloseDialog({ commit });
      }
    });
  },
  actIniForm({ commit }) {
    commit("mutIniForm");
  },
  actIniFormGlobal({ commit }) {
    commit("mutIniFormGlobal");
  },
  actIniSearch({ commit }) {
    commit("mutIniSearch");
  },
  actSetRow({ commit }, RowData) {
    commit("mutSetRow", RowData);
  },
  actShowDialog({ commit }, payLoad) {
    commit("mutDialogSet", payLoad);
    commit("mutDialogCtrl", true);
  },
  actCloseDialog({ commit }) {
    commit("mutDialogCtrl", false);
  },
  actCancel() {
    router.push("/rules");
  },
  actGlobalCancel({ commit }) {
    commit("mutIniFormGlobal");
  },
  actGetGroupLists({ commit }) {
    axiosAPI.instance.show = true;
    state.formData.accountId = getters.userAccountId();
    axiosAPI.instance.post("/group", state.formData).then((response) => {
      commit("mutGroupList", response.data);
    });
  },
  actSearchMailGlobal({ commit }) {
    var url = "getMaillists";
   url = "getDomainlists";
    axiosAPI.instance.show = false;
    axiosAPI.instance.post("/rules/" + url).then((response) => {
      commit("mutMailLists", response.data);
    });
  },
};

var mutations = {
  mutFillGrid(state, res) {
    if (res.data != null && res.data.length > 0) {
      state.gridRulesList.data = res.data;
      state.gridRulesList.totalCount = res.TotalCount;
    } else {
      state.gridRulesList.data = [];
    }
  },
  mutSetRow(state, RowData) {
    state.infoAccountId = RowData.id;
    state.formData.id = RowData.id;
    state.formData.ruleName = RowData.ruleName;
    state.formData.description = RowData.description;
  },
  mutDialogSet(state, payLoad) {
    state.init.dialogAction = payLoad.action;
    state.init.dialogType = payLoad.type;
  },
  mutDialogCtrl(state, Status) {
    state.init.dialogOpen = Status;
  },
  mutFillForm(state, res) {
    state.formData = JSON.parse(JSON.stringify(res.data));
    state.ruleAction = JSON.parse(JSON.stringify(state.formData.ruleAction));
    state.selectedMail = JSON.parse(JSON.stringify(state.formData.mails));
  },
  mutFillFormGlobal(state, res) {
    if (res.data != null) {
      state.formDataGlobal = JSON.parse(JSON.stringify(res.data));
      state.allowMailGlobal = JSON.parse(
        JSON.stringify(state.formDataGlobal.allowMails)
      );
      state.blockMailGlobal = JSON.parse(
        JSON.stringify(state.formDataGlobal.blockMails)
      );
      state.init.formDataGlobal = JSON.parse(
        JSON.stringify(state.formDataGlobal)
      );
    } else {
      state.formDataGlobal = JSON.parse(
        JSON.stringify(state.init.formDataGlobal)
      );
    }
  },
  mutInitFormGlobal(state, res) {
    if (res.data != null) {
      state.formDataGlobal = JSON.parse(JSON.stringify(res.data));
      state.allowMailGlobal = JSON.parse(
        JSON.stringify(state.formDataGlobal.allowMails)
      );
      state.blockMailGlobal = JSON.parse(
        JSON.stringify(state.formDataGlobal.blockMails)
      );
    } else {
      state.formData = JSON.parse(JSON.stringify(state.init.formDataGlobal));
    }
  },
  mutIniForm(state) {
    state.formData = JSON.parse(JSON.stringify(state.init.formData));
    state.infoAccountId = "00000000-0000-0000-0000-000000000000";
    state.selectedMail = [];
  },
  mutIniFormGlobal(state) {
    state.formDataGlobal = JSON.parse(
      JSON.stringify(state.init.formDataGlobal)
    );
  },
  mutIniSearch(state) {
    state.formSearch = JSON.parse(JSON.stringify(state.init.formSearch));
  },
  mutIniPage(state) {},
  mutMailLists(state, res) {
    if (res.data != null && res.data.length > 0) {
      state.mailLists = res.data;
    } else {
      state.mailLists = [];
    }

    if (state.formData.ruleAction != state.ruleAction)
      state.formData.mails = [];
    else state.formData.mails = JSON.parse(JSON.stringify(state.selectedMail));
  },
  mutGroupList(state, res) {
    if (res != null && res.length > 0) {
      state.groupLists_include = res;
      state.groupLists_exclude = res;
    } else {
      state.groupLists_include = [];
      state.groupLists_exclude = [];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
