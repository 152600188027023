import Vue from "vue";
import { createOidcAuth, SignInType, LogLevel } from "vue-oidc-client";
import { AppConfig } from "../config.js";

const loco = window.location;
const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`;
import authConfig from "../../auth_config.json";

var redirectUrl = new URL("/callback", appRootUrl);
var mainOidc = createOidcAuth(
  "main",
  SignInType.Window,
  appRootUrl,
  {
    authority: `https://${authConfig.appAuth.domain}/`,
    client_id: `${authConfig.appAuth.clientId}`,
    response_type: "code",
    scope: `${authConfig.appAuth.scope}`,
    redirect_uri: redirectUrl.toString(),
    extraQueryParams: {
      audience: authConfig.appAuth.audience,
    },
    prompt: "login",
  },
  console,
  LogLevel.Debug
);

Vue.prototype.$oidc = mainOidc;
export default mainOidc;
