<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title
            ><span>Reports</span>
          </v-card-title>
          <v-divider> </v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <v-skeleton-loader
                  v-if="progress"
                  class="mx-auto"
                  type="list-item-three-line"
                ></v-skeleton-loader>

                <v-card v-show="!progress">
                  <v-card-title>Search</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="6" class="ml-md-auto">
                        <v-menu
                          ref="menu1"
                          v-model="menu1"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :label="init.lbl.beginDate"
                              v-model="formSearch.beginDate"
                              prepend-icon="mdi-calendar-blank-outline"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formSearch.beginDate"
                            @input="menu1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6" class="ml-md-auto">
                        <v-menu
                          ref="menu2"
                          v-model="menu2"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :label="init.lbl.endDate"
                              v-model="formSearch.endDate"
                              prepend-icon="mdi-calendar-blank-outline"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formSearch.endDate"
                            @input="menu2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          :items="gridSendLogs.data"
                          label="Select Sender"
                          item-text="senderMail"
                          item-value="senderMail"
                          @change="getSelectedSender"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <!-- 查詢 -->
                    <v-btn outlined text @click="actSenderSearch(sendersearch)">
                      <v-icon small>mdi-magnify</v-icon
                      ><span>{{ Dict.search }}</span>
                    </v-btn>

                    <!-- 清除 -->
                    <v-btn outlined text @click="actIniSearch(formData)">
                      <v-icon small>mdi-delete-outline</v-icon>
                      <span>{{ Dict.clear }}</span>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-skeleton-loader
                  v-if="progress"
                  class="mx-auto"
                  type="table"
                ></v-skeleton-loader>

                <v-data-table
                  v-show="!progress"
                  class="elevation-1 my_table_sm"
                  :headers="gridSendLogs.headers"
                  :items="gridSendLogs.data"
                  :server-items-length="gridSendLogs.totalCount"
                  :hide-default-footer="gridSendLogs.data.length == 0"
                  :options.sync="gridSendLogs.page"
                  :items-per-page-text="Dict.grid.rowsPerPageText"
                  :footer-props="footerProps"
                  :items-per-page="20"
                  :no-data-text="Dict.grid.noDataText"
                  :no-results-text="Dict.grid.noResultsText"
                  group-by="senderMail"
                  hide-default-header
                >
                  <template v-slot:group="{ group, items, headers }">
                    <v-expansion-panels multiple accordion>
                      <v-expansion-panel>
                        <v-expansion-panel-header>
                          <span class="font-weight-bold">
                            Sender:
                            {{ group }}
                          </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-data-table
                            :headers="headers"
                            :items="items"
                            hide-default-footer
                            :items-per-page="-1"
                            group-by="sendType"
                            :sort-by="['sendDate', 'sendTo']"
                            :sort-desc="[true, false]"
                          >
                            <template
                              v-slot:[`group.header`]="{
                                group,
                                items,
                                isOpen,
                                toggle,
                              }"
                            >
                              <!-- 0: Trusted 1: NonTrusted 2: BlockList 3: Internal -->
                              <td v-if="group == 0" colspan="4">
                                <v-btn icon small @click="toggle">
                                  <v-icon small v-if="isOpen">
                                    mdi-minus
                                  </v-icon>
                                  <v-icon small v-else> mdi-plus </v-icon>
                                </v-btn>
                                Trusted ({{ items.length }})
                              </td>
                              <td v-if="group == 1" colspan="4">
                                <v-btn icon small @click="toggle">
                                  <v-icon small v-if="isOpen">
                                    mdi-minus
                                  </v-icon>
                                  <v-icon small v-else> mdi-plus </v-icon>
                                </v-btn>
                                NonTrusted ({{ items.length }})
                              </td>
                              <td v-if="group == 3" colspan="4">
                                <v-btn icon small @click="toggle">
                                  <v-icon small v-if="isOpen">
                                    mdi-minus
                                  </v-icon>
                                  <v-icon small v-else> mdi-plus </v-icon>
                                </v-btn>
                                Internal ({{ items.length }})
                              </td>
                              <td v-if="group == 2" colspan="4">
                                <v-btn icon small @click="toggle">
                                  <v-icon small v-if="isOpen">
                                    mdi-minus
                                  </v-icon>
                                  <v-icon small v-else> mdi-plus </v-icon>
                                </v-btn>
                                Blocked ({{ items.length }})
                              </td>
                            </template>
                            <template v-slot:[`item.isSend`]="{ item }">
                              <v-icon
                                small
                                v-if="item.isSend"
                                color="green darken-2"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                small
                                v-if="!item.isSend"
                                color="red darken-2"
                                >mdi-close</v-icon
                              >
                            </template>
                            <template v-slot:[`item.sendDate`]="{ item }">
                              <span>{{
                                item.sendDate.toLocaleString()
                                  | localMoment(AppConfig.dateTimeFormat)
                              }}</span>
                            </template>
                          </v-data-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
export default {
  components: {},
  data: () => ({
    menu1: null,
    menu2: null,
    openedPanel: 0,
    footerProps: { "items-per-page-options": [20, 30, 50, 100, -1] },
    sendersearch: {
      isManual: true,
      sender: "sender",
    },
  }),
  mounted() {
    this.actIniForm();
    this.actSearch();
  },
  computed: {
    ...mapState("statistics", [
      "formSearch",
      "init",
      "formData",
      "gridSendLogs",
    ]),
    ...mapState("mainCtr", ["progress"]),
  },
  filters: {
    localMoment: function (date, format) {
      return moment.utc(date).local().format(format);
    },
  },
  methods: {
    ...mapActions("statistics", [
      "actIniForm",
      "actIniSearch",
      "actSetRow",
      "actShowDialog",
      "actCloseDialog",
      "actSearch",
      "actSenderSearch",
    ]),
    getSelectedSender(sender) {
      this.sendersearch.sender = sender;
    },
  },
};
</script>
