import axiosAPI from "../asiosAPI";
// eslint-disable-next-line no-unused-vars
import EG from "../../common/plugin";
import moment from "moment";
import mainOidc from "../../auth/index.js";
import { AppConfig } from "../../config";
import store from "../../store/index";

var state = {
  name: "mailList",
  currHead: "Mail Lists Management",
  init: {
    lbl: {
      title: "Mail Lists ",
      mailAddress: "Mail / Domain",
      isActive: "Active",
    },
    statusSelect: {
      text: "",
      value: "",
      toolTips: "",
    },
    dialogAction: "",
    dialogOpen: false,
    formData: {
      id: 0,
      mailAddress: "",
      isActive: [],
      updateDate: "",
    },
    formSearch: {
      mail: "",
      isActive: "All",
    },
  },
  formData: {
    id: 0,
    accountId: "00000000-0000-0000-0000-000000000000",
    mailAddress: "",
    isActive: true,
    updateDate: "",
  },
  formSearch: {
    mail: "",
    isActive: "All",
    status: null,
  },
  excelData: {
    tableData: [],
    tableHeader: [],
  },

  gridMailList: {
    data: [],
    loading: false,
    headers: [
      { text: "", value: "actions" },
      { text: "Mail/Domain", value: "mailAddress" },
      { text: "Active", value: "isActive" },
      { text: "Updated Date", value: "updateDate" },
    ],
    totalCount: 0,
    page: {
      descending: true,
      page: 1,
      rowsPerPage: 20,
    },
  },
};

var getters = {
  userAccountId: function (state) {
    if (AppConfig.deploymentType != "Cloud") {
      return AppConfig.accountId;
    } else {
      if (mainOidc.userProfile != undefined)
        return mainOidc.userProfile[AppConfig.accountIdKey];
      else return "00000000-0000-0000-0000-000000000000";
    }
  },
};

var actions = {
  actInitAllData({ commit }) {
    commit("mutIniSearch");
  },
  actReadMailLists(isManual) {
    if (isManual) state.gridMailList.page.page = 1;

    axiosAPI.instance.show = true;
    axiosAPI.instance
      .post("/maillist/list", state.formSearch)
      .then(function (response) {
        state.gridMailList.data = response.data.data;
        state.gridMailList.totalCount = response.data.TotalCount;
      });
    // .catch((e) => {
    //   throw new Error(`Problem handling something: ${e}.`);
    // });
  },
  actSearch({ commit }, isManual) {
    if (isManual) {
      commit("mutIniPage");
    }

    var _formSearch = JSON.parse(JSON.stringify(state.formSearch));
    if (state.formSearch.isActive != "All")
      _formSearch.isActive = state.formSearch.isActive.split(",");
    else _formSearch.isActive = [];

    axiosAPI.instance.show = isManual;
    axiosAPI.instance.post("/maillist/list", _formSearch).then((response) => {
      if (response.data.success) commit("mutFillGrid", response.data);
    });
    // .catch((e) => {
    //   throw new Error(`Problem handling something: ${e}.`);
    // });
  },
  actSave({ commit }) {
    var _formData = state.formData;

    if (state.init.dialogAction === "create")
      _formData.accountId = getters.userAccountId();

    axiosAPI.instance.show = true;
    axiosAPI.instance
      .post("/maillist/" + state.init.dialogAction, _formData)
      .then((response) => {
        if (response.data.success) {
          actions.actCloseDialog({ commit });
          // commit("mutIniSearch");
          actions.actSearch({ commit });
        }
        commit("mutIniForm");
      });
  },
  actSaveExcelImport({ commit }) {
    var _formData = state.formData;
    _formData.accountId = getters.userAccountId();
    
    var importMailLists = [];
    state.excelData.tableData.map((td) => {
      importMailLists.push ({
                id: _formData.id,
                accountId: _formData.accountId,
                mailAddress: td.Domains,
                isActive: _formData.isActive,
                updateDate: _formData.updateDate,
              })
    });
    
    axiosAPI.instance.show = true;
    axiosAPI.instance
      .post("/maillist/import" , importMailLists)
      .then((response) => {
        if (response.data.success) {
          actions.actCloseDialog({ commit });
          // commit("mutIniSearch");
          actions.actSearch({ commit });
        }
        commit("mutIniForm");
      });
  },
  actIniForm({ commit }) {
    commit("mutIniForm");
  },
  actIniSearch({ commit }) {
    commit("mutIniSearch");
  },
  actSetRow({ commit }, RowData) {
    commit("mutSetRow", RowData);
  },
  actShowDialog({ commit }, payLoad) {
    commit("mutDialogSet", payLoad);
    commit("mutDialogCtrl", true);
  },
  actCloseDialog({ commit }) {
    commit("mutDialogCtrl", false);
  },
};

var mutations = {
  mutFillGrid(state, res) {
    if (res.data != null && res.data.length > 0) {
      state.gridMailList.data = res.data;
      state.gridMailList.totalCount = res.TotalCount;
    } else {
      state.gridMailList.data = [];
    }
  },
  mutSetRow(state, RowData) {
    state.formData.mailAddress = RowData.mailAddress;
    state.formData.isActive = RowData.isActive;
    state.formData.id = RowData.id;
    state.formData.updateDate = RowData.updateDate;
    state.formData.accountId = RowData.accountId;
  },
  mutDialogSet(state, payLoad) {
    state.init.dialogAction = payLoad.action;
  },
  mutDialogCtrl(state, Status) {
    state.init.dialogOpen = Status;
  },
  mutIniForm(state) {
    state.formData = { isActive: true, mailAddress: "" };
  },
  mutIniSearch(state) {
    state.formSearch = JSON.parse(JSON.stringify(state.init.formSearch));
  },
  mutIniPage(state) {
    state.gridMailList.page.page = 1;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
