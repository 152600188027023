var Dict = {
  formate: "Formate",
  add: "Add",
  edit: "Edit",
  delete: "Delete",
  cancel: "Cancel",
  confirm: "Confirm",
  save: "Save",
  new: "New",
  send: "Send",
  input: "Enter",
  search: "Search",
  clear: "Clear",
  enable: "Enable",
  disable: "Disable",
  close: "Close",
  maintain: "Management",
  startDate: "Start Date",
  refresh: "Refresh",
  endDate: "End Date",
  checkDelete: "Confirm to delete",
  report: "report",
  yes: "Yes",
  no: "No",
  allItem: "All",
  nullItem: "Nan",
  sysMsg: "System Message",
  grid: {
    rowsPerPageItems: [20, 50],
    noResultsText: "No Results",
    rowsPerPageText: "Rows Per Page",
    noDataText: "No Data",
  },
};
export default Dict;
