<template>
  <div>
       <v-avatar
          color="blue"
          :size="this.imgsize"
        >
            <v-icon color="white" :size="this.imgsize-12" v-if="!this.loadedImage">mdi-account</v-icon>
            <img ref="avatarimg"
                    v-if="this.loadedImage"
                    :src="this.imgUrl"
                  />
       </v-avatar>
</div>
</template>

<script>
import axiosAPI from "../store/asiosAPI";
import { AppConfig } from "../config";
import { mapActions, mapState, mapGetters } from "vuex";
import mainOidc from "../auth/index";

export default {
  props: {
    'userid': { type: String, required:true, default:null},
    'imgsize': { type: Number, required:true, default:64}
  },
  async mounted() {

      let accountId='';
      if (AppConfig.deploymentType != "Cloud") accountId= AppConfig.accountId;
      else {
        if (mainOidc.isAuthenticated)
          accountId= mainOidc.userProfile[AppConfig.accountIdKey];
        else accountId= "00000000-0000-0000-0000-000000000000";
      }
        this.imgUrl=`${AppConfig.apiRoot}/user/profilepic/${accountId}/${this.userid}/${this.imgsize}`;
       this.loadedImage=true;
      //     axiosAPI.instance.show = false;
      //     axiosAPI.instance.get(`user/profilepic/${this.userid}/${this.imgsize}`).then((response) => {
      //     if (response.data.success) {
      //         this.imageBase64=response.data.imageBase64;
      //         this.loadedImage=true;
      //     }
      // });
      
  },
  data: () => ({
    loadedImage:false,
    imageBase64:'',
    imgUrl:''
  }),
};
</script>