<template>
  <div>
    <notifications
      name="notify"
      animation-type="velocity"
      group="my_group"
      :width="350"
      animation-name="v-fade-left"
      position="bottom right"
    >
      <template slot="body" slot-scope="props">
        <div :class="'mb-3 my_tmp pl-1 my_' + props.item.type">
          <!-- icon類型 -->
          <v-icon v-if="props.item.type == 'success'" class="green--text"
            >mdi-check-circle</v-icon
          >
          <v-icon v-else-if="props.item.type == 'warn'" class="orange--text"
            >mdi-info</v-icon
          >
          <v-icon v-else class="red--text">mdi-info</v-icon>
          <!-- 文字顯示 -->
          <div class="my_tmp-content">
            <div class="my_tmp-title">
              {{ props.item.title }}
            </div>
            <div>
              <div v-html="props.item.text"></div>
            </div>
          </div>
          <div class="my_tmp-close" @click="props.close">
            <v-icon>mdi-close</v-icon>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("mainCtr", ["snackBar"]),
  },
  watch: {
    snackBar() {
      var group = "my_group"; //this.snackBar.group
      var text = this.snackBar.message;
      var type = this.snackBar.type;
      var duration = this.snackBar.duration;
      var title = "System Information";

      if (text) {
        this.$notify({ group, title, text, type, duration });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my_warn {
  background: #ffffbb;
  opacity: 0.8;
  border: 2px solid #ffff80;
}

.my_error {
  background: #ffd7d7;
  opacity: 1;
  border: 2px solid #ffbfbf;
}

.my_success {
  background: #e8f9f0;
  opacity: 0.95;
  border: 2px solid #d0f2e1;
}

.my_tmp {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: left;
  font-size: 13px;
  margin: 5px;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  &,
  & > div {
    box-sizing: border-box;
  }

  .my_tmp-close {
    flex: 0 1 auto;
    padding: 0 20px;
    font-size: 16px;
    opacity: 0.2;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .my_tmp-title {
    letter-spacing: 1px;
    font-size: 10px;
    font-weight: 600;
  }
  .my_tmp-content {
    padding: 10px;
    flex: 1 0 auto;
  }
}

.v-fade-left-enter-active,
.v-fade-left-leave-active,
.v-fade-left-move {
  transition: all 0.5s;
}

.v-fade-left-enter,
.v-fade-left-leave-to {
  opacity: 0;
  transform: translateX(-500px) scale(0.2);
}
</style>
