import axiosAPI from "../asiosAPI";
import EG from "../../common/plugin";
import router from "../../router";
import { AppConfig } from "../../config";

var state = {
  name: "account",
  currHead: "Account",
  init: {
    lbl: {
      title: "Account Info ",
      usersTitle: "Account Users",
      account: "Account",
      license: "Lincense",
      company: "Company Name",
      authType: "Authentication Type",
      authClientId: "OAuth ClientId",
      authDomain: "OAuth Domain",
      authAudience: "OAuth Audience",
      winAuth: "Windows Authentication",
      oAuth: "OAuth Authentication",
    },
    dialogOpen: false,
    formData: {
      accountList: [],
      account: {
        licenseKey: "",
        licenseSummary: {
           totalLicenseCount: 55,
           totalLicenseUsed: 5,
           isValid: false,
           validFrom: "0001-01-01T00:00:00",
           validTo: "0001-01-01T00:00:00"
        },
        id: "",
        company: "",
        description: null,
        checkType: "",
        logo:"",
        externalMailWarning: "",
        externalMailWarningSec: "",
        offline: true,
        authType: 1,
        authClientId: "",
        authDomain: "",
        authAudience: "",
        customerId: null,
        accountNumber: null,
        stripeCustomerId: "",
        enabled: true,
        outstandingPayment: false,
        isTrial: false,
        acceptedEULA: true,
        acceptEULADate: "0001-01-01T00:00:00",
        licenseCount: 0,
        subscriptionExpireDate: "0001-01-01T00:00:00",
        enableEnkiCheckO365: false,
        enableEnkiCheckO365OWA: false,
        enableEnkiCheckO365Mac: false,
        enableEnkiCheckO365Win: false,
        enableEnkiCheckOffice: false
      },
      selectedId: "",
    },
    formSearch: {
      accountName: "",
    },
    isEditing: false,
  },
  accountUsers: [],
  formData: {
    accountList: [],
    account: {
      licenseKey: "",
      licenseSummary: {
         totalLicenseCount: 55,
         totalLicenseUsed: 5,
         isValid: false,
         validFrom: "0001-01-01T00:00:00",
         validTo: "0001-01-01T00:00:00"
      },
      id: "",
      company: "",
      description: null,
      checkType: "",
      logo:"",
      externalMailWarning: "",
      externalMailWarningSec: "",
      offline: true,
      authType: 1,
      authClientId: "",
      authDomain: "",
      authAudience: "",
      customerId: null,
      accountNumber: null,
      stripeCustomerId: "",
      enabled: true,
      outstandingPayment: false,
      isTrial: false,
      acceptedEULA: true,
      acceptEULADate: "0001-01-01T00:00:00",
      licenseCount: 0,
      subscriptionExpireDate: "0001-01-01T00:00:00",
      enableEnkiCheckO365: false,
      enableEnkiCheckO365OWA: false,
      enableEnkiCheckO365Mac: false,
      enableEnkiCheckO365Win: false,
      enableEnkiCheckOffice: false
    },
    selectedId: "",
  },
  m365Status:{
    serverPermission:false,
    clientAddIn:false
  },
  status: "",
  formSearch: {
    accountName: "",
  },
  newAccountUser: {
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  },
  eulaDialog: true,
};

var getters = {};

var actions = {
  actInitAllData({ commit }) {
    commit("mutIniSearch");
  },
  actIniForm({ commit }) {
    commit("mutIniForm");
  },
  actSearch({ commit }, isManual) {
    if (isManual) {
      commit("mutIniPage");
    }

    axiosAPI.instance.show = isManual;
    axiosAPI.instance
      .post("/account/Lists")
      .then((response) => {
        if (response.data.success) commit("mutFillAccountList", response.data);
      })
      // .catch((e) => {
      //   EG.PROGRESS(false);
      //   EG.setAlert(`Problem handling something: ${e}.`);
      // });
  },
  actGet({ commit }) {
    axiosAPI.instance
      .post("/account/GetAccount")
      .then((response) => {
        // window.console.log(response.data)
        if (response.data.success) commit("mutFillFormData", response.data);
      })
      // .catch((e) => {
      //   EG.PROGRESS(false);
      //   EG.setAlert(`Problem handling something: ${e}.`);
      // });
  },
  actGetStatus({ commit }, data) {
    var url = "/account/status";
    axiosAPI.instance.show = true;
    axiosAPI.instance
      .get(url, {
        headers: {
          "enki-account-id": data.accountId,
          Authorization: `Bearer ${data.accessToken}`,
        },
      })
      .then((response) => {
        if (response.data) {
          commit("mutAccountStatus", response.data);
        }
      });
  },
  actUserRole({ commit, dispatch }, data) {
    var url = `/account/user/${data.id}/changeRole`;
    axiosAPI.instance.show = true;
    axiosAPI.instance.post(url,data).then((response) => {
      if (response.data.success) {
        dispatch("actGetUsers");
      }
    });
  },
  actEnableUser({ commit, dispatch }, data) {
    var url = `/account/user/${data}/enable`;
    axiosAPI.instance.show = true;
    axiosAPI.instance.post(url).then((response) => {
      if (response.data.success) {
        dispatch("actGetUsers");
      }
    });
  },
  actDisableUser({ commit, dispatch }, data) {
    var url = `/account/user/${data}/disable`;
    axiosAPI.instance.show = true;
    axiosAPI.instance.post(url).then((response) => {
      if (response.data.success) {
        dispatch("actGetUsers");
      }
    });
  },
  actRemoveUser({ commit, dispatch }, data) {
    var url = `/account/user/${data}/`;
    axiosAPI.instance.show = true;
    axiosAPI.instance.delete(url).then((response) => {
      if (response.data.success) {
        dispatch("actGetUsers");
      }
    });
  },
  actAddUser({ commit, dispatch }) {
    var _formdata = state.newAccountUser;
    var url = "/account/user";
    axiosAPI.instance.show = true;
    axiosAPI.instance.post(url, _formdata).then((response) => {
      if (response.data.success) {
        dispatch("actGetUsers");
      }
    });
  },
  actGetUsers({ commit }) {
    var _formdata = {};
    var url = "/account/users";
    axiosAPI.instance.show = true;
    axiosAPI.instance.post(url, _formdata).then((response) => {
      if (response.data) {
        commit("mutAccountUsers", response.data);
      }
    });
  },
   actOpenM365Prepare({commit}){
    var returnUrl= encodeURI(AppConfig.baseUrl + '/m365/setup-tenant-callback');
    var azureClientId=AppConfig.AzureADClientId;
    var accountId=state.formData.account.id;
    //var hash=await crypto.subtle.digest('SHA-256', accountId)
    var url=`https://login.microsoftonline.com/common/oauth2/v2.0/authorize?response_type=code&client_id=${azureClientId}&state=${accountId}&redirect_uri=${returnUrl}&scope=https%3A%2F%2Fgraph.microsoft.com%2FDirectory.AccessAsUser.All+openid+profile+offline_access&prompt=select_account`;
    window.open(url, '_blank');
  },
  actOpenM365AdminConsentAddIn({commit}){
    var returnUrl= encodeURI(AppConfig.baseUrl + '/m365/admin-consent-callback');
    var azureClientId=AppConfig.O365AddInClientId;
    var accountId=state.formData.account.id;
    var url=`https://login.microsoftonline.com/common/adminconsent?client_id=${azureClientId}&state=${accountId}&redirect_uri=${returnUrl}`;
    window.open(url, '_blank');
  },
  actOpenM365AdminConsent({commit}){
    var returnUrl= encodeURI(AppConfig.baseUrl + '/m365/admin-consent-callback');
    var azureClientId=AppConfig.AzureADClientId;
    var accountId=state.formData.account.id;
    var url=`https://login.microsoftonline.com/common/adminconsent?client_id=${azureClientId}&state=${accountId}&redirect_uri=${returnUrl}`;
    window.open(url, '_blank');
  },
  actGetM365Status({commit}){
    var _formdata = {  };
    var url = "/account/OfficeAddInJSStatus";
    axiosAPI.instance.show = true;
    axiosAPI.instance.post(url, _formdata).then((response) => {
      if (response.data) {
        commit("mutM365Status", response.data);
      }
    });
  },
  actImportM365Users({ dispatch, commit }) {
    var url = "/m365/import-users";

    axiosAPI.instance.show = true;
    axiosAPI.instance
      .post(url)
      .then((response) => {
        if (response.data.success) {
          
          dispatch("actGetUsers");
          actions.actCloseDialog({ commit });
          
        }
      })
      .finally(() => {
        actions.actCloseDialog({ commit });
      });
  },
  actOpenSubscriptionPortal({ commit }) {
    
    var returnBaseUrl=AppConfig.baseUrl;
    if(!returnBaseUrl.startsWith("http://") && !returnBaseUrl.startsWith("https://"))
    {
      returnBaseUrl=window.location.protocol + "//" + window.location.host;
    }
    var _formdata = { ReturnUrl: returnBaseUrl + router.currentRoute.path };
    var url = "/account/CustomerPortal";
    axiosAPI.instance.show = true;
    axiosAPI.instance.post(url, _formdata).then((response) => {
      if (response.data) {
        window.location = response.data.url;
      }
    });
  },
  actSave({ commit }) {
    var _formdata = state.formData.account;
    var url = "/account/Update";
    axiosAPI.instance.show = true;
    axiosAPI.instance.post(url, _formdata).then((response) => {
      if (response.data.success) {
        commit("mutFillFormData", response.data);
      }
    });
  },
  actSaveAccount({ commit }) {
    var _formdata = state.formData.account;
    var url = "/account/Create";
    axiosAPI.instance.show = true;
    axiosAPI.instance.post(url, _formdata).then((response) => {
      if (response.data.success) {
        commit("mutFillFormData", response.data);
      }
    });

    actions.actCloseDialog({ commit });
  },
  actSetRow({ commit }, RowData) {
    commit("mutSetRow", RowData);
  },
  actShowDialog({ commit }, payLoad) {
    commit("mutDialogSet", payLoad);
    commit("mutDialogCtrl", true);
  },
  actCloseDialog({ commit }) {
    commit("mutDialogCtrl", false);
  },
  actCancel({ commit }) {
    commit("mutCancelEditing", state.init.formData);
  },
  actAcceptEULA({ commit }, data) {
    var _formdata = state.formData.account;
    _formdata.id = data.accountId;
    _formdata.acceptedEULA = true;
    var url = "/account/acceptEULA";
    axiosAPI.instance.show = true;
    axiosAPI.instance.post(url, _formdata).then((response) => {
      if (response.data.success) {
        router.push("/");
      }
    });

    actions.actCloseDialog({ commit });
  },
};

var mutations = {
  mutAccountUsers(state, res) {
    state.accountUsers = res.data;
  },
  mutAccountStatus(state, res) {
    state.status = res.status;
  },
  mutM365Status(state, res) {
    state.m365Status = res.m365;
  },
  mutFillFormData(state, res) {
    if (res.data != null) {
      state.formData.account = res.data;
    } else {
      state.formData.account = {
        company: "",
        licenseKey: "",
        authType: 0,
        authClientId: "",
        authDomain: "",
        authAudience: "",
      };
    }

    state.init.formData = JSON.parse(JSON.stringify(state.formData));
  },
  mutFillAccountList(state, res) {
    if (res.data != null) {
      state.formData.accountList = res.data;
    } else {
      state.formData.accountList = [];
    }
  },
  mutIniForm(state) {
    state.formData.accountList = [];
    state.formData.account = {
      company: "",
      licenseKey: "",
      authType: 0,
      authClientId: "",
      authDomain: "",
      authAudience: "",
    };
  },
  mutIniSearch(state) {
    state.formSearch = state.init.formSearch;
  },
  mutIniPage(state) {
    state.formSearch.Page.page = 1;
  },
  mutCancelEditing(state, tmpData) {
    state.formData = JSON.parse(JSON.stringify(tmpData));
  },
  mutDialogSet(state, payLoad) {
    state.init.dialogAction = payLoad.action;
  },
  mutDialogCtrl(state, Status) {
    state.init.dialogOpen = Status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
