/* eslint-disable no-unused-vars */
import axios from "axios";
import EG from "../common/plugin";
import { AppConfig } from "../config";
import mainOidc from "../auth/index.js";
import store from "../store/index";
import {useAppInsights} from "../services/AppInsights"

var instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": AppConfig.baseUrl,
    "Access-Control-Allow-Headers": "Content-Type, token",
  },
  url: "/",
  method: "post",
  baseURL: AppConfig.apiRoot,
  timeout: 500000,
  withCredentials: true,
  crossDomain: true,
});

instance.interceptors.request.use(
  async function (config) {
    if (AppConfig.deploymentType != "Cloud") {
      config.withCredentials = true;
      config.headers.common["enki-account-id"] = AppConfig.accountId;
    } else {
      if (!config.headers.Authorization) {
        const token = await getToken();
        config.headers.Authorization = `Bearer ${token}`;
      }
      config.withCredentials = false;
      config.headers.common["enki-account-id"] = await getAccountId(); //"7f8380f8-4873-49d9-89d4-24da15d493bb";
    }

    EG.PROGRESS(true);
    return config;
  },
  function (error) {
    EG.PROGRESS(false);
    // window.console.log(error);
    useAppInsights().trackException({exception: new Error("API request error on url " + (error.request != undefined && error.request.responseURL != undefined
      ? ", URL:" + error.request.responseURL
      : "") + " Error : "+ JSON.stringify(error))});
    EG.setAlert(
      error.message +
        (error.request != undefined && error.request.responseURL != undefined
          ? ", URL:" + error.request.responseURL
          : "")
    );
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (res) {
    EG.PROGRESS(false);
    // eslint-disable-next-line no-prototype-builtins
    if (!res.data.hasOwnProperty("success") || res.data.success === true) {
      if (instance.show) {
        var tmp = resToSnack(res);
        EG.setSnackBar(tmp.content, tmp.type);
      }
      return res;
    } else {
      // eslint-disable-next-line no-prototype-builtins
      if (res.data.hasOwnProperty("success")) {
        EG.setAlert(res.data.message);
        useAppInsights().trackException({exception: new Error("API call failed on url " + res.config.url + " Error : "+ JSON.stringify(res))});
      }
      return Promise.reject(res);
    }
  },
  function (error) {
    EG.PROGRESS(false);
    useAppInsights().trackException({exception: new Error("API call error on url " + error.config.url + " Error : "+ (error.response != undefined)?JSON.stringify(error.response):JSON.stringify(error))});
    EG.setAlert(error + " on "  + error.config.url );
    return Promise.reject(error);
  }
);

export default {
  instance,
};

var resToSnack = function (res) {
  var snackBar = { type: "", content: "" };
  if (res != null) {
    if (res.data.success == true) {
      snackBar.type = "success";
      snackBar.content = res.data.message;
      return snackBar;
    } else {
      snackBar.type = "error";
      if (res.status == "200") {
        snackBar.content = res.data.message;
        return snackBar;
      } else {
        snackBar.content = res.data.message;
        return snackBar;
      }
    }
  } else {
    snackBar.type = "error";
    snackBar.content = "Connect Timeout!";
    return snackBar;
  }
};

var getToken = async function () {
  return mainOidc.accessToken;
};

var getAccountId = async function () {
  if (AppConfig.deploymentType != "Cloud") return AppConfig.accountId;
  else {
    if (mainOidc.isAuthenticated)
      return mainOidc.userProfile[AppConfig.accountIdKey];
    else return "00000000-0000-0000-0000-000000000000";
  }
};
