<template>
  <v-dialog
    class="my_fade"
    v-model="init.connDialogOpen"
    @keydown.esc="cancel(false)"
    persistent
    max-width="40%"
  >
    <v-card flat :class="'pa-3 ' + 'my_' + init.connDialogAction">
      <span class="title">
        {{ title }}
      </span>
    </v-card>

    <!-- 刪除畫面 -->
    <v-card
      v-show="init.connDialogAction == 'delete'"
      flat
      class="px-4 py-3 my_bg-6"
    >
      <div class="mb-5">
        <p class="title text-xs-center">
          <u>{{ connectionData.connection1 }}</u>
        </p>
        <blockquote class="body-2 py-3 text--grey lighten-4">
          {{ init.lbl.warning }}
        </blockquote>
      </div>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn color="my_btn-2" dark @click="cancel()">
          {{ Dict.cancel }}
          <v-icon dark right>mdi-cancel</v-icon>
        </v-btn>
        <v-btn color="my_btn-3" dark @click="del()">
          {{ Dict.confirm }}
          <v-icon dark right>mdi-check-circle</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- 新增|編輯畫面 -->
    <v-card
      v-show="['update', 'create'].includes(init.connDialogAction)"
      class="my_bg-6"
    >
      <v-card-text>
        <v-layout row style="height: 70px">
          <v-flex sm6 pl-2>
            <v-radio-group v-model="connectionData.ldapType" row>
              <v-radio label="LDAP" value="LDAP"></v-radio>
              <v-radio label="LDAPS" value="LDAPS"></v-radio>
            </v-radio-group>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text>
        <v-layout row style="height: 70px">
          <v-flex sm6 pl-2>
            <v-text-field
              :prepend-icon="Icon.text"
              :label="init.lbl.server"
              v-model="connectionData.connection1"
              @input="$v.connectionData.connection1.$touch()"
            ></v-text-field>
            <div
              class="input-error"
              v-if="!$v.connectionData.connection1.required"
            >
              {{ init.lbl.server }} is required.
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text>
        <v-layout row style="height: 70px">
          <v-flex sm6 pl-2>
            <v-text-field
              :prepend-icon="Icon.text"
              :label="init.lbl.connAccount"
              v-model="connectionData.account"
              @input="$v.connectionData.account.$touch()"
            ></v-text-field>
            <div class="input-error" v-if="!$v.connectionData.account.required">
              {{ init.lbl.connAccount }} is required.
            </div>
            <div
              class="input-error"
              v-if="!$v.connectionData.account.minLength"
            >
              {{ init.lbl.connection }} must have at least
              {{ $v.connectionData.account.$params.minLength.min }} letters.
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-text>
        <v-layout row style="height: 70px">
          <v-flex sm6 pl-2>
            <v-text-field
              :prepend-icon="Icon.text"
              :label="init.lbl.connPassword"
              v-model="connectionData.password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              counter
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="my_btn-2" dark @click="cancel()">
          {{ Dict.cancel }}
          <v-icon dark right>mdi-cancel</v-icon>
        </v-btn>
        <v-btn color="my_btn-3" dark @click="save()">
          {{ Dict.confirm }}
          <v-icon dark right>mdi-check-circle</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.chip--small {
  height: 20px;
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import { notUri } from "../../common/validator";
const { validationMixin, default: Vuelidate } = require("vuelidate");
const { required, minLength } = require("vuelidate/lib/validators");
import { AppConfig } from "../../config";

export default {
  computed: {
    ...mapState("globalSetting", ["init", "connectionData"]),
    // eslint-disable-next-line vue/return-in-computed-property
    title(state) {
      var v = state.init.connDialogAction;
      var title = state.init.lbl.o365Connection;
      if (AppConfig.deploymentType != "Cloud")
        title = state.init.lbl.adConnection;
      if (v == "create") {
        return "Create " + title;
      } else if (v == "update") {
        return "Modify " + title;
      } else if (v == "delete") {
        return "Delete " + title;
      }
    },
  },
  data() {
    return {
      active: "actionCodes",
      show1: false,
    };
  },
  validations: {
    connectionData: {
      connection1: {
        required,
        minLength: minLength(5),
      },
      account: {
        required,
        minLength: minLength(3),
      },
    },
  },
  methods: {
    ...mapActions("globalSetting", [
      "actSaveConnections",
      "actCloseConnDialog",
    ]),
    save() {
      this.$v.connectionData.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.connectionData.$pending || this.$v.connectionData.$error)
        return;

      this.actSaveConnections();
    },
    cancel() {
      this.$v.connectionData.$reset();
      this.actCloseConnDialog();
    },
    del() {
      this.actSaveConnections();
    },
  },
};
</script>
