import axiosAPI from "../asiosAPI";
import EG from "../../common/plugin";
import moment from "moment";
import { AppConfig } from "../../config";
import store from "../../store/index";
import mainOidc from "../../auth/index";

var state = {
  name: "Reports",
  currHead: "Reports ",
  init: {
    lbl: {
      title: "Reports ",
      senders: "Sender",
      sendDate: "Sent Date",
      to: "To",
      beginDate: "Sent From:",
      endDate: "To:",
    },
    formData: {
      id: 0,
      mailAddress: "",
      isActive: true,
      updateDate: "",
    },
    formSearch: {
      Page: {
        descending: false,
        sortBy: "SendDate",
        page: 1,
        rowsPerPage: 10,
      },
    },
    searchSender: {
      isManual: true,
      sender: "",
    },
  },
  formSearch: {
    beginDate: moment().add(-30, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    // senders: [],
    Page: {
      descending: false,
      sortBy: "SendDate",
      page: 1,
      rowsPerPage: 10,
    },
  },
  gridSendLogs: {
    data: [],
    loading: false,
    headers: [
      { text: "Sender", value: "senderMail" },
      { text: "To", value: "sendTo" },
      { text: "Sent Date", value: "sendDate" },
      { text: "Sent Successful", value: "isSend" },
    ],
    totalCount: 0,
    page: {
      descending: true,
      page: 1,
      rowsPerPage: 20,
    },
  },
  searchSender: {
    isManual: true,
    sender: "",
  },
  selectedSenderMail: "",
};

var getters = {
  userAccountId: function (state) {
    if (AppConfig.deploymentType != "Cloud") {
      return AppConfig.accountId;
    } else {
      if (mainOidc.userProfile != undefined)
        return mainOidc.userProfile[AppConfig.accountIdKey];
      else return "00000000-0000-0000-0000-000000000000";
    }
  },
};
var actions = {
  actInitAllData({ commit }) {
    commit("mutIniSearch");
  },
  actReadMailLists(isManual) {
    if (isManual) state.gridMailList.page.page = 1;

    axiosAPI.instance.show = false;
    axiosAPI.instance
      .post("/statistics/list", state.formSearch)
      .then(function (response) {
        state.gridMailList.data = response.data.data;
        state.gridMailList.totalCount = response.data.TotalCount;
      });
    // .catch(e => {
    //   throw new Error(`Problem handling something: ${e}.`);
    // });
  },
  actSearch({ commit }, isManual) {
    if (isManual) {
      commit("mutIniPage");
    }
    axiosAPI.instance.show = isManual;
    axiosAPI.instance
      .post("/statistics/list", state.formSearch)
      .then((response) => {
        if (response.data.success) {
          commit("mutFillGrid", response.data);
        }
      });

    // .catch(e => {
    //   throw new Error(`Problem handling something: ${e}.`);
    // });
  },
  actSenderSearch({ commit }, searchSender) {
    if (searchSender.isManual) {
      commit("mutIniPage");
    }
    axiosAPI.instance.show = searchSender.isManual;
    axiosAPI.instance
      .post("/statistics/list", state.formSearch)
      .then((response) => {
        if (response.data.success) {
          if (searchSender.sender !== null) {
            commit("mutSelectedSender", searchSender.sender);
            commit("mutFillGridSender", response.data);
          } else {
            commit("mutFillGrid", response.data);
          }
        }
      });

    // .catch(e => {
    //   throw new Error(`Problem handling something: ${e}.`);
    // });
  },
  actIniForm({ commit }) {
    commit("mutIniForm");
  },
  actIniSearch({ commit }) {
    commit("mutIniSearch");
  },
};

var mutations = {
  mutFillGrid(state, res) {
    if (res.data != null && res.data.length > 0) {
      state.gridSendLogs.data = res.data;
      state.gridSendLogs.totalCount = res.TotalCount;
    } else {
      state.gridSendLogs.data = [];
    }
  },
  mutIniForm(state) {
    state.formData = state.init.formData;
  },
  mutIniSearch(state) {
    state.formSearch = state.init.formSearch;
  },
  mutIniPage(state) {
    state.formSearch.Page.page = 1;
  },
  mutSelectedSender(state, sender) {
    state.selectedSenderMail = sender;
  },
  mutFillGridSender(state, res) {
    console.log(state.selectedSenderMail);
    if (res.data != null && res.data.length > 0) {
      state.gridSendLogs.data = res.data.filter(
        (s) => s.senderMail == state.selectedSenderMail
      );
      state.gridSendLogs.totalCount = res.TotalCount;
      console.log(state.gridSendLogs.data);
    } else {
      state.gridSendLogs.data = [];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
