<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title
            ><span>Role Info</span>
            <v-spacer></v-spacer>
            <v-btn outlined @click="save()">
              <v-icon>mdi-check-circle</v-icon>
              <span>{{ Dict.save }}</span>
            </v-btn>
            <v-btn color="error" outlined @click="cancel()">
              <v-icon>mdi-close</v-icon>
              <span>{{ Dict.cancel }}</span>
            </v-btn>
          </v-card-title>

          <v-divider></v-divider>

          <v-skeleton-loader
            v-if="progress"
            class="mx-auto"
            type="list-item-three-line, divider,list-item-avatar,list-item-avatar, divider, list-item-avatar, divider, list-item-three-line,divider,list-item-three-line"
          ></v-skeleton-loader>

          <v-card-text v-if="!progress">
            <v-list subheader two-line>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <span>{{ init.lbl.ruleName }}</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field
                      :label="init.lbl.ruleName"
                      v-model="formData.ruleName"
                      @input="$v.formData.ruleName.$touch()"
                      @blur="$v.formData.ruleName.$touch()"
                    >
                    </v-text-field>
                    <div
                      class="input-error"
                      v-if="!$v.formData.ruleName.required"
                    >
                      {{ init.lbl.ruleName }} is required.
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{
                    init.lbl.description
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-text-field
                      :label="init.lbl.description"
                      v-model="formData.description"
                    >
                    </v-text-field>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{
                    init.lbl.ruleAction
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-radio-group v-model="formData.ruleAction" row>
                      <v-radio :label="init.lbl.allow" :value="1"></v-radio>
                      <v-radio :label="init.lbl.block" :value="2"></v-radio>
                    </v-radio-group>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <v-switch
                      :label="`${
                        formData.isActive ? Dict.enable : Dict.disable
                      }`"
                      v-model="formData.isActive"
                    ></v-switch>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-show="!progress">
      <v-col>
        <v-expansion-panels multiple>
          <!--include group -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="v-card__title"
                >{{ init.lbl.include + " " + init.lbl.group }} 
    <v-chip class="ml-4 mr-4"
      :class="selected_Include.length >0?'':'error'"
      :text-color="selected_Include.length >0?'':'white'"
    >
      {{ selected_Include.length }}
    </v-chip>
                <font
                  class="input-error"
                  style="font-size: 10px"
                  v-if="includeError"
                >
                  Required</font
                >
                </span
              > 
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-toolbar dense>
                <v-text-field
                  v-model="search_include"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
              <v-checkbox
                v-model="include_selectAll"
                :indeterminate="include_indeterminate"
                label="Select All"
                @click="selectAllInclude()"
                disabled
              ></v-checkbox>
              <v-list shaped>
                <v-list-item-group v-model="selected_Include" multiple>
                  <template v-for="(item, i) in filteredIncludeList">
                    <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                    <v-list-item
                      v-else
                      :key="`item-${i}`"
                      :value="item.key"
                      @click="tmpSaveInclude(item.key)"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="item.connection"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            :true-value="item.key"
                            disabled
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!--exclude group -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="v-card__title">{{
                init.lbl.exclude + " " + init.lbl.group
              }} <v-chip class="ml-4 mr-4" 
      
    >
      {{ selected_Exclude.length }}
    </v-chip></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-toolbar dense>
                <v-text-field
                  v-model="search_exclude"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>
              <v-checkbox
                v-model="exclude_selectAll"
                :indeterminate="exclude_indeterminate"
                label="Select All"
                @click="selectAllExclude()"
                disabled
              ></v-checkbox>

              <v-list shaped>
                <v-list-item-group v-model="selected_Exclude" multiple>
                  <template v-for="(item, i) in filteredExcludeList">
                    <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                    <v-list-item
                      v-else
                      :key="`item-${i}`"
                      :value="item.key"
                      @click="tmpSaveExclude(item.key)"
                    >
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-text="item.connection"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            :true-value="item.key"
                            disabled
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <!--maillist -->
          <v-expansion-panel>
            <v-expansion-panel-header>
              <span class="v-card__title">{{ init.lbl.mail }} <v-chip class="ml-4 mr-4"
       :class="formData.mails.length >0?'':'error'"
       :text-color="formData.mails.length >0?'':'white'"
       
    >
      {{ formData.mails.length }}
    </v-chip></span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list shaped>
                <v-list-item-group v-model="formData.mails" multiple>
                  <template v-for="(item, i) in mailLists">
                    <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                    <v-list-item v-else :key="`item-${i}`" :value="item.id">
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title
                            v-text="item.mailAddress"
                          ></v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox
                            :input-value="active"
                            :true-value="item.id"
                            disabled
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
#responsibilityEdit .ql-editor {
  max-height: 200px;
}
</style>
<script>
import { mapActions, mapState } from "vuex";
const { validationMixin, default: Vuelidate } = require("vuelidate");
const { required, minLength } = require("vuelidate/lib/validators");

export default {
  components: {},
  data: () => ({
    tmpInclude: [],
    tmpExclude: [],
    selected_Include: [],
    selected_Exclude: [],
    search_include: "",
    search_exclude: "",
    includeError: false,
    exclude_selectAll: false,
    include_selectAll: false,
  }),
  mounted() {
    this.actGetInfo();
  },
  watch: {
    "formData.groupsInclude": function () {
      if (this.formData.groupsInclude.length > 0) {
        this.selected_Include = [];
        this.tmpInclude = [];
        this.formData.groupsInclude.forEach((element) => {
          this.selected_Include.push(element);
          this.tmpInclude.push(element);
        });
      }
    },
    "formData.groupsExclude": function () {
      if (this.formData.groupsExclude.length > 0) {
        this.selected_Exclude = [];
        this.tmpExclude = [];
        this.formData.groupsExclude.forEach((element) => {
          this.selected_Exclude.push(element);
          this.tmpExclude.push(element);
        });
      }
    },
    search_include: function () {
      this.selected_Include = JSON.parse(JSON.stringify(this.tmpInclude));
    },
    search_exclude: function () {
      this.selected_Exclude = JSON.parse(JSON.stringify(this.tmpExclude));
    },
  },
  computed: {
    ...mapState("rules", [
      "formSearch",
      "init",
      "formData",
      "mailLists",
      "selectedMails",
      "groupLists_include",
      "groupLists_exclude",
      "selectedGroup_Include",
      "selectedGroup_Exclude",
      "groupHeaders",
    ]),
    ...mapState("mainCtr", ["progress"]),
    filteredIncludeList() {
      var rtnList = [];
      this.groupLists_include
        .filter((group) => {
          return this.tmpInclude.indexOf(group.key) >= 0;
        })
        .forEach((element) => {
          rtnList.push(element);
        });

      this.groupLists_include.forEach((element) => {
        if (rtnList.indexOf(element) == -1) rtnList.push(element);
      });

      return rtnList.filter((group) => {
        return group.name
          .toLowerCase()
          .includes(this.search_include.toLowerCase());
      });
    },
    filteredExcludeList() {
      var rtnList_exclude = [];
      this.groupLists_exclude
        .filter((group) => {
          return this.tmpExclude.indexOf(group.key) >= 0;
        })
        .forEach((element) => {
          rtnList_exclude.push(element);
        });

      this.groupLists_exclude.forEach((element) => {
        if (rtnList_exclude.indexOf(element) == -1)
          rtnList_exclude.push(element);
      });

      return rtnList_exclude.filter((group) => {
        return group.name
          .toLowerCase()
          .includes(this.search_exclude.toLowerCase());
      });
    },
    exclude_indeterminate() {
      return (
        this.selected_Exclude.length > 0 &&
        this.groupLists_exclude.length != this.selected_Exclude.length
      );
    },
    include_indeterminate() {
      return (
        this.selected_Include.length > 0 &&
        this.groupLists_include.length != this.selected_Include.length
      );
    },
  },
  validations: {
    formData: {
      ruleName: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("rules", [
      "actShowDialog",
      "actCloseDialog",
      "actGetInfo",
      "actSave",
      "actCancel",
      "actSearchMail",
      "actGetGroupLists",
    ]),
    dataUrl(imageData) {
      return "data:image/jpeg;base64," + imageData;
    },
    save() {
      this.$v.formData.$touch();
      if (this.selected_Include == null || this.selected_Include.length <= 0) {
        this.includeError = true;
      }
      if (this.$v.formData.$pending || this.$v.formData.$error) return;
      if (this.selected_Include == null || this.selected_Include.length <= 0) {
        return;
      }
      this.actSave({
        mail: this.formData.mails,
        includeGroup: this.selected_Include,
        excludeGroup: this.selected_Exclude,
      });
    },
    cancel() {
      this.$v.formData.$reset();
      this.includeError = false;
      this.actCancel();
    },
    tmpSaveInclude(groupId) {
      var index = this.tmpInclude.indexOf(groupId);
      if (index > -1) this.tmpInclude.splice(index, 1);
      else this.tmpInclude.push(groupId);
    },
    tmpSaveExclude(groupId) {
      var index = this.tmpExclude.indexOf(groupId);
      if (index > -1) this.tmpExclude.splice(index, 1);
      else this.tmpExclude.push(groupId);
    },
    selectAllInclude() {
      if (this.include_selectAll) {
        this.groupLists_include.forEach((element) => {
          this.selected_Include.push(element.key);
          this.tmpInclude.push(element.key);
        });
      } else {
        this.selected_Include = [];
        this.tmpInclude = [];
      }
    },
    selectAllExclude() {
      if (this.exclude_selectAll) {
        this.groupLists_exclude.forEach((element) => {
          this.selected_Exclude.push(element.key);
          this.tmpExclude.push(element.key);
        });
      } else {
        this.selected_Exclude = [];
        this.tmpExclude = [];
      }
    },
  },
};
</script>
