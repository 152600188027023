import axiosAPI from "./asiosAPI";
import EG from "../common/plugin";
import mainAuth from "../auth/index.js";

var state = {
  name: "users",
  myUserInfo: null,
  myProfilePic:[],
  releaseNotesRead:false,
  alertRead:false,
};

var getters = {};

var actions = {
  actGetMyUserInfo({ commit }) {
    axiosAPI.instance.show = true;
    axiosAPI.instance.post("/user/me", {
        externalId: mainAuth.userProfile["sub"],
      }).then((response) => {
      if (response.data.success) {
        commit("mutMyUserInfo", response.data.data);
        //console.log(response.data.data);
      }
    });
    // .catch((e) => {
    //   EG.PROGRESS(false);
    //   EG.setAlert(`Problem handling something: ${e}.`);
    // });
  },
  actSetReleaseNotesState({ commit, dispatch }, data) {
    commit("mutReleaseNotesRead", data);
  },
  actGetMyUserProfilePic({ commit, dispatch }, data) {
    axiosAPI.instance.show = true;
    axiosAPI.instance.get(`user/profilepic/${data.id}/${data.size}`).then((response) => {
      if (response.data) {
        commit("mutMyProfilePic", response.data.imageBase64);
      }
    });
    // .catch((e) => {
    //   EG.PROGRESS(false);
    //   EG.setAlert(`Problem handling something: ${e}.`);
    // });
  },
};

var mutations = {
    mutMyUserInfo(state, data) {
        state.myUserInfo = data;
    },
    mutReleaseNotesRead(state, data) {
      state.releaseNotesRead = data;
    },
    mutMyProfilePic(state, data) {
      state.myProfilePic = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };