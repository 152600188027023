import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"fullscreen":"","persistent":""},model:{value:(_vm.eulaDialog),callback:function ($$v) {_vm.eulaDialog=$$v},expression:"eulaDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline grey lighten-2"},[_vm._v(" Accpet End User License Agreement ")]),_c(VCardText,[_c('div',{staticClass:"Contract"},[_c('iframe',{attrs:{"src":_vm.pathUrl}})])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.notAccpet()}}},[_vm._v(" Not Accpet ")]),_c(VBtn,{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.accpet()}}},[_vm._v(" Accept ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }