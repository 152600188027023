<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col md="6">
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="80"
            :width="10"
          ></v-progress-circular></div></v-col
    ></v-row>
    <v-row justify="center">
      <v-col md="6"
        ><div class="text-center text-body-1">Loading...</div></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { AppConfig } from "../config.js";
import axiosAPI from "../store/asiosAPI";

export default {
  data: () => ({
    loadCompleted: false,
  }),
  computed: {
    ...mapState("account", ["status"]),
  },
  mounted() {
      var consentState=this.$route.query.admin_consent;
      var tid=this.$route.query.tenant;
      var accountid=this.$route.query.state;

      var error=this.$route.query.error;
       var error_description=this.$route.query.error_description;

    if(!error)
    {
         axiosAPI.instance
            .post("/m365/admin-consent/", { accountId: accountid, tenantId:tid })
            .then((response) => {
              window.location.href = "/account";
            });
    }
  }
}
</script>