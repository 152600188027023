import Vue from "vue";
import VueRouter from "vue-router";
import { AppConfig } from "../config";
import Home from "../views/Home.vue";
import GlobalSetting from "../views/settings/GlobalSettings.vue";
import MailLists from "../views/rules/MailLists.vue";
import Statistic from "../views/statistics/Statistic.vue";
import ActivityLogHome from "../views/activitylog/activitylog.vue";
import Account from "../views/settings/Account.vue";
import UserHome from "../views/users/Home.vue";
import Rules from "../views/rules/Rules.vue";
import RuleInfo from "../views/rules/RuleInfo.vue";
import mainAuth from "../auth/index.js";
import Globalrule from "../views/rules/GlobalRuleInfo.vue";
import store from "../store/index";
import axiosAPI from "../store/asiosAPI";
import EG from "../common/plugin";
import Eula from "../views/Eula.vue";
import OAuthCallback from "../views/OAuthCallback.vue";
import M365AdminConsentCallback from "../views/M365AdminConsentCallback.vue";
import M365SetupTenantCallback from "../views/M365SetupTenantCallback.vue";
import LoginResultTrialExpired from  "../views/LoginResultTrialExpired.vue";
import LoginResultExpired from "../views/LoginResultExpired.vue";
import LoginResultNoAccess from "../views/LoginResultNoAccess.vue";
import LoginResultAccountLinked from "../views/LoginResultAccountLinked.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    meta: {
      authName: mainAuth.authName,
      breadcrumb: "Home",
    },
    children: [
      {
        path: "",
        name: 'home',
        component: Home,
        meta: {
          authName: mainAuth.authName,
          breadcrumb: "Home",
          requireEULA: true,
        },
      },
      {
        path: "maillist",
        name: "maillist",
        component: MailLists,
        meta: {
          authName: mainAuth.authName,
          breadcrumb: "Mail List",
          requireEULA: true,
          requireLicenseCheck: true,
        },
      },
      {
        path: "users",
        name: "users",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
          authName: mainAuth.authName,
          breadcrumb: "Users",
          requireEULA: true,
          requireLicenseCheck: true,
        },
        children: [
          {
            path: "me",
            name: "users-me",
            component: UserHome,
            meta: {
              authName: mainAuth.authName,
              breadcrumb: "Me",
              requireLicenseCheck: true,
              requireEULA: true,
            },
          },
        ]
      },
      {
        path: "globalsetting",
        name: "globalsetting",
        component: GlobalSetting,
        meta: {
          authName: mainAuth.authName,
          breadcrumb: "Global Setting",
          requireLicenseCheck: true,
          requireEULA: true,
        },
      },
      {
        path: "globalrule",
        name: "globalrule",
        component: Globalrule,
        meta: {
          authName: mainAuth.authName,
          breadcrumb: "Global Rule",
          requireLicenseCheck: true,
          requireEULA: true,
        },
      },
      {
        path: "activitylog",
        name: "activitylog",
        component: ActivityLogHome,
        meta: {
          authName: mainAuth.authName,
          breadcrumb: "Activity Logs",
          requireLicenseCheck: true,
          requireEULA: true,
        },
      },
      {
        path: "statistics",
        name: "statistics",
        component: Statistic,
        meta: {
          authName: mainAuth.authName,
          breadcrumb: "Reports",
          requireLicenseCheck: true,
          requireEULA: true,
        },
      },
      {
        path: "account",
        name: "account",
        component: Account,
        meta: {
          authName: mainAuth.authName,
          breadcrumb: "Account",
          requireLicenseCheck: true,
          requireEULA: true,
        },
      },
      {
        path: "rules",
        component: {
          render(c) {
            return c("router-view");
          },
        },
        meta: {
          authName: mainAuth.authName,
          breadcrumb: "Rules",
        },
        children: [
          {
            path: "",
            name: "rules",
            component: Rules,
            meta: {
              authName: mainAuth.authName,
              breadcrumb: "Rules",
              requireLicenseCheck: true,
              requireEULA: true,
            },
          },
          {
            path: "ruleinfo",
            name: "ruleinfo",
            component: RuleInfo,
            meta: {
              authName: mainAuth.authName,
              breadcrumb: "Rule Info",
              requireLicenseCheck: true,
              requireEULA: true,
            },
          },
        ],
      },
      {
        path: "eula",
        name: "eula",
        component: Eula,
      },
    ],
  },
  {
    path: "/m365/callback",
    name: "m365callback",
    component: OAuthCallback,
  },
  {
    path: "/m365/admin-consent-callback",
    name: "m365adminconsentcallback",
    component: M365AdminConsentCallback,
  },
  {
    path: "/m365/setup-tenant-callback",
    name: "M365SetupTenantCallback",
    component: M365SetupTenantCallback,
  },
  {
    path: "/callback",
    name: "callback",
    component: OAuthCallback,
  },
  {
    path: "/login-error-trialexpired",
    name: "login-error-trial-expired",
    component: LoginResultTrialExpired,
  },
  {
    path: "/login-error-expired",
    name: "login-error-expired",
    component: LoginResultExpired,
  },
  {
    path: "/login-error-noaccess",
    name: "login-error-noaccess",
    component: LoginResultNoAccess,
  },
  {
    path: "/login-account-linked",
    name: "login-account-linked",
    component: LoginResultAccountLinked,
  },
  // {
  //   path: "/callback",
  //   name: "callback",
  //   component: () => import("../views/callback.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.requireEULA) {
    next();
  } else {
    if (mainAuth.isAuthenticated) {
      axiosAPI.instance.show = false;
      axiosAPI.instance
        .post("/user/allowaccess/admin", {
          externalId: mainAuth.userProfile["sub"],
        })
        .then((response) => {
          if (response.data.data) {
            axiosAPI.instance
              .post("/account/GetAccount")
              .then((response) => {
                if (response.data.success) {
                  var account = response.data.data;
                  var expiredDate;
                  if (to.meta.requireLicenseCheck) {
                    var today = new Date(Date.now());
                    if (account.subscriptionExpireDate != null) {
                      expiredDate = new Date(
                        account.subscriptionExpireDate
                      );
                      if (expiredDate < today) {
                        if (router.currentRoute.path != "/login-error-expired")
                          router.push({ path: "/login-error-expired" });
                      } else next();
                    } else {
                      if (account.licenseSummary != null) {
                        expiredDate = new Date(
                          account.licenseSummary.validTo
                        );
                        if (expiredDate < today) {
                          if (
                            router.currentRoute.path != "/login-error-expired"
                          )
                            if (
                              router.currentRoute.path != "/login-error-expired"
                            )
                              router.push({ path: "/login-error-expired" });
                        } else next();
                      } else {
                        next();
                      }
                    }
                  } else {
                    next();
                  }

                  if (to.meta.requireEULA) {
                    if (!account.acceptedEULA)
                      if (router.currentRoute.path != "/eula")
                        router.push({ path: "/eula" });
                  } else {
                    next();
                  }
                } else {
                  account = null;
                }
              })
              .catch((e) => {
                EG.PROGRESS(false);
                EG.setAlert(`Problem handling something: ${e}.`);
                next();
              });
          } else {
            if (router.currentRoute.path != "/login-error-noaccess")
              router.push({ path: "/login-error-noaccess" });
          }
        })
        .catch((e) => {
          EG.PROGRESS(false);
          EG.setAlert(`Problem handling something: ${e}.`);
          next();
        });
    } else {
      next();
    }
  }
});

if (AppConfig.deploymentType == "Cloud") mainAuth.useRouter(router);

export default router;
