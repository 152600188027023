<template>
  <v-dialog
    class="my_fade"
    v-model="init.dialogOpen"
    @keydown.esc="cancel(false)"
    persistent
    max-width="40%"
  >
    <v-card flat :class="'pa-3 ' + 'my_' + init.dialogAction">
      <span class="title">
        {{ title }}
      </span>
    </v-card>

    <!-- 刪除畫面 -->
    <v-card
      v-show="init.dialogAction == 'delete'"
      flat
      class="px-4 py-3 my_bg-6"
    >
      <div class="mb-5">
        <p class="title text-xs-center">
          <u>{{ domainData.InternalDomain1 }}</u>
        </p>
        <blockquote class="body-2 py-3 text--grey lighten-4">
          {{ init.lbl.warning }}
        </blockquote>
      </div>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn color="my_btn-2" dark @click="cancel()">
          {{ Dict.cancel }}
          <v-icon dark right>mdi-cancel</v-icon>
        </v-btn>
        <v-btn color="my_btn-3" dark @click="del()">
          {{ Dict.confirm }}
          <v-icon dark right>mdi-check-circle</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- 新增|編輯畫面 -->
    <v-card
      v-show="['update', 'create'].includes(init.dialogAction)"
      class="my_bg-6"
    >
      <v-card-text>
        <v-layout row style="height: 70px">
          <v-flex sm6 pl-2>
            <v-text-field
              :prepend-icon="Icon.text"
              :label="init.lbl.internalDomain"
              v-model="domainData.InternalDomain1"
              @input="$v.domainData.InternalDomain1.$touch()"
            ></v-text-field>
            <div
              class="input-error"
              v-if="!$v.domainData.InternalDomain1.required"
            >
              {{ init.lbl.internalDomain }} is required.
            </div>
            <div
              class="input-error"
              v-if="!$v.domainData.InternalDomain1.minLength"
            >
              {{ init.lbl.internalDomain }} must have at least
              {{ $v.domainData.InternalDomain1.$params.minLength.min }} letters.
            </div>
            <div
              class="input-error"
              v-if="!$v.domainData.InternalDomain1.notDomain"
            >
              {{ init.lbl.internalDomain }} format is incorrect.
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="my_btn-2" dark @click="cancel()">
          {{ Dict.cancel }}
          <v-icon dark right>mdi-cancel</v-icon>
        </v-btn>
        <v-btn color="my_btn-3" dark @click="save()">
          {{ Dict.confirm }}
          <v-icon dark right>mdi-check-circle</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.chip--small {
  height: 20px;
}
</style>

<script>
import { mapActions, mapState } from "vuex";
import { notDomain } from "../../common/validator";
const { validationMixin, default: Vuelidate } = require("vuelidate");
const { required, minLength } = require("vuelidate/lib/validators");

export default {
  computed: {
    ...mapState("globalSetting", ["init", "domainData"]),
    // eslint-disable-next-line vue/return-in-computed-property
    title(state) {
      var v = state.init.dialogAction;
      var title = state.init.lbl.internalDomain;
      if (v == "create") {
        return "Create " + title;
      } else if (v == "update") {
        return "Modify " + title;
      } else if (v == "delete") {
        return "Delete " + title;
      }
    },
  },
  data() {
    return {
      active: "actionCodes",
    };
  },
  validations: {
    domainData: {
      InternalDomain1: {
        required,
        minLength: minLength(5),
        notDomain,
      },
    },
  },
  methods: {
    ...mapActions("globalSetting", ["actSaveInternalDomain", "actCloseDialog"]),
    save() {
      this.$v.domainData.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.domainData.$pending || this.$v.domainData.$error) return;
      this.actSaveInternalDomain("InternalDomain");
    },
    cancel() {
      this.$v.domainData.$reset();
      this.actCloseDialog();
    },
    del() {
      this.actSaveInternalDomain("InternalDomain");
    },
  },
};
</script>
