<template>
  <div>
    <div
      class="image-input"
      :style="{
        'background-image': `url(${dataUrl(imageData)})`,
        width: `${this.width}`,
        height: `${this.height}`,
      }"
    >
      <span v-if="!imageData && isEditable" class="placeholder">
        Choose an Image
      </span>
      <span v-if="!imageData && !isEditable" class="placeholder">
        No Image
      </span>
      <input
        :disabled="!isEditable"
        class="file-input"
        ref="fileInput"
        type="file"
        @input="onSelectFile"
        accept=".png, .jpg, .jpeg"
      />
    </div>
    <v-btn @click="chooseImage" v-if="isEditable">upload</v-btn>
    <v-btn @click="onDelete" v-if="isEditable">delete</v-btn>
  </div>
</template>

<script>
import {useAppInsights} from "../services/AppInsights"
export default {
  props: {
    data: Array,
    isEditable: Boolean,
    type: String,
    width: String,
    height: String,
  },
  data() {
    return {
      imageData: null,
      fromDB: true,
    };
  },
  methods: {
    chooseImage() {
      this.fromDB = false;
      this.$refs.fileInput.click();
    },
    onSelectFile() {
      const input = this.$refs.fileInput;
      const files = input.files;
      if (files && files[0]) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = Array.from(new Uint8Array(e.target.result));
          this.$emit("input", this.imageData);
          this.fromDB = true;
        };
        reader.readAsArrayBuffer(files[0]);
      }
    },
    onDelete() {
      this.imgData = null;
      this.$emit("input", null);
    },
    dataUrl(imgData) {
      var rtn = "data:image/png;base64,";

      if (this.fromDB) {
        this.imageData = this.data;
        imgData = this.data;
      }

      rtn += btoa(
        new Uint8Array(imgData).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      );

      return rtn;
    },
  },
  compress(e) {
    const width = this.width;
    const height = this.height;
    const fileName = e.target.files[0].name;
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      (img.onload = () => {
        const elem = document.createElement("canvas");
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext("2d");
        // img.width and img.height will contain the original dimensions
        ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob(
          (blob) => {
            // eslint-disable-next-line no-unused-vars
            const file = new File([blob], fileName, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
          },
          "image/jpeg",
          1
        );
      }),
        (reader.onerror = (error) => 
        useAppInsights().trackException({exception: new Error("Image input Error : "+ (error.response != undefined)?JSON.stringify(error.response):JSON.stringify(error))})
        );
    };
  },
};
</script>

<style>
.image-input {
  display: block;
  width: 200px;
  height: 200px;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}
.placeholder {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}
.placeholder:hover {
  background: #e0e0e0;
}
.file-input {
  display: none;
}
</style>
